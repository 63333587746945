import { WillsStatus } from "../views/wills/helpers/willsStatus";

const devEnvURL = (willsSiteURL: string, charityId?: string) =>
  charityId ? willsSiteURL?.replace("wills", charityId) : willsSiteURL;
const proEnvURL = (willsSiteURL: string, charityId?: string) =>
  charityId ? `https://${charityId}.islamicfinanceguru.com` : willsSiteURL;

const charityURL = (willsSiteURL: string, charityId?: string) => {
  // This checking here is to improve the dev experience.
  if (charityId?.match(/localhost/gi)) {
    return `http://${charityId}`;
  }

  // The url standard changes between production and dstaging
  // on production all the carities URLs are https:// + charityId + .islamicfinanceguru.com
  // on staging the only URL we have is https:// + charityId + .pub.islamicfinanceguru.dev
  const env = willsSiteURL.match(/islamicfinanceguru.dev/gi) ? "dev" : "prod";

  return env === "dev"
    ? devEnvURL(willsSiteURL, charityId)
    : proEnvURL(willsSiteURL, charityId);
};

export const redirectUser =
  (navigationCallback: (url: string) => void, willsSiteURL: string) =>
  (
    willStatus: string,
    domainCharityId?: string,
    willsPartnerCharityId?: string,
    willsPartnerCharityKey?: string
  ): void => {
    const existingUser = willStatus !== WillsStatus.NOT_STARTED;
    const charitySubdomain = Boolean(domainCharityId);
    const charityWill = Boolean(willsPartnerCharityId);
    const IFGWill = !charityWill;

    if (!existingUser) {
      return;
    }

    if (existingUser && IFGWill && charitySubdomain) {
      navigationCallback(willsSiteURL);
    }

    if (existingUser && charityWill && !charitySubdomain) {
      navigationCallback(charityURL(willsSiteURL, willsPartnerCharityKey));
    }

    if (
      existingUser &&
      charityWill &&
      charitySubdomain &&
      domainCharityId !== willsPartnerCharityId
    ) {
      navigationCallback(charityURL(willsSiteURL, willsPartnerCharityKey));
    }

    if (existingUser && IFGWill) {
      return;
    }
  };
