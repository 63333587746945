import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { UPDATE_DEPARTING_MESSAGE } from "../constants/actionTypes";

export const updateDepartingMessageAPI = createAsyncThunk<string, string>(
  UPDATE_DEPARTING_MESSAGE,
  async (departingMessage) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($departingMessage: String) {
            updateWill(data: { departingMessage: $departingMessage }) {
              departingMessage
            }
          }
        `,
        variables: {
          departingMessage,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
      }

      return response.data?.updateWill?.departingMessage;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "updateDepartingMessageAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
