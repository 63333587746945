import { State } from "../../../../components/Family/NavigationFlow";

export const isThereAnotherAnswerFromHere = (
  startPoint: number,
  state: State
) => {
  const keys = Object.keys(state);

  for (let index = startPoint + 1; index < keys.length; index++) {
    if (state[index].has) {
      return true;
    }
  }

  return false;
};
