import React, { useContext } from "react";
import LockIcon from "@assets/icons/lock.svg";
import { Description, InnerWrapper, Layout, Text } from "../styled";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { PageType } from "../types";
import { Field, Formik } from "formik";
import Input from "@legacyComponents/Form/input/Input/Input.component";
import { CashSchema } from "../constants/validation";
import { useDispatch, useSelector } from "react-redux";
import { updateCashStore } from "../actions";
import { getCashAssets } from "../selectors";
import { createCashAPI, updateCashAPI } from "../actions/cash";
import { CashObject } from "../constants/types";
import { AuthContext } from "..";
import { FormikForm } from "@legacyComponents/Form/input/styled/Form";
import tooltipText from "../constants/tooltipText";
import Tooltip from "@components/Tooltip";

type FieldType = {
  field: {
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
};

const initialState = {
  cashInHand: 0,
  cashISA: 0,
};

const Cash: React.FC<PageType> = ({ nextPage, previousPage, showTooltip }) => {
  const dispatch = useDispatch();
  const initialValues = useSelector(getCashAssets) || initialState;
  const email = useContext(AuthContext);

  const create = async (value: CashObject, totalCashValue: number) => {
    const cash = await dispatch(
      createCashAPI({
        email,
        data: {
          ...value,
          totalCashValue,
        },
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateCashStore(cash));
  };

  const update = async (value: CashObject, totalCashValue: number) => {
    const cash = await dispatch(
      updateCashAPI({
        ...value,
        totalCashValue,
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateCashStore(cash));
  };

  return (
    <Formik
      validationSchema={CashSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        //if cash value is not type number
        if ((values.cashISA as any) === "") {
          values.cashISA = 0;
        }

        if ((values.cashInHand as any) === "") {
          values.cashInHand = 0;
        }

        const { id, cashInHand, cashISA } = values;

        const totalCashValue = cashInHand + cashISA;

        if (!id) {
          create(values, totalCashValue);
        } else {
          update(values, totalCashValue);
        }

        nextPage();
      }}
    >
      {({ errors, touched }) => (
        <FormikForm>
          <Layout>
            <InnerWrapper>
              <div>
                <Text align="left">Cash</Text>
                <Description>
                  Enter the amount of cash you have in paper currency
                </Description>
                {showTooltip && <Tooltip text={tooltipText.cash} />}
              </div>
              <Field name="cashInHand">
                {({ field }: FieldType) => (
                  <Input
                    type="number"
                    heading="Cash in hand"
                    description="e.g. cash you hold in your safe"
                    errorMessage={
                      touched.cashInHand ? errors.cashInHand : undefined
                    }
                    placeholder="Enter value"
                    {...field}
                  />
                )}
              </Field>
              <Field name="cashISA">
                {({ field }: FieldType) => (
                  <Input
                    type="number"
                    heading="Cash in ISA"
                    description="Cash held in a government backed cash ISA"
                    errorMessage={touched.cashISA ? errors.cashISA : undefined}
                    placeholder="Enter value"
                    {...field}
                  />
                )}
              </Field>
            </InnerWrapper>
            <InnerWrapper>
              <FormButton
                type="submit"
                text="Save & Continue"
                disclaimerIcon={<LockIcon />}
                disclaimerText={
                  <div>
                    We take your{" "}
                    <a
                      href="https://www.islamicfinanceguru.com/security-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy seriously
                    </a>
                    . Our institutional-grade security ensures your data is
                    confidential.
                  </div>
                }
                leftArrowOnClick={previousPage}
                leftArrowLabel="Back"
              />
            </InnerWrapper>
          </Layout>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Cash;
