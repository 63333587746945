import React from "react";
import StyledRadioInputGroup from "./styled/StyledRadioInputGroup";

export enum RadioInputDirection {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
}

export type RadioInputGroupProps = {
  children: React.ReactNode;
  direction?: RadioInputDirection;
  testId?: string;
};

const RadioInputGroup: React.FC<RadioInputGroupProps> = ({
  direction = RadioInputDirection.VERTICAL,
  children,
  testId,
}) => (
  <StyledRadioInputGroup data-testid={testId} direction={direction}>
    {children}
  </StyledRadioInputGroup>
);

export default RadioInputGroup;
