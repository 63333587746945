export const keyTitle = {
  firstName: "First name",
  lastName: "Last name",
  address: "Address",
  email: "Email",
  emailAddress: "Email",
  percentageEstate: "Estate percentage",
  over18: "Is over 18",
  familyRelation: "Relation",
  relationship: "Relation",
  familyEdgeCasetype: "Religion",
  marriageStatustype: "Marital status",
  fullName: "Name",
  charityID: "ID",
  description: "Description",
  money: "Money",
  familyMembers: "Family members",
  familyMemberType: "Family relation",
  memberDisabled: "Family members disabled",
  memberReceivingTestedBenefits: "Family members receiving benefits",
  itemsToLeave: "Items to leave",
  name: "Name",
  islamicInheritor: "Islamic Inheritor",
  partOfInheritance: "Part of inheritance",
  nameOnCard: "Name on card",
  sortCode: "Sort code",
  accountNumber: "Account number",
  includedAsShare: "Included as share",
  personDisabled: "Is disabled",
  bankAccountName: "Bank name",
  cashInAccount: "Cash in account",
  brokerName: "Broker name",
  type: "Type",
  value: "Value",
  commodityType: "Type",
  monetaryValue: "Value",
  carat: "Carat",
  weight: "Weight (grams)",
  nameOfChild: "Name of child",
  fundName: "Fund name",
  fundTicker: "Fund ticker",
  numUnits: "Number of units",
  nameTicker: "Ticker name",
  numShares: "Number of shares",
  totalValue: "Value",
  shareName: "Share name",
  market: "Market",
  currency: "Currency",
  coinName: "Coin name",
  coinID: "Coin ID",
  message: "Message",
  numCoins: "Number of coins",
  pensionType: "Pension type",
  pensionProviderName: "Provider name",
  employerName: "Employer",
  pensionID: "Pension ID",
  postcode: "Postcode",
  bedroom: "Bedroom",
  businessName: "Business name",
  companyName: "Company name",
  companyID: "Company ID",
  nameOfItem: "Name of item",
  nameOfEntityLentTo: "Lent to",
  cash: "Cash",
  bankAccounts: "Bank accounts",
  cryptoAccounts: "Crypto accounts",
  stockAccounts: "Stock accounts",
  debts: "Debts",
  commodities: "Commodities",
  childTrustFunds: "Child trust funds",
  funds: "Funds",
  stocks: "Stocks",
  crypto: "Crypto",
  pensions: "Pensions",
  pensionName: "Pension name",
  properties: "Properties",
  businessAssets: "Business assets",
  startups: "Startups",
  agricultureAssets: "Agriculture assets",
  alternativeInvestments: "Alternative investments",
  owedAssets: "Owed assets",
  sponsName: "Name",
  donation: "Donation amount",
  sponsBqPercentage: "Bequest percentage",
  giftAid: "Gift Aid",
  giftAidTitle: "Title",
};
