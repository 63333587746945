import { MarriageStatusType } from "@ifgengineering/hip-app-domain";
import React from "react";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "../../../../../components/Wills/constants/types";

type Props = {
  husband: FamilyMemberObject[];
  marriageStatusType: MarriageStatusType;
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean) => void;
  onBack: () => void;
};

export const Husband = ({
  husband,
  marriageStatusType,
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  return (
    <FamilyComposition
      title="Please enter your husband’s details"
      buttonText="Add your husband's info"
      modalTitle="About your husband"
      limit={1}
      data={husband}
      onBack={onBack}
      onSave={(value) => {
        onChange({
          ...value,
          familyMemberType: FamilyMemberTypes.Husband,
          marriageStatustype: marriageStatusType,
        });
      }}
      onRemove={(id) => onDelete(id)}
      onFinish={() => onFinish(Boolean(husband.length))}
    />
  );
};
