import { Router } from "@reach/router";
import React, { useEffect } from "react";
import Dashboard from "../views/dashboard";
import Summary from "../views/summary";
import Wills from "../views/wills";
import WillsUpgrade from "../views/wills/upgrade";
import Forms from "../views/forms";
import PrivateRoute from "../components/PrivateRoute";
import NavigationLayout from "../layouts/navigationLayout";
import PurchaseSummary from "./payment/purchase-normal";
import { navigate } from "gatsby";
import Loading from "@components/Loading";
import SecurityLayer from "@ifgengineering/client-security-layer";
import authSdk from "@components/Login/authSDK";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { getUserSelector } from "../store/user/user.selectors";
import styled from "styled-components";

const { refreshAccessToken, userInfo } = authSdk;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  height: -webkit-fill-available;
  overflow: hidden;
`;

export const App: React.FC = () => {
  const user = useSelector(getUserSelector);

  useEffect(() => {
    if (user.email) {
      Sentry.setUser({ email: user.email });
    }
  }, [user]);

  return (
    <PageWrapper>
      <SecurityLayer
        refreshAccessToken={refreshAccessToken}
        userInfo={userInfo}
        failureCallback={() => navigate("/auth/login/")}
        loadingComponent={<Loading />}
        userInfoSuccessCallback={(user) => user}
      >
        <Router component={NavigationLayout} basepath="/app">
          <PrivateRoute path="/" component={Wills} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/wills" component={Wills} />
          <PrivateRoute
            path="/wills/upgrade/:will-id"
            component={WillsUpgrade}
          />
          <PrivateRoute path="/wills/:form" component={Forms} />
          <PrivateRoute path="/summary" component={Summary} />
          <PrivateRoute path="/payment" component={PurchaseSummary} />
        </Router>
      </SecurityLayer>
    </PageWrapper>
  );
};

export default App;
