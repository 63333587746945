import styled from "styled-components";
import { theme } from "../../../../../styles/legacy-theme";

type StyledRadioInputProps = {
  checked: boolean;
  value: string;
  name?: string;
  onClick: () => void;
};

export const StyledRadioInput = styled.div<StyledRadioInputProps>`
  cursor: ${({ checked }) => (checked ? "default" : "pointer")};
  width: 1.375rem;
  height:  1.375rem;
  margin: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: ${({ checked }) => (checked ? "2px" : "1px")} solid
  ${theme.colors.Primary01};

  &::after {
    content: "";
    border-radius: 50%;
    width: 0.625rem;
    height: 0.625rem;
    background: ${({ checked }) => (checked ? theme.colors.Primary01 : "#fff")};
    border: 1px solid ${theme.colors.Primary01};
  }
}
`;

export default StyledRadioInput;
