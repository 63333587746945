import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "../../../../../components/Wills/constants/types";
import { maternalSiblingsSelector } from "../../../../../components/Wills/selectors/family";

type Props = {
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean) => void;
  onBack: () => void;
};

export const MaternalSiblings = ({
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const maternalSiblings = useSelector(maternalSiblingsSelector);

  useEffect(() => {
    if (maternalSiblings.length) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [maternalSiblings.length]);

  return (
    <>
      {showForm ? (
        <FamilyComposition
          title="Do you have any maternal half-siblings?"
          description="These are any siblings that you share the same mother with, but a different father"
          buttonText="Add a maternal half-sibling"
          modalTitle="Maternal half-siblings"
          formConfig={{
            askGender: true,
          }}
          dropDownOptions={[
            {
              label: "Male",
              value: FamilyMemberTypes.MaternalBrothers,
            },
            {
              label: "Female",
              value: FamilyMemberTypes.MaternalSisters,
            },
          ]}
          data={maternalSiblings}
          onBack={onBack}
          onSave={(value) => onChange(value)}
          onRemove={(id) => onDelete(id)}
          onFinish={() => onFinish(Boolean(maternalSiblings.length))}
        />
      ) : (
        <YesNoQuestions
          title="Do you have any maternal half-siblings?"
          description="These are any siblings that you share the same mother with, but a different father"
          onChange={(value) => (value ? setShowForm(value) : onFinish(false))}
          onBack={onBack}
        />
      )}
    </>
  );
};
