import React from "react";
import styled from "styled-components";
import { renderGiftsAndBequestPage } from "./utils/renderGiftsAndBequestPage";
import { useSelector } from "react-redux";
import { currBQPage } from "../../../store/ui/selectors";

const Layout = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em 0;
  @media (max-width: 425px) {
    padding: 0;
  }
`;
const ContentContainer = styled.div`
  max-width: 327px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GiftsAndBequest: React.FC = () => {
  const page = useSelector(currBQPage);

  return (
    <Layout>
      <ContentContainer>{renderGiftsAndBequestPage(page)}</ContentContainer>
    </Layout>
  );
};

export default GiftsAndBequest;
