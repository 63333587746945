import React, { useContext, useState } from "react";
import {
  InnerWrapper,
  Layout,
  Text,
  Description,
  CardsContainer,
} from "../../styled";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import LockIcon from "@assets/icons/lock.svg";
import { getStartups } from "../../selectors";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import AddItemCard from "../../../../legacyComponents/AddItemCard/AddItemCard.component";
import AccountCard from "../../../../legacyComponents/AccountCard/AccountCard.component";
import { deleteStartupStore } from "../../actions";
import Modal from "../../../../legacyComponents/Modal/Modal.component";
import StartupForm from "./StartupForm";
import { StartupItemInterface } from "@ifgengineering/hip-app-domain";
import { OwedAssetItemObject } from "../../constants/types";
import { deleteStartupAPI } from "../../actions/startup";
import Tooltip from "@components/Tooltip";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

const emptyState: StartupItemInterface = {
  value: 0,
};

const Startup: React.FC<PageType> = ({
  nextPage,
  previousPage,
  showTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedStartup, setSelectedStartup] =
    useState<StartupItemInterface>(emptyState);
  const { currencySymbol } = useContext(CurrencyContext);
  const startupInvestments = useSelector(getStartups);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch();

  const deleteAPI = async (value: OwedAssetItemObject) => {
    await dispatch(
      deleteStartupAPI(value.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deleteStartupStore(value));
  };

  const onClose = () => {
    setOpen(false);
    setSelectedStartup(emptyState);
  };

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Startups</Text>
              <Description>
                If you have any startup investments (investments into a private
                company) you can add them here.
              </Description>
              {showTooltip && <Tooltip text={tooltipText.startups} />}
            </div>

            <CardsContainer>
              <AddItemCard
                text="Add startup"
                onClick={() => {
                  setOpen(true);
                  setSelectedStartup({
                    value: 0,
                  });
                }}
              />

              {startupInvestments.map((startup) => {
                const { value, description = "" } = startup;

                return (
                  <AccountCard
                    key={`${description}-${value}`}
                    name={description}
                    currency={currencySymbol}
                    balance={value}
                    onClickDelete={() => deleteAPI(startup)}
                    onClickEdit={() => {
                      setOpen(true);
                      setSelectedStartup(startup);
                    }}
                  />
                );
              })}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        <StartupForm
          title="Add startup investment"
          data={selectedStartup}
          previousPage={previousPage}
          onSubmit={onClose}
        />
      ) : (
        <Modal onClose={onClose} open={open}>
          <StartupForm
            title="Add startup investment"
            data={selectedStartup}
            previousPage={previousPage}
            onSubmit={onClose}
          />
        </Modal>
      )}
    </>
  );
};

export default Startup;
