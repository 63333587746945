import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_EXECUTORS_API,
  DELETE_EXECUTORS_API,
  UPDATE_EXECUTORS_API,
} from "../constants/actionTypes";
import { ExecutionerObject } from "../constants/types";

export const createExecutorsAPI = createAsyncThunk<
  ExecutionerObject[],
  {
    executors: ExecutionerObject[];
    email: string;
  }
>(CREATE_EXECUTORS_API, async ({ executors, email }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($executors: [ExecutionerInput!]!, $email: String!) {
          createExecutors(data: $executors, email: $email) {
            id
            firstName
            lastName
            email
            address {
              id
              addressLine1
              addressLine2
              city
              region
              country
              postcode
            }
          }
        }
      `,
      variables: {
        executors,
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createExecutors;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "createExecutorsAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateExecutorsAPI = createAsyncThunk<
  ExecutionerObject[],
  ExecutionerObject
>(UPDATE_EXECUTORS_API, async (executor) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($executor: ExecutionerEditInput!) {
          editExecutor(data: $executor) {
            id
            firstName
            lastName
            email
            address {
              id
              addressLine1
              addressLine2
              city
              region
              country
              postcode
            }
          }
        }
      `,
      variables: {
        executor,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.editExecutor;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateExecutorsAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteExecutorAPI = createAsyncThunk<void, number>(
  DELETE_EXECUTORS_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deleteExecutor(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deleteExecutorAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
