import { gql } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { UPDATE_CURRENCY_API } from "../constants/actionTypes";
import { updateCurrency } from "@components/PortfolioData/actions";
import { logException } from "@helpers/logException";

type UpdatePortfolioInput = {
  currency?: string;
};

export const updateCurrencyApi = createAsyncThunk<
  void,
  {
    data: UpdatePortfolioInput;
    email: string;
  }
>(UPDATE_CURRENCY_API, async ({ data, email }, { dispatch }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: UpdatePortfolioInput!, $email: String!) {
          updatePortfolio(updatePortfolioInput: $data, email: $email) {
            id
            currency
          }
        }
      `,
      variables: {
        data,
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    dispatch(
      updateCurrency({ currency: response.data.updatePortfolio.currency })
    );
    return response.data.updatePortfolio.currency;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "updateCurrencyApi",
      exception: e,
    });

    throw Error(e);
  }
});
