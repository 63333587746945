import { PAGES, State } from "..";

export const siblingsPage = (page: number) => {
  return (state: State): boolean => {
    const notAnswered = !state[page].has;
    const grandchildrenPage = state[PAGES.GRAND_CHILDREN];
    const paternalGrandFather = state[PAGES.PATERNAL_GRANDFATHER];

    const preConditions =
      !paternalGrandFather.answered &&
      grandchildrenPage.has &&
      Boolean(!grandchildrenPage.hasMale);

    return notAnswered && preConditions;
  };
};
