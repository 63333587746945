import React, { useContext, useState } from "react";
import {
  InnerWrapper,
  Layout,
  Text,
  Description,
  CardsContainer,
} from "../../styled";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import LockIcon from "@assets/icons/lock.svg";
import { getChildTrustFundList } from "../../selectors";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import AddItemCard from "@legacyComponents/AddItemCard/AddItemCard.component";
import AccountCard from "@legacyComponents/AccountCard/AccountCard.component";
import { deleteChildTrustItemStore } from "../../actions";
import Modal from "@legacyComponents/Modal/Modal.component";
import ChildTrustForm from "./ChildTrustForm";
import { ChildTrustFundItemInterface } from "@ifgengineering/hip-app-domain";
import { deleteChildTrustAPI } from "../../actions/childTrust";
import Tooltip from "@components/Tooltip";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

const emptyState: ChildTrustFundItemInterface = {
  totalValue: 0,
};

const ChildTrustFund: React.FC<PageType> = ({
  nextPage,
  previousPage,
  showTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedTrust, setSelectedTurst] =
    useState<ChildTrustFundItemInterface>(emptyState);

  const trustList = useSelector(getChildTrustFundList);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const { currencySymbol } = useContext(CurrencyContext);
  const dispatch = useDispatch();

  const onClose = () => {
    setOpen(false);
    setSelectedTurst(emptyState);
  };

  const deleteAPI = async (value: ChildTrustFundItemInterface) => {
    await dispatch(
      deleteChildTrustAPI(value.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deleteChildTrustItemStore(value));
  };

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Child Trust Fund</Text>
              <Description>
                This is an investment you’ve set up on behalf of your child.
                E.g. a Junior ISA
              </Description>
              {showTooltip && <Tooltip text={tooltipText.childTrustFund} />}
            </div>

            <CardsContainer>
              <AddItemCard
                text="Add Child Trust Fund"
                onClick={() => {
                  setOpen(true);
                  setSelectedTurst({
                    totalValue: 0,
                  });
                }}
              />

              {trustList.map((trust) => {
                const { totalValue = 0, description = "" } = trust;

                return (
                  <AccountCard
                    key={`${description}-${totalValue}`}
                    name={description}
                    currency={currencySymbol}
                    balance={totalValue}
                    onClickDelete={() => deleteAPI(trust)}
                    onClickEdit={() => {
                      setOpen(true);
                      setSelectedTurst(trust);
                    }}
                  />
                );
              })}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        <ChildTrustForm
          title="Add Child Trust Fund"
          fund={selectedTrust}
          previousPage={previousPage}
          onSubmit={onClose}
        />
      ) : (
        <Modal onClose={onClose} open={open}>
          <ChildTrustForm
            title="Add Child Trust Fund"
            fund={selectedTrust}
            previousPage={previousPage}
            onSubmit={onClose}
          />
        </Modal>
      )}
    </>
  );
};

export default ChildTrustFund;
