import { PAGES } from "../../../../components/Family/NavigationFlow";

import { Spouse } from "./Spouse";
import { Father } from "./Father";
import { Mother } from "./Mother";
import { Children } from "./Children";
import { PaternalGrandfather } from "./PaternalGrandfather";
import { PaternalGrandmother } from "./PaternalGrandmother";
import { MaternalGrandmother } from "./MaternalGrandmother";
import { Grandchildren } from "./Grandchildren";
import { Siblings } from "./Siblings";
import { MaternalSiblings } from "./MaternalSiblings";
import { PaternalBrothers } from "./PaternalBrothers";
import { PaternalSisters } from "./PaternalSisters";
import { FullBrothersSon } from "./FullBrothersSon";
import { PaternalBrothersSon } from "./PaternalBrothersSon";
import { FullUncles } from "./FullUncles";
import { FatherPaternalBrothers } from "./FatherPaternalBrothers";
import { FullPaternalUnclesSon } from "./FullPaternalUnclesSon";
import { FatherPaternalBrothersSon } from "./FatherPaternalBrothersSon";
import { FamilyMemberObject } from "../../../../../components/Wills/constants/types";

export const PAGES_COMPONENTS: Record<
  number,
  ({
    onChange,
    onDelete,
    onFinish,
    onBack,
  }: {
    onChange: (value: FamilyMemberObject) => void;
    onDelete: (id: string | undefined) => void;
    onFinish: (answered: boolean) => void;
    onBack: () => void;
  }) => JSX.Element
> = {
  [PAGES.SPOUSE]: Spouse,
  [PAGES.FATHER]: Father,
  [PAGES.MOTHER]: Mother,
  [PAGES.CHILDREN]: Children,
  [PAGES.PATERNAL_GRANDFATHER]: PaternalGrandfather,
  [PAGES.PATERNAL_GRANDMOTHER]: PaternalGrandmother,
  [PAGES.MATERNAL_GRANDMOTHER]: MaternalGrandmother,
  [PAGES.GRAND_CHILDREN]: Grandchildren,
  [PAGES.SIBLINGS]: Siblings,
  [PAGES.MATERNAL_SIBLINGS]: MaternalSiblings,
  [PAGES.PATERNAL_BROTHERS]: PaternalBrothers,
  [PAGES.PATERNAL_SISTERS]: PaternalSisters,
  [PAGES.FULL_BROTHERS_SON]: FullBrothersSon,
  [PAGES.PATERNAL_BROTHERS_SON]: PaternalBrothersSon,
  [PAGES.FULL_UNCLES]: FullUncles,
  [PAGES.FATHER_PATERNAL_BROTHERS]: FatherPaternalBrothers,
  [PAGES.FULL_PATERNAL_UNCLES_SON]: FullPaternalUnclesSon,
  [PAGES.FATHER_PATERNAL_BROTHERS_SON]: FatherPaternalBrothersSon,
};
