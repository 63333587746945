import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import store from "../../../../store";
import { updateFamily } from "../../../../../components/Wills/actions";
import { updateFamilyAPI } from "../../../../../components/Wills/actions/family";
import { familySelector } from "../../../../../components/Wills/selectors/family";

type Props = {
  onFinish: (value: boolean) => void;
  onBack: () => void;
};

export const MemberDisabled = ({ onFinish, onBack }: Props): JSX.Element => {
  const [answer, setAnswer] = useState<boolean | null>(null);
  const family = useSelector(familySelector);
  const dispatch = useDispatch<typeof store.dispatch>();
  const { id, memberReceivingTestedBenefits } = family;

  useEffect(() => {
    if (answer === null) {
      return;
    }

    onFinish(answer);
  }, [answer]);

  const onChange = async (value: boolean) => {
    const updatedFamily = await dispatch(
      updateFamilyAPI({
        id,
        memberReceivingTestedBenefits,
        memberDisabled: value,
      })
    ).unwrap();
    dispatch(updateFamily(updatedFamily));
  };

  return (
    <YesNoQuestions
      title="Are any of your beneficiaries disabled?"
      description="We ask this to make sure your beneficiaries don’t inadvertently lose their benefit by receiving this inheritance."
      onChange={(value) => {
        onChange(value);
        setAnswer(value);
      }}
      onBack={onBack}
    />
  );
};
