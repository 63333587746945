import React, { useState } from "react";
import styled from "styled-components";
import { Description, Text } from "../../../signup/styled";
import GeneralForm from "../forms/generalForm";
import AddItemCard from "../../../../../legacyComponents/AddItemCard/AddItemCard.component";
import StyledModal from "../../../../../legacyComponents/Modal/Modal.component";
import FormButton from "../../../../../legacyComponents/FormButton/FormButton.component";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../../../components/mediaQueries";
import { useDispatch, useSelector } from "react-redux";
import { guardianListSelector } from "../../../../../components/Wills/selectors";
import FamilyCard from "../../../../components/Cards/FamilyCard/FamilyCard.component";
import { GuardianObject } from "../../../../../components/Wills/constants/types";
import {
  deleteGuardianStore,
  updateGuardiansStore,
  updateGuardianStore,
} from "../../../../../components/Wills/actions";
import { ExecutorGuardianPages } from "../index";
import { navigate } from "@reach/router";
import {
  createGuardiansAPI,
  deleteGuardianAPI,
  updateGuardiansAPI,
} from "../../../../../components/Wills/actions/guardians";
import store from "../../../../store";
import { getUserEmail } from "../../../../../components/Login/selectors";

const Guardians: React.FC<{
  setPage: (page: ExecutorGuardianPages) => void;
}> = ({ setPage }) => {
  const [addMode, setAddMode] = useState<boolean>(false);
  const [selectedGuardian, setSelectedGuardian] =
    useState<GuardianObject | null>(null);
  const guardianList = useSelector(guardianListSelector);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const userEmail = useSelector(getUserEmail);
  const dispatch = useDispatch<typeof store.dispatch>();

  const closeModal = () => {
    setAddMode(false);
    setSelectedGuardian(null);
  };

  const handleAddGuardian = async (guardian: GuardianObject) => {
    const guardians = await dispatch(
      createGuardiansAPI({
        email: userEmail,
        guardian: [guardian],
      })
    ).unwrap();
    dispatch(updateGuardiansStore(guardians));
  };

  const handleRemoveGuardian = (guardian: GuardianObject) => {
    dispatch(deleteGuardianAPI(guardian.id as number));
    dispatch(deleteGuardianStore(guardian.id as number));
  };

  const handleEditGuardian = async (guardian: GuardianObject) => {
    const result = await dispatch(updateGuardiansAPI(guardian)).unwrap();
    dispatch(updateGuardianStore(result));
  };

  const renderGuardians = () =>
    guardianList.map((guard, key) => (
      <>
        <FamilyCard
          key={key}
          name={`${guard.firstName} ${guard.lastName}`}
          familyRelationship={`Guardian ${key + 1}`}
          enableDelete
          onRemove={() => handleRemoveGuardian(guard)}
          onClick={() => {
            setSelectedGuardian(guard);
            setAddMode(true);
          }}
        />
      </>
    ));

  return (
    <>
      {!addMode && (
        <FormWrapper>
          <div>
            <Text align="center">Guardians</Text>
            <Description>
              Your children will be taken care of by your guardians if everyone
              with parental responsibility for them has died before they turn
              18. This means that you should not choose your spouse as a
              guardian.
            </Description>
            <CardsContainer>
              <AddItemCard
                text="Add guardian"
                onClick={() => setAddMode(true)}
              />
              {renderGuardians()}
            </CardsContainer>
          </div>
          <div>
            <FormButton
              onClick={() => navigate("/app/wills")}
              text="Save & Continue"
              leftArrowOnClick={() => setPage(ExecutorGuardianPages.executors)}
              leftArrowLabel="Back"
            />
          </div>
        </FormWrapper>
      )}
      {isMobile && addMode ? (
        <GeneralForm
          setAddMode={closeModal}
          selected={selectedGuardian}
          addCallback={handleAddGuardian}
          editCallback={handleEditGuardian}
          searchDescription="If you don't know the address of your guardian, don't worry. Just leave this blank for now and you can always email us after submission."
        />
      ) : (
        <StyledModal open={addMode} onClose={closeModal}>
          <GeneralForm
            setAddMode={closeModal}
            selected={selectedGuardian}
            addCallback={handleAddGuardian}
            editCallback={handleEditGuardian}
            searchDescription="If you don't know the address of your guardian, don't worry. Just leave this blank for now and you can always email us after submission."
          />
        </StyledModal>
      )}
    </>
  );
};

export default Guardians;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80vh;
`;

const CardsContainer = styled.div`
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;
