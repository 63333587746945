import styled from "styled-components";
import { Close } from "@icon-park/react";
import Icon from "@icon-park/react/es/all";
import ReactTooltip from "react-tooltip";
import { Text } from "@ifgengineering/component-library";

export const StyledCross = styled(Close)`
  position: absolute;
  cursor: pointer;
  top: 0.75rem;
  right: 0.75rem;
`;
export const StyledTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 8px #d4d4d4 !important;
  opacity: 1 !important;
  border-radius: 8px !important;
  border: 0 !important;
  pointer-events: auto !important;
`;

export const TooltipWrapper = styled.div`
  padding: 10px;
`;

export const TitleText = styled(Text)`
  padding-bottom: 10px;
`;

export const LabelText = styled(Text)`
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const TooltipText = styled.div`
  width: 288px;
  font-size: 14px;
  white-space: break-spaces;
`;
