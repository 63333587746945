import { State } from "..";

export const genericDecision = (page: number, previousPage: number | null) => {
  return (state: State): boolean => {
    if (previousPage === null) {
      return !state[page].has;
    } else {
      const previous = state[previousPage];
      const answerNo = !state[page].has;
      const preConditions = previous.has && !previous.answered;

      return answerNo && preConditions;
    }
  };
};
