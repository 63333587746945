import React, { createContext, useContext, useEffect, useState } from "react";
import { Description, InnerWrapper, Layout, Spacer, Text } from "./styled";
import { ASSETS } from "./types";
import { pages } from "./constants/pages";
import CheckboxButton, {
  CheckboxButtonDirection,
} from "../../legacyComponents/CheckboxButton/CheckboxButton.component";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../mediaQueries";
import FormButton from "../../legacyComponents/FormButton/FormButton.component";
import { navigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import {
  getEstimatedEstateValue,
  getPortfolioData,
  getSelectedPortfolioData,
} from "./selectors";
import EstimatedEstateValue from "./pages/estimatedEstateValue";
import Currency from "./pages/currency";
import EnterAssetsDetailsQuestion from "./pages/enterAssetsDetailsQuestion";
import { updateEstimatedEstateAPI } from "./actions/estimatedEstateValue";
import { getUserEmail } from "../Login/selectors";
import Tooltip from "@components/Tooltip";
import { updateCrm } from "@helpers/crmUpdate";
import { getAssetValues } from "@helpers/getAssetValues";
import { useCurrencyData } from "@components/CurrencyContext/useCurrencyData";
import { CurrencyContext } from "@components/CurrencyContext";

export const AuthContext = createContext("");

interface AssetsProps {
  showTooltip?: boolean;
  showOverallEstatePage?: boolean;
  showCurrencyPage?: boolean;
  forceAssetsItems?: boolean;
  destinationPage: string;
  originPage: string;
}

const Assets: React.FC<AssetsProps> = ({
  showTooltip = false,
  showOverallEstatePage = false,
  showCurrencyPage = false,
  forceAssetsItems = false,
  destinationPage,
  originPage,
}) => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const portfolioList = useSelector(getSelectedPortfolioData) || [];
  const estimatedEstateVal = useSelector(getEstimatedEstateValue);
  const userEmail = useSelector(getUserEmail);
  const portfolioData = useSelector(getPortfolioData);
  const initialSelectedState = forceAssetsItems
    ? [ASSETS.debts, ASSETS.alternativeInvestments, ...portfolioList]
    : portfolioList;
  const [selectedAssets, setSelectedAssets] = useState<Array<string | number>>([
    ...new Set(initialSelectedState),
  ]);
  const [page, setPage] = useState<number>(0);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showEstateValuePage, setShowEstateValuePage] = useState<boolean>(
    showOverallEstatePage
  );
  const [
    showEnterAssetsDetailsQuestionPage,
    setShowEnterAssetsDetailsQuestionPage,
  ] = useState<boolean>(showOverallEstatePage);
  const [showChooseCurrencyPage, setShowChooseCurrencyPage] =
    useState<boolean>(showCurrencyPage);
  const { currenciesData } = useCurrencyData();
  const { currency } = useContext(CurrencyContext);
  const assetArray = Object.keys(pages) as ASSETS[];

  useEffect(() => {
    if (forceAssetsItems && selectedAssets.length === 2)
      setSelectedAssets([
        ...new Set([
          ASSETS.debts,
          ASSETS.alternativeInvestments,
          ...portfolioList,
        ]),
      ]);

    if (!forceAssetsItems && !selectedAssets.length)
      setSelectedAssets(portfolioList);
  }, [portfolioList]);

  useEffect(() => {
    const { netWorth, numberOfAssets } = getAssetValues(portfolioData);

    const rate = currenciesData.find((obj) => {
      return obj.sourceCurrency === currency;
    })?.conversionRate;

    if (rate) {
      updateCrm({
        payload: {
          net_worth: netWorth ? `${netWorth * rate}` : "0",
          number_of_assets: numberOfAssets ? `${numberOfAssets}` : "0",
        },
      });
    }
  }, [portfolioData, currenciesData, currency]);

  const buttonDefinitions = () =>
    assetArray.map((asset) => ({
      key: `button-${asset}`,
      text: pages[asset].label,
      value: asset,
      disabled:
        forceAssetsItems &&
        (asset === ASSETS.debts || asset === ASSETS.alternativeInvestments),
    }));

  const nextPage: (currentPage: number) => void = (currentPage) => {
    !showForm && setShowForm(true);
    if (!showForm && selectedAssets.includes(assetArray[0])) return;

    if (currentPage > assetArray.length) {
      return navigate(destinationPage);
    }

    selectedAssets.includes(assetArray[currentPage + 1])
      ? setPage(currentPage + 1)
      : nextPage(currentPage + 1);
  };

  const previousPage: (currentPage: number) => void = (currentPage) => {
    if (currentPage < 0) {
      return navigate(originPage);
    }

    if (currentPage === 0 && showForm) {
      setPage(0);
      return setShowForm(false);
    }

    selectedAssets.includes(assetArray[currentPage - 1])
      ? setPage(currentPage - 1)
      : previousPage(currentPage - 1);
  };

  const dispatch = useDispatch();

  const RenderPage = pages[assetArray[page]].page;

  switch (true) {
    case showChooseCurrencyPage && showCurrencyPage:
      return (
        <Currency
          showTooltip={showTooltip}
          onClickContinue={() => {
            setShowChooseCurrencyPage(false);
          }}
        />
      );
    case showEstateValuePage && showOverallEstatePage:
      return (
        <EstimatedEstateValue
          onClickContinue={() => {
            dispatch(
              updateEstimatedEstateAPI({
                email: userEmail,
                data: { estimatedEstateValue: estimatedEstateVal },
              })
            );
            setShowEstateValuePage(false);
            setShowEnterAssetsDetailsQuestionPage(true);
          }}
          onClickBack={() => navigate("/app/wills")}
        />
      );
    case showEnterAssetsDetailsQuestionPage && showOverallEstatePage:
      return (
        <EnterAssetsDetailsQuestion
          destinationPage={destinationPage}
          onClickContinue={() => {
            setShowEnterAssetsDetailsQuestionPage(false);
          }}
          onClickBack={() => {
            setShowEnterAssetsDetailsQuestionPage(false);
            setShowEstateValuePage(true);
          }}
        />
      );
    case showForm:
      return (
        <AuthContext.Provider value={userEmail}>
          <RenderPage
            nextPage={() => nextPage(page)}
            previousPage={() => previousPage(page)}
            showTooltip={showTooltip}
          />
        </AuthContext.Provider>
      );
    default:
      return (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">What I own</Text>
              <Description>
                Please select the options that you think may apply to you. If
                you’re not sure about an option, select it anyway and more
                information will follow to help you decide.
              </Description>
              <Tooltip
                label="Important Information"
                text="If you have a property to flip, short term shares or SME finance investments please select 'other'."
              />
            </div>
          </InnerWrapper>
          <CheckboxButton
            direction={
              isMobile
                ? CheckboxButtonDirection.VERTICAL
                : CheckboxButtonDirection.HORIZONTAL
            }
            buttons={buttonDefinitions()}
            onChange={(value) => setSelectedAssets(value)}
            values={selectedAssets}
          />
          <InnerWrapper>
            <Spacer />
            <FormButton
              active={selectedAssets.length > 0}
              onClick={() => nextPage(page)}
              text="Save & Continue"
              leftArrowOnClick={() => {
                if (showOverallEstatePage) {
                  setShowEstateValuePage(true);
                }

                if (showCurrencyPage) {
                  setShowChooseCurrencyPage(true);
                }
              }}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      );
  }
};

export default Assets;
