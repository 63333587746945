import * as Yup from "yup";

export const Schema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.lazy((value) => {
    if (value) {
      return Yup.string().email("Invalid email");
    }

    return Yup.mixed();
  }),
});
