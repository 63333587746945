import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "../../../../../components/Wills/constants/types";
import { paternalGrandfathersSelector } from "../../../../../components/Wills/selectors/family";

type Props = {
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean) => void;
  onBack: () => void;
};

export const PaternalGrandfather = ({
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const paternalGrandfathers = useSelector(paternalGrandfathersSelector);

  useEffect(() => {
    if (paternalGrandfathers.length) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [paternalGrandfathers.length]);

  return (
    <>
      {showForm ? (
        <FamilyComposition
          title="Do you have a paternal grandfather?"
          description="This is your father’s father"
          buttonText="Add your paternal grandfather's info"
          modalTitle="About your paternal grandfather"
          limit={1}
          data={paternalGrandfathers}
          onBack={onBack}
          onSave={(value) => {
            onChange({
              ...value,
              familyMemberType: FamilyMemberTypes.PaternalGrandfather,
            });
          }}
          onRemove={(id) => onDelete(id)}
          onFinish={() => onFinish(Boolean(paternalGrandfathers.length))}
        />
      ) : (
        <YesNoQuestions
          title="Do you have a paternal grandfather?"
          description="This is your father’s father"
          onChange={(value) => (value ? setShowForm(value) : onFinish(false))}
          onBack={onBack}
        />
      )}
    </>
  );
};
