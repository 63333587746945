import { WillInterface } from "@ifgengineering/hip-app-domain";
import { SectionStatus } from "./sectionStatus";

const aboutMeStatus = (will: WillInterface): SectionStatus => {
  const { userInfo } = will;

  if (!userInfo) {
    return SectionStatus.NOT_YET_STARTED;
  }

  const { firstName, lastName, gender, mobileNumber, address } = userInfo;
  const about = Boolean(firstName && lastName && gender);
  const contactDetails = mobileNumber && address;

  if (!about && !contactDetails) {
    return SectionStatus.NOT_YET_STARTED;
  }

  if (about && !contactDetails) {
    return SectionStatus.IN_PROGRESS;
  }

  return SectionStatus.COMPLETE;
};

export default aboutMeStatus;
