import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_COMMODITY_API,
  DELETE_COMMODITY_API,
  UPDATE_COMMODITY_API,
} from "../constants/actionTypes";
import { CommodityItemObject, CommodityObject } from "../constants/types";

export const createCommodityAPI = createAsyncThunk<
  CommodityObject,
  {
    commodities: CommodityItemObject[];
    email: string;
  }
>(CREATE_COMMODITY_API, async ({ commodities, email }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($commodities: [CreateCommodityItemInput!]!, $email: String!) {
          createCommodityItems(data: $commodities, email: $email) {
            id
            totalValue
            commoditiesList {
              id
              name
              commodityType
              description
              monetaryValue
              carat
              weight
            }
          }
        }
      `,
      variables: {
        commodities,
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createCommodityItems;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "createCommodityAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateCommodityAPI = createAsyncThunk<
  CommodityItemObject[],
  CommodityItemObject
>(UPDATE_COMMODITY_API, async (commodity) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($commodity: UpdateCommodityItem!) {
          updateCommodityItem(data: $commodity) {
            id
            name
            commodityType
            description
            monetaryValue
            carat
            weight
          }
        }
      `,
      variables: {
        commodity,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.updateCommodityItem;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateCommodityAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteCommodityAPI = createAsyncThunk<void, number>(
  DELETE_COMMODITY_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deleteCommodityItem(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return [];
      }

      return response.data.deleteCommodityItem;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deleteCommodityAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
