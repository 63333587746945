import { navigate } from "@reach/router";
import { DashboardEnums } from "../../dashboard/types/dashboardEnums";
import { Forms } from "../../forms";
import { WillEnums } from "../types/willEnums";

export type Template = {
  testId: string;
  title: string;
  description: string[];
  button: string;
  additionalInfo: null | string;
  onClick?: () => void;
  customButton?: any;
};

export type Templates = Partial<Record<WillEnums | DashboardEnums, Template>>;

const getStarted = {
  title: "Get Started",
  description: [
    "First up, some really quick Islamic knowledge which will help you with your answers.",
    "The basic rule is that your assets get divided up according to Islamic law (we will figure that out for you). But you can leave 1/3 of your estate to anyone that isn't going to be part of your Will (e.g. a charity). We call this a bequest. You can make a bequest in the last section of the form.",
    "We’ll ask you only the crucial questions to put together your Will. We've designed this so that it should only take 20 minutes if you have all the information to hand.",
    "Ready? Click the Start button below to begin.",
  ],
  button: "Start",
  additionalInfo: null,
  testId: "get-started-navigate-button",
  onClick: (): Promise<void> => navigate(`/app/wills/${Forms.aboutYou}`),
};

const about = {
  title: "About Me",
  description: [
    "In this section we’ll ask for your basic details such as your name, address and date of birth.",
  ],
  button: "Continue",
  additionalInfo: null,
  testId: "about-me-navigate-button",
  onClick: (): Promise<void> => navigate(`/app/wills/${Forms.aboutYou}`),
};
const family = {
  title: "My Family",
  description: [
    "Let’s move on to your family.",
    "This section is really important for us to work out who will inherit from you and to calculate your Islamic inheritance percentages.",
    "Only include your Muslim relatives in this section- non-Muslim relatives can be allocated a share in the Bequest section. If you don't have Muslim relatives, a member of the team will get in touch to help once we've received your submission.",
  ],
  button: "Continue",
  additionalInfo: null,
  testId: "family-navigate-button",
  onClick: (): Promise<void> => navigate(`/app/wills/${Forms.myFamily}`),
};
const assets = {
  title: "Your Assets",
  description: [
    "In this section we’ll ask you about what you own.",
    "You’ll notice we are quite thorough with documenting your assets. We do this for two key reasons. First, it really helps executors out, and second, what information you enter in here, will double up as your zakat calculation. We’ll send you a prefilled calculation in Ramadan to save you some time.",
    "All the information you enter in this section is fully secure and will not be shared with anybody.",
  ],
  button: "Continue",
  additionalInfo: null,
  testId: "assets-navigate-button",
  onClick: (): Promise<void> => navigate(`/app/wills/${Forms.myAssets}`),
};
const executors = {
  title: "Executors & Guardians",
  description: [
    "In this section you will add your executors and guardians. These are people you appoint to sort out your will after you pass away and who will look after your estate until everything has been distributed.",
    "Being an executor or a guardian can be stressful. There are a few important things to consider if you’re choosing friends or family:",
    " • That they’re over 18",
    " • That you trust them",
    " • They’re good with finances and paperwork",
    " • You’re happy to tell them you’ve chosen them",
    " • We recommend you appoint two executors and two guardians",
  ],
  button: "Continue",
  additionalInfo: null,
  testId: "executors-navigate-button",
  onClick: (): Promise<void> =>
    navigate(`/app/wills/${Forms.executorsGuardians}`),
};
const gifts = {
  title: "Gifts & Bequests",
  description: [
    "Your assets get divided up to your family according to Islamic law. This has already been automatically calculated for you.  However, up to 1/3 of your estate can be left to anyone that isn't going to be part of your Will (e.g. a friend or a charity). We call this a bequest and you can leave some in this section",
    "This is a great place to leave a lasting Sadaqah jariyah to a cause you care about.",
    "The Prophet (peace and blessings be upon him) said:",
    "Charity does not decrease wealth, no one forgives another except that Allah increases his honor, and no one humbles himself for the sake of Allah except that Allah raises his status. [Muslim]",
  ],
  button: "Continue",
  additionalInfo: null,
  testId: "gifts-navigate-button",
  onClick: (): Promise<void> => navigate(`/app/wills/${Forms.giftsBequests}`),
};

const messages = {
  title: "Messages",
  description: [
    "Many people like to leave a last message for their loved ones. Our Will gives you this option",
  ],
  button: "Continue",
  additionalInfo: null,
  testId: "messages-navigate-button",
  onClick: (): Promise<void> => navigate(`/app/wills/${Forms.messages}`),
};

export const reviewSubmitTextCompletedNotPaid = [
  "Congratulations! You’ve finished writing your will. Review your answers carefully in the next section, and once you’re satisfied you can submit it to our team for checking",
];

export const reviewSubmitTextInProgress = [
  "You’ve not yet completed your Will. Please fill out all outstanding sections and once completed come back to this section to submit your Will to our expert team for checking! If you need any help, you can contact our team on: wills@islamicfinanceguru.com",
];

const review = {
  title: "Review & Submit",
  description: reviewSubmitTextCompletedNotPaid,
  button: "Continue",
  additionalInfo: null,
  testId: "review-submit-navigate-button",
  onClick: (): Promise<void> => navigate(`/app/summary`),
};

const templates: Templates = {
  assets,
  family,
  about,
  getStarted,
  executors,
  gifts,
  messages,
  review,
};

export default templates;
