import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LockIcon from "@assets/icons/lock.svg";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { updateEstimatedEstateValue } from "../../actions";
import { getEstimatedEstateValue } from "../../selectors";
import { InnerWrapper, Layout, Text, Description } from "../../styled";

interface EstimatedEstateValueProps {
  onClickContinue?: () => void;
  onClickBack?: () => void;
}

const EstimatedEstateValue: React.FC<EstimatedEstateValueProps> = ({
  onClickBack,
  onClickContinue,
}) => {
  const estimatedEstateVal = useSelector(getEstimatedEstateValue);

  useEffect(() => {
    if (estimatedEstateVal) {
      setValue(estimatedEstateVal?.toString());
    }
  }, [estimatedEstateVal]);

  const dispatch = useDispatch();

  const [value, setValue] = useState<string>(
    estimatedEstateVal?.toString() || ""
  );

  const [showErrMsg, setShowErrMsg] = useState<boolean>(false);
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (value) setShowErrMsg(false);

    dispatch(
      updateEstimatedEstateValue({
        estimatedEstateValue: Number.parseFloat(value),
      })
    );
  }, [value]);

  return (
    <Layout>
      <InnerWrapper>
        <Text>How much would you value your overall estate to be?</Text>
        <Description>
          Please add together all your assets including your home (taking away
          debts and mortgages)
        </Description>
        <Input
          heading="Amount GBP"
          type="number"
          description="This estimate doesn't need to be super accurate, just a rough one will do."
          value={value}
          errorMessage={
            showErrMsg ? "Your estimated estate value is required" : ""
          }
          onChange={handleValueChange}
        />
      </InnerWrapper>
      <InnerWrapper>
        <FormButton
          text="Save & Continue"
          onClick={
            value.length > 0 ? onClickContinue : () => setShowErrMsg(true)
          }
          leftArrowLabel="Back"
          disclaimerIcon={<LockIcon />}
          disclaimerText={
            <div>
              We take your{" "}
              <a
                href="https://www.islamicfinanceguru.com/security-policy"
                target="_blank"
                rel="noreferrer"
              >
                privacy seriously
              </a>
              . Our institutional-grade security ensures your data is
              confidential.
            </div>
          }
          leftArrowOnClick={onClickBack}
        />
      </InnerWrapper>
    </Layout>
  );
};

export default EstimatedEstateValue;
