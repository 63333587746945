import { PortfolioDataInterface } from "@ifgengineering/hip-app-domain";
import { SectionStatus } from "./sectionStatus";

const assetsStatus = (portfolioData: PortfolioDataInterface): SectionStatus => {
  return portfolioData.estimatedEstateValue !== undefined &&
    portfolioData.estimatedEstateValue !== 0
    ? SectionStatus.COMPLETE
    : SectionStatus.NOT_YET_STARTED;
};

export default assetsStatus;
