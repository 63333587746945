import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button, { ButtonStates } from "../Button/Button.component";

export enum CheckboxButtonDirection {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
}

type StyledCheckboxButtonWrapperProps = {
  direction: CheckboxButtonDirection;
};

const StyledCheckboxButtonWrapper = styled.div<StyledCheckboxButtonWrapperProps>`
  ${({ direction }) => {
    if (direction === CheckboxButtonDirection.VERTICAL) {
      return `
        display: flex;
        flex-direction: column;
          & > button {
            width: auto;
            margin-bottom: 1rem;
          }
      `;
    }

    return `
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1rem;
    `;
  }}
`;

export type CheckboxButtonDefinition = {
  key: string;
  text: string;
  value: string | number;
  disabled?: boolean;
};

type CheckboxButtonProps = {
  buttons: CheckboxButtonDefinition[];
  values?: Array<string | number>;
  direction?: CheckboxButtonDirection;
  onChange?: (value: Array<string | number>) => void;
};

const CheckboxButton = ({
  direction = CheckboxButtonDirection.HORIZONTAL,
  buttons,
  values = [],
  onChange,
}: CheckboxButtonProps): JSX.Element => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [selectedValues, setSelectedValues] = useState<Array<string | number>>(
    values || []
  );

  useEffect(() => {
    if (values) {
      const keys = buttons
        .filter((button) => values.includes(button.value))
        .map((button) => button.key);

      setSelectedKeys(keys);
      setSelectedValues(values);
    }
  }, [values.length]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedValues);
    }
  }, [selectedValues]);

  const itemIsSelected = (
    item: string | number,
    list: string[] | Array<string | number>
  ) => {
    return list.some((listItem) => listItem === item);
  };

  const toggleItemKey = (key: string) => {
    const isSelected = itemIsSelected(key, selectedKeys);

    if (isSelected) {
      const filteredList = selectedKeys.filter(
        (selectedKey) => selectedKey !== key
      );

      setSelectedKeys(filteredList);
    } else {
      setSelectedKeys([...selectedKeys, key]);
    }
  };

  const toggleItemValue = (value: string | number) => {
    const isSelected = itemIsSelected(value, selectedValues);

    if (isSelected) {
      const filteredList = selectedValues.filter(
        (selectedValue) => selectedValue !== value
      );

      setSelectedValues(filteredList);
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  return (
    <div>
      <StyledCheckboxButtonWrapper direction={direction}>
        {buttons.map(({ key, text, value, disabled }) => (
          <Button
            disabled={disabled}
            text={text}
            key={key}
            version={
              itemIsSelected(key, selectedKeys)
                ? ButtonStates.PRIMARY
                : ButtonStates.PRIMARY_GHOST_LARGE
            }
            onClick={() => {
              toggleItemValue(value);
              toggleItemKey(key);
            }}
          />
        ))}
      </StyledCheckboxButtonWrapper>
    </div>
  );
};

export default CheckboxButton;
