import { PAGES, State } from "..";

export const grandchildrenPage = (page: number) => {
  return (state: State): boolean => {
    const { has } = state[page];
    const children = state[PAGES.CHILDREN];
    const noSons = children.has && Boolean(!children.hasMale);

    return !has && noSons;
  };
};
