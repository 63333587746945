import { SectionTemplateItem } from "../constants/sections";
import { SectionStatus, StatusPerSection } from "./sectionStatus";

const labelPerStatus: Record<SectionStatus, string> = {
  [SectionStatus.NOT_YET_STARTED]: "Start",
  [SectionStatus.IN_PROGRESS]: "Continue",
  [SectionStatus.COMPLETE]: "Edit",
};

const setStatusToSections = (
  statuses: StatusPerSection,
  sections: SectionTemplateItem[]
): SectionTemplateItem[] => {
  return sections.map((section) => {
    const status = statuses[section.content];
    const buttonText = labelPerStatus[status];

    return {
      ...section,
      buttonText,
      status,
    };
  });
};

export default setStatusToSections;
