export const toolTipText = {
  currency:
    "We recommend that you read the important information in our tooltips to avoid overpaying your zakat",
  cash: "Zakat is payable on all your money and not just your savings. If you have rare or precious collector coins then these are likely to be classified as assets rather than cash and would not attract zakat as long as you are holding them as an investment.",
  bankAccounts:
    "Zakat is payable on your savings. If you have any interest (riba), this should not be included here and should all be given away.",
  goldAndSilver:
    "There are two schools of thought on whether you need to pay zakat on your everyday gold and silver jewellery. If you are a Hanafi then the position is that you should pay on this jewellery too. Some of the other schools exclude jewellery you wear from your calculation.",
  debts:
    "For Islamic mortgages you can deduct only the next month's payment as it is seen as a rent. For student loans you should only deduct the next month’s payment. For mainstream mortgages you can deduct the next 12 months payments as they are seen as a debt repayment. Any utilities bills do not need to be included.",
  childTrustFund:
    "Zakat is due on Child Trust Funds. In the Hanafi school, Zakat is not paid on children's wealth. In other schools, Zakat must be paid on children’s wealth. Please go with the opinion you prefer. When the child reaches puberty, it is their responsibility to pay. A parent can choose to pay on their behalf. Child Trust Funds are usually just an equities fund - and we will calculate your zakat on that basis.",
  sharesAndFunds:
    "If you bought shares with the sole intention to trade them short term, Zakat is payable at the full current market value - please put these in ‘other assets’ section." +
    "\n" +
    "\n" +
    "If you bought shares for any other reason (eg. dividend income) or with mixed intentions, then Zakat is payable on the underlying assets of the business and you should include them here. In this case we recommend 25% of the market value as a suitable proxy which the calculator will automatically apply.",
  pensions:
    "There are two types of pensions - defined benefit pension and defined contribution pensions. Defined benefit pensions are also called `final salary pensions` and are usually only offered by the public sector. You don't have to pay zakat on these so do not include them here. \n" +
    "\n" +
    "Defined contribution pensions are pensions where your money is actually being invested in something and your eventual pension is linked to the performance of that investment so they should be included here. If you are in the private sector you'll have one of these. Your zakatable pension is that portion of your underlying pension that is held in cash, receivables, inventory and other liquid assets. In this case we recommend 25% of the market value as a suitable proxy which the calculator will automatically apply.",
  crypto:
    "There is an ongoing debate among scholars whether crypto is best seen as a currency or an asset. For the purposes of our calculator we have played it safe and will treat crypto as currency, and calculate 2.5% on the full amount.\n" +
    "\n" +
    `For bitcoin and other currencies like it, our personal view is they are best understood as currency as you can actually use them to pay for things.\n` +
    `\n` +
    `For crypto tokens that you can’t buy and sell with and you are just investing in it as an asset - our view is that those should be excluded from zakat as long-term investments. However, as we said, we played it safe with the calculator and that's probably the way closest to taqwa.\n` +
    `\n` +
    `For NFTs that are held long- term, they are not zakatable.However for any income you make from NFTs, that would be zakatable so please include that here.\n` +
    `\n` +
    `For NFTs bought for resale in the next few months, the entire amount would become zakatable and you should include that in "Other Assets"`,
  property:
    "You will only need to pay zakat on properties that you have an intention of flipping. Please include these in the ‘other assets section’.  \n" +
    "\n" +
    "Your primary residence (the residence that you live in most of the year) and buy to let properties that you do not intend to resell any time soon are not zakatable, however we have included them in this section so we can analyse your entire portfolio to give you an optimal health check.",
  businessAssets:
    "If applicable, raw materials and work in progress should also be included at their current value",
  agricultureAssets:
    'If you have farmland, crops or livestock you are likely going to need to pay zakat on them. However the rules for that are complex and often the payment is non-monetary too, so for simplicity we have left it out of this calculator. However for completeness we wanted to flag this to you so you are aware. For further information on this we recommend Sh. Qaradawi\'s book "Fiqh Al Zakah".',
  startups:
    "You need to work out how much of a startup's zakatable assets you are directly an owner of. For this you need to know a combination of how much you invested, your percentage shareholding, the last valuation, the zakatable assets. Most people realistically will not know this. But they will know how much they invested. So our `approximation` approach uses this number, takes 25% of that as the likely zakatable assets percentage and works out zakat on that.",
  owedAssets:
    "If you are no longer confident you will receive the money owed to you, then you do not need to include it here.If you are not confident that your money will be repaid, then no zakat is due on it. If eventually it does get repaid, then you should just pay zakat on it at your next zakat anniversary. Zakat is applicable for the total amount of the loan that has to be repaid regardless of time. In regards to money expected from a sale, zakat is only applied to the money that is due in the next year.",
  other:
    "Please include any buy to flip properties, short term shares and SME finance investments here. If you have any other assets that you believe zakat is fully payable on, you can also input them here.",
};

export default toolTipText;
