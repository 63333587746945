import styled from "styled-components";
import { theme } from "../../styles/theme";
import Arrow from "../../../assets/icons/arrow.svg";

export const Content = styled.div<{ maxHeight: string }>`
  max-height: ${({ maxHeight }) => maxHeight};
  background-color: ${theme.colors.Primary02};
  overflow: hidden;
  transition: max-height 0.6s ease;
  padding: ${({ maxHeight }) => (maxHeight === "0px" ? "0" : "8px")};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.p`
  font-weight: bold;
`;

export const AccordionButton = styled.button<{ open: boolean }>`
  background-color: ${theme.colors.LightBlue};
  color: ${theme.colors.Font02};
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  width: 100%;
  transition: background-color 0.6s ease;
  justify-content: space-between;
`;

export const StyledArrow = styled(Arrow)<{ open: boolean }>`
  transform: ${({ open }) => !open && "rotate(180deg)"};
  transition: 0.3s ease;
`;
