export enum DebtType {
  MORTGAGE = "MORTGAGE",
  STUDENT_LOAN = "STUDENT_LOAN",
  MONEY_BORROWED = "MONEY_BORROWED",
  CREDIT_CARD = "CREDIT_CARD",
  CAR_FINANCE = "CAR_FINANCE",
  BANK_LOAN = "BANK_LOAN",
  OTHER = "OTHER",
}

export const DebtLabels: Record<DebtType, string> = {
  [DebtType.MORTGAGE]: "Mortgage",
  [DebtType.STUDENT_LOAN]: "Student Loan",
  [DebtType.MONEY_BORROWED]: "Money Borrowed",
  [DebtType.CREDIT_CARD]: "Credit Card",
  [DebtType.CAR_FINANCE]: "Car Finance",
  [DebtType.BANK_LOAN]: "Bank Loan",
  [DebtType.OTHER]: "Other Debts",
};

export const DebtTypesList: Array<{
  value: DebtType;
  label: string;
}> = [
  {
    value: DebtType.MORTGAGE,
    label: DebtLabels.MORTGAGE,
  },
  {
    value: DebtType.STUDENT_LOAN,
    label: DebtLabels.STUDENT_LOAN,
  },
  {
    value: DebtType.MONEY_BORROWED,
    label: DebtLabels.MONEY_BORROWED,
  },
  {
    value: DebtType.CREDIT_CARD,
    label: DebtLabels.CREDIT_CARD,
  },
  {
    value: DebtType.CAR_FINANCE,
    label: DebtLabels.CAR_FINANCE,
  },
  {
    value: DebtType.BANK_LOAN,
    label: DebtLabels.BANK_LOAN,
  },
  {
    value: DebtType.OTHER,
    label: DebtLabels.OTHER,
  },
];
