import { Templates } from "../../wills/constants/templates";

const noWill = {
  title: "Writing your will",
  testId: "writing-your-will-button",
  description: ["You have not yet completed writing your will"],
  button: "Start",
  additionalInfo: null,
};

const inReview = {
  title: "Your will is being processed",
  description: [
    "Salaam",
    "Your will is being processed by a member of the team!",
    "We'll let you know when it's ready",
    "It normally takes 1-2 weeks for a Will to be processed. You cannot ammend a Will while it’s being processed. If there’s something you want to add or change, get on contact with our team.",
  ],
  button: "Start",
  testId: "will-is-being-processed-button",
  additionalInfo: null,
};

const queries = {
  title: "We have a few queries about your Will",
  description: [
    "Salaam",
    "We wont be able to process your will until you resolved these queries!",
    "If you need to speak to a member of our team about your will, contact us here",
  ],
  button: "Resolve queries",
  testId: "few-queries-about-your-will-button",
  additionalInfo: null,
};

const complete = {
  title: "Your Will is complete and ready to download!",
  description: [
    "Salaam",
    "To make your Will legally binding you will need to print and sign your Will!",
    "If you need to speak to a member of our team about your will, contact us here",
  ],
  button: "Download Will",
  testId: "Will-is-complete-button",
  additionalInfo: "Would you like to include an Add-on?",
};

// these will be named to correspond with the status of the will for easier access
const templates: Templates = {
  noWill,
  inReview,
  queries,
  complete,
};

export default templates;
