import { State } from "../../../../components/Family/NavigationFlow/types";
import { PAGES } from "../../../../components/Family/NavigationFlow/contstants";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
  FamilyObject,
  UserInfoObject,
} from "../../../../../components/Wills/constants/types";
import { genericFilter } from "../../../../../components/Wills/selectors/family";
import { isThereAnotherAnswerFromHere } from "./isThereAnotherAnswerFromHere";

export const convertStoreToUIState = (
  familyStore: FamilyObject,
  userInfo: UserInfoObject
): State | undefined => {
  if (!familyStore) {
    return;
  }

  const familyFilter = genericFilter(familyStore.familyMembers);
  const combinedFamilyFilter = (familyTypeA: string, familyTypeB: string) => {
    return familyFilter(familyTypeA).concat(familyFilter(familyTypeB));
  };

  const stateItem = (familyMembers: FamilyMemberObject[]) => ({
    has: Boolean(familyMembers.length),
    answered: Boolean(familyMembers.length),
  });

  const makeSpouseData = (userInfo: UserInfoObject) => {
    return userInfo?.gender === "Male"
      ? stateItem(familyFilter(FamilyMemberTypes.Wife))
      : stateItem(familyFilter(FamilyMemberTypes.Husband));
  };

  const sons = familyFilter(FamilyMemberTypes.Son);
  const daughters = familyFilter(FamilyMemberTypes.Daughter);
  const grandSons = familyFilter(FamilyMemberTypes.Grandson);
  const grandSaughters = familyFilter(FamilyMemberTypes.Granddaughter);

  const componentState: State = {
    [PAGES.SPOUSE]: makeSpouseData(userInfo),
    [PAGES.FATHER]: stateItem(familyFilter(FamilyMemberTypes.Father)),
    [PAGES.MOTHER]: stateItem(familyFilter(FamilyMemberTypes.Mother)),
    [PAGES.CHILDREN]: {
      ...stateItem(
        combinedFamilyFilter(FamilyMemberTypes.Son, FamilyMemberTypes.Daughter)
      ),
      hasMale: Boolean(sons.length),
      hasFemale: Boolean(daughters.length),
    },
    [PAGES.PATERNAL_GRANDFATHER]: stateItem(
      familyFilter(FamilyMemberTypes.PaternalGrandfather)
    ),
    [PAGES.PATERNAL_GRANDMOTHER]: stateItem(
      familyFilter(FamilyMemberTypes.PaternalGrandmother)
    ),
    [PAGES.MATERNAL_GRANDMOTHER]: stateItem(
      familyFilter(FamilyMemberTypes.MaternalGrandmother)
    ),
    [PAGES.GRAND_CHILDREN]: {
      ...stateItem(
        combinedFamilyFilter(
          FamilyMemberTypes.Grandson,
          FamilyMemberTypes.Granddaughter
        )
      ),
      hasMale: Boolean(grandSons.length),
      hasFemale: Boolean(grandSaughters.length),
    },
    [PAGES.SIBLINGS]: {
      has: Boolean(
        combinedFamilyFilter(
          FamilyMemberTypes.Brother,
          FamilyMemberTypes.Sister
        ).length
      ),
      answered: Boolean(
        combinedFamilyFilter(
          FamilyMemberTypes.Brother,
          FamilyMemberTypes.Sister
        ).length
      ),
      hasMale: Boolean(familyFilter(FamilyMemberTypes.Brother).length),
      hasFemale: Boolean(familyFilter(FamilyMemberTypes.Sister).length),
    },
    [PAGES.MATERNAL_SIBLINGS]: stateItem(
      combinedFamilyFilter(
        FamilyMemberTypes.MaternalBrothers,
        FamilyMemberTypes.MaternalSisters
      )
    ),
    [PAGES.PATERNAL_BROTHERS]: stateItem(
      familyFilter(FamilyMemberTypes.PaternalBrothers)
    ),
    [PAGES.PATERNAL_SISTERS]: stateItem(
      familyFilter(FamilyMemberTypes.PaternalSisters)
    ),
    [PAGES.FULL_BROTHERS_SON]: stateItem(
      familyFilter(FamilyMemberTypes.FullBrothersSon)
    ),
    [PAGES.PATERNAL_BROTHERS_SON]: stateItem(
      familyFilter(FamilyMemberTypes.PaternalBrothersSon)
    ),
    [PAGES.FULL_UNCLES]: stateItem(
      familyFilter(FamilyMemberTypes.FullPaternalUncle)
    ),
    [PAGES.FATHER_PATERNAL_BROTHERS]: stateItem(
      familyFilter(FamilyMemberTypes.FathersPaternalBrothers)
    ),
    [PAGES.FULL_PATERNAL_UNCLES_SON]: stateItem(
      familyFilter(FamilyMemberTypes.FullPaternalUnclesSons)
    ),
    [PAGES.FATHER_PATERNAL_BROTHERS_SON]: stateItem(
      familyFilter(FamilyMemberTypes.FathersPaternalBrothersSons)
    ),
    [PAGES.MEMBER_RECEIVING_BENEFITS]: {
      has: familyStore.memberReceivingTestedBenefits !== null,
      answered:
        familyStore.memberReceivingTestedBenefits === null
          ? false
          : familyStore.memberReceivingTestedBenefits,
    },
    [PAGES.MEMBER_DISABLED]: {
      has: familyStore.memberDisabled !== null,
      answered:
        familyStore.memberDisabled === null
          ? false
          : familyStore.memberDisabled,
    },
  };

  Object.keys(componentState).forEach((key: string): void => {
    const intKey = Number.parseInt(key);
    if (!componentState[intKey].has) {
      componentState[intKey].has = isThereAnotherAnswerFromHere(
        intKey,
        componentState
      );
    }
  });

  return componentState;
};
