import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "../../../../../components/Wills/constants/types";
import { fullPaternalUncleSelector } from "../../../../../components/Wills/selectors/family";

type Props = {
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean) => void;
  onBack: () => void;
};

export const FullUncles = ({
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const fullPaternalUncle = useSelector(fullPaternalUncleSelector);

  useEffect(() => {
    if (fullPaternalUncle.length) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [fullPaternalUncle.length]);

  return (
    <>
      {showForm ? (
        <FamilyComposition
          title="Do you have any full paternal uncles?"
          description="This is your Father’s full brother"
          buttonText="Add a paternal uncle"
          modalTitle="Paternal Uncle"
          data={fullPaternalUncle}
          onBack={onBack}
          onSave={(value) => {
            onChange({
              ...value,
              familyMemberType: FamilyMemberTypes.FullPaternalUncle,
            });
          }}
          onRemove={(id) => onDelete(id)}
          onFinish={() => onFinish(Boolean(fullPaternalUncle.length))}
        />
      ) : (
        <YesNoQuestions
          title="Do you have any full paternal uncles?"
          description="This is your Father’s full brother"
          onChange={(value) => (value ? setShowForm(value) : onFinish(false))}
          onBack={onBack}
        />
      )}
    </>
  );
};
