import React from "react";
import { useParams } from "@reach/router";
import AboutYou from "./aboutYou";
import Family from "./family";
import ExecutorsGuardians from "./executorsGuardians";
import Messages from "./messages";
import GiftsAndBequest from "./giftsAndBequest";
import Assets from "../../../components/PortfolioData";

export enum Forms {
  aboutYou = "about",
  myFamily = "family",
  myAssets = "assets",
  executorsGuardians = "executors",
  giftsBequests = "gifts",
  messages = "messages",
  review = "review",
}

const WillForms: React.FC = () => {
  const { form } = useParams<{ form: Forms }>();

  const renderForm = {
    [Forms.aboutYou]: AboutYou,
    [Forms.myFamily]: Family,
    [Forms.myAssets]: () => (
      <Assets
        destinationPage="/app/wills"
        originPage="/app/wills"
        showOverallEstatePage
      />
    ),
    [Forms.executorsGuardians]: ExecutorsGuardians,
    [Forms.giftsBequests]: GiftsAndBequest,
    [Forms.messages]: Messages,
    [Forms.review]: () => <div>Review Placeholder</div>,
  };

  const FormComponent = renderForm[form];
  return <FormComponent />;
};

export default WillForms;
