import { PortfolioDataInterface } from "@ifgengineering/hip-app-domain";
import { labels } from "@Wealth/components/netWorthTracker/constants/labels";

export const getAssetValues = (portfolioData: PortfolioDataInterface) => {
  const debts = portfolioData?.debts?.totalValue || 0;
  const getText = (textKey: keyof typeof labels) => labels[textKey];

  const assets = Object.keys(portfolioData).map((asset) => {
    if (asset === "cash")
      return {
        label: getText(asset),
        value: portfolioData[asset]?.totalCashValue,
      };
    return {
      /** 
          Asset is string and we need it to be PortfolioDataInterface
          @ts-expect-error **/
      label: getText(asset as keyof PortfolioDataInterface),
      value: portfolioData[asset]?.totalValue || 0,
    };
  });

  const assetsWithTotals = assets.filter(
    (asset) =>
      !["id", "estimatedEstateValue", "currency"].includes(asset.label) &&
      asset.value !== 0
  );
  const totalAssetValue = assetsWithTotals.reduce(
    (acc, asset) => (asset.label === "Debts" ? acc : acc + asset.value),
    0
  );

  const netWorth = totalAssetValue - debts;

  const numberOfAssets = assetsWithTotals.length;

  return {
    netWorth: netWorth,
    totalAssetValue: totalAssetValue,
    debts: debts,
    assetsWithTotals: assetsWithTotals,
    numberOfAssets: numberOfAssets,
  };
};
