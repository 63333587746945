import React, { useContext } from "react";
import { Formik } from "formik";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { ChildTrustFundSchema } from "../../constants/validation";
import { ButtonsContainer, InnerWrapper, Text } from "../../styled";
import { ChildTrustFundItemInterface } from "@ifgengineering/hip-app-domain";
import { useDispatch } from "react-redux";
import LockIcon from "@assets/icons/lock.svg";
import {
  createChildTrustAPI,
  updateChildTrustItemAPI,
} from "../../actions/childTrust";
import {
  updateChildTrustItemStore,
  updateChildTrustStore,
} from "../../actions";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { CurrencyContext } from "@components/CurrencyContext";

type Props = {
  title: string;
  fund: ChildTrustFundItemInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

const ChildTrustForm = ({
  title,
  fund,
  previousPage,
  onSubmit,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const { currencySymbol } = useContext(CurrencyContext);
  const dispatch = useDispatch();

  const createAPI = async (values: ChildTrustFundItemInterface) => {
    const childTrust = await dispatch(
      createChildTrustAPI({
        email,
        data: [values],
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updateChildTrustStore(childTrust));
  };

  const updateAPI = async (values: ChildTrustFundItemInterface) => {
    const childTrust = await dispatch(
      updateChildTrustItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateChildTrustItemStore(childTrust));
  };

  return (
    <Formik
      validationSchema={ChildTrustFundSchema}
      initialValues={fund}
      onSubmit={(trust) => {
        if (trust.id) {
          updateAPI(trust);
        } else {
          createAPI(trust);
        }

        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        return (
          <FormikForm>
            <Text align="left">{title}</Text>

            <Input
              value={values.description?.toString() || ""}
              type="text"
              name="description"
              heading="Description"
              description="e.g. my child’s junior ISA held with AJ Bell"
              errorMessage={errors.description}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setFieldValue("description", e.target.value)}
            />

            <Input
              value={values.nameOfChild?.toString() || ""}
              type="text"
              name="nameOfChild"
              heading="Name of Child"
              errorMessage={
                touched.nameOfChild && errors.nameOfChild
                  ? errors.nameOfChild
                  : ""
              }
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setFieldValue("nameOfChild", e.target.value)}
            />

            <MoneyInput
              heading="Estimated Value"
              description="How much you think the fund is worth"
              value={values.totalValue.toString() || ""}
              currency={currencySymbol}
              onChange={(value) => setFieldValue("totalValue", Number(value))}
              errorMessage={
                touched.totalValue && errors.totalValue ? errors.totalValue : ""
              }
            />

            <ButtonsContainer>
              <FormButton
                onClick={submitForm}
                type="submit"
                text="Save & Continue"
                disclaimerIcon={<LockIcon />}
                disclaimerText={
                  <div>
                    We take your{" "}
                    <a
                      href="https://www.islamicfinanceguru.com/security-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy seriously
                    </a>
                    . Our institutional-grade security ensures your data is
                    confidential.
                  </div>
                }
                leftArrowOnClick={isMobile ? previousPage : undefined}
                leftArrowLabel={isMobile ? "Back" : undefined}
              />
            </ButtonsContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default ChildTrustForm;
