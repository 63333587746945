import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { UPDATE_ESTIMATED_ESTATE_VALUE_API } from "../constants/actionTypes";

type UpdatePortfolioInput = {
  estimatedEstateValue?: number;
};
export const updateEstimatedEstateAPI = createAsyncThunk<
  void,
  {
    data: UpdatePortfolioInput;
    email: string;
  }
>(UPDATE_ESTIMATED_ESTATE_VALUE_API, async ({ data, email }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: UpdatePortfolioInput!, $email: String!) {
          updatePortfolio(updatePortfolioInput: $data, email: $email) {
            id
            estimatedEstateValue
          }
        }
      `,
      variables: {
        data,
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.estimatedEstateValue;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateEstimatedEstateAPI",
      exception: e,
    });

    throw Error(e);
  }
});
