import { client } from "../../../graphql";
import { gql } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { logException } from "@helpers/logException";

export const UPDATE_APP_RECORD_API = "UPDATE_APP_RECORD_API";

export const updateAppRecordAPI = createAsyncThunk<null, string>(
  UPDATE_APP_RECORD_API,
  async (app) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($app: String!) {
            updateAppRecord(app: { name: $app }) {
              message
            }
          }
        `,
        variables: {
          app,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }

      return response.data.myUser;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "updateAppRecordAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
