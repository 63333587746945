import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_PROPERTY_API,
  DELETE_PROPERTY_API,
  UPDATE_PROPERTY_API,
} from "../constants/actionTypes";
import { PropertyItemObject, PropertyObject } from "../constants/types";

export const createPropertyAPI = createAsyncThunk<
  PropertyObject,
  {
    email: string;
    data: PropertyItemObject[];
  }
>(CREATE_PROPERTY_API, async ({ email, data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: [CreatePropertyItemInput!]!, $email: String!) {
          createPropertyAsset(data: $data, email: $email) {
            id
            totalValue
            propertyList {
              id
              name
              description
              postcode
              bedroom
              value
            }
          }
        }
      `,
      variables: {
        email,
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createPropertyAsset;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "createPropertyAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updatePropertyItemAPI = createAsyncThunk<
  PropertyItemObject,
  PropertyItemObject
>(UPDATE_PROPERTY_API, async (data) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: UpdatePropertyItemInput!) {
          updatePropertyItem(data: $data) {
            id
            name
            description
            postcode
            bedroom
            value
          }
        }
      `,
      variables: {
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.updatePropertyItem;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updatePropertyItemAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deletePropertyAPI = createAsyncThunk<string, number>(
  DELETE_PROPERTY_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deletePropertyItem(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return [];
      }

      return response.data.deletePropertyItem;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deletePropertyAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
