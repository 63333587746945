import React, { useContext } from "react";
import { Formik } from "formik";
import { useMediaQuery } from "react-responsive";
import LockIcon from "@assets/icons/lock.svg";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { OwedAssetItemSchema } from "../../constants/validation";
import { ButtonsContainer, InnerWrapper, Text } from "../../styled";
import { OwedAssetItemInterface } from "@ifgengineering/hip-app-domain";
import { useDispatch } from "react-redux";
import { OwedAssetItemObject } from "../../constants/types";
import {
  createOwedAssetAPI,
  updateOwedAssetItemAPI,
} from "../../actions/owedAssets";
import { updateOwedAssetsStore, updateOwedAssetStore } from "../../actions";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { CurrencyContext } from "@components/CurrencyContext";

type Props = {
  title: string;
  data: OwedAssetItemInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

const OwedAssetsForm = ({
  title,
  data,
  previousPage,
  onSubmit,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const createAPI = async (values: OwedAssetItemObject) => {
    const bankAccount = await dispatch(
      createOwedAssetAPI({
        email,
        data: [values],
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updateOwedAssetsStore(bankAccount));
  };

  const updateAPI = async (values: OwedAssetItemObject) => {
    const bankAccount = await dispatch(
      updateOwedAssetItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateOwedAssetStore(bankAccount));
  };

  return (
    <Formik
      validationSchema={OwedAssetItemSchema}
      initialValues={data}
      onSubmit={(owedAsset) => {
        if (owedAsset.id) {
          updateAPI(owedAsset);
        } else {
          createAPI(owedAsset);
        }

        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        return (
          <FormikForm>
            <Text align="left">{title}</Text>

            <Input
              value={values.description || ""}
              name="description"
              heading="Description"
              description="e.g. money I lent to my brother"
              isLong
              errorMessage={errors.description}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setFieldValue("description", e.target.value)}
            />

            <MoneyInput
              heading="Estimated total value"
              description="Enter the estimated amount of money this person / instituition owes to you. Try to be as accurate as possible."
              value={values.value.toString() || ""}
              onChange={(value) => setFieldValue("value", Number(value))}
              currency={currencySymbol}
              errorMessage={touched.value && errors.value ? errors.value : ""}
            />

            <ButtonsContainer>
              <FormButton
                onClick={submitForm}
                type="submit"
                text="Save & Continue"
                disclaimerIcon={<LockIcon />}
                disclaimerText={
                  <div>
                    We take your{" "}
                    <a
                      href="https://www.islamicfinanceguru.com/security-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy seriously
                    </a>
                    . Our institutional-grade security ensures your data is
                    confidential.
                  </div>
                }
                leftArrowOnClick={isMobile ? previousPage : undefined}
                leftArrowLabel={isMobile ? "Back" : undefined}
              />
            </ButtonsContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default OwedAssetsForm;
