import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import { userInfoSelector } from "../../../../../components/Wills/selectors";
import { Wives } from "./Wives";
import { Husband } from "./Husband";
import { FamilyMemberObject } from "../../../../../components/Wills/constants/types";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import {
  husbandSelector,
  wivesSelector,
} from "../../../../../components/Wills/selectors/family";
import { MarriageStatusType } from "@ifgengineering/hip-app-domain";

type Props = {
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean) => void;
  onBack: () => void;
};

const getMarriageStatusType = (
  islamicallyMarried: boolean | null,
  legallyMarried: boolean | null
): MarriageStatusType => {
  if (!islamicallyMarried && !legallyMarried) {
    return "N/A";
  }

  if (islamicallyMarried && legallyMarried) {
    return "Married Legally & Islamically";
  }

  if (islamicallyMarried) {
    return "Married Islamically";
  }

  return "Married Legally";
};

export const Spouse = (props: Props): JSX.Element => {
  const { onFinish } = props;
  const userInfo = useSelector(userInfoSelector);
  const [islamicallyMarried, setIslamicallyMarried] = useState<boolean | null>(
    null
  );
  const [legallyMarried, setLegallyMarried] = useState<boolean | null>(null);
  const husband = useSelector(husbandSelector);
  const wives = useSelector(wivesSelector);

  const hasFamilyMemebers = husband.length || wives.length;
  const hasAnsweredQuestions =
    islamicallyMarried !== null && legallyMarried !== null;
  const showLegallyMarriedQuestion =
    !hasFamilyMemebers && islamicallyMarried !== null;
  const showForm = hasFamilyMemebers || hasAnsweredQuestions;

  const marriageStatus = getMarriageStatusType(
    islamicallyMarried,
    legallyMarried
  );

  useEffect(() => {
    // it will bring the yes/no questions back
    // if the user remove all the partners info.
    setIslamicallyMarried(null);
    setLegallyMarried(null);
  }, [hasFamilyMemebers]);

  const onBack = () => navigate("/app/wills");

  const spouseForm =
    userInfo?.gender === "Male" ? (
      <Wives
        {...props}
        wives={wives}
        onBack={onBack}
        marriageStatusType={marriageStatus}
      />
    ) : (
      <Husband
        {...props}
        onBack={onBack}
        husband={husband}
        marriageStatusType={marriageStatus}
      />
    );

  return (
    <>
      {!hasFamilyMemebers && islamicallyMarried === null && (
        <YesNoQuestions
          title="Are you currently married Islamically?"
          description="Non-Muslim family members are not eligible for Islamic Inheritance and so shouldn’t be added here. You can still leave them a share of inheritance in the Bequests section."
          onChange={(value) =>
            value ? setIslamicallyMarried(value) : onFinish(false)
          }
          onBack={onBack}
        />
      )}
      {!showForm && showLegallyMarriedQuestion && (
        <YesNoQuestions
          title="Are you married legally?"
          description="I.e. your marriage was performed in compliance with legal requirements in the country in which it took place"
          onChange={(value) => setLegallyMarried(value)}
          onBack={onBack}
        />
      )}
      {showForm && spouseForm}
    </>
  );
};
