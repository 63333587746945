import React from "react";
import { GiftsAndBequestPageEnum, GiftsAndBequestPages } from "../types";

export const renderGiftsAndBequestPage = (
  page: keyof typeof GiftsAndBequestPages
): JSX.Element => {
  const {
    LANDING,
    NEW_INDIVIDUAL,
    EDIT_INDIVIDUAL,
    EXISTING_INDIVIDUAL,
    EXISTING_INDIVIDUAL_ADDRESS,
    ESTATE_ALLOCATION,
    PART_OR_ABOVE_SHARE,
    MEANS_TESTED_BENEFITS,
    DISABLED_BENEFICIARIES,
    NEW_CHARITY,
    CHARITY_ESTATE,
    EDIT_CHARITY,
    SPONSORED_CHARITY,
    SPONSORED_CHARITY_DONATION,
    SPONSORED_CHARITY_THANKS,
  } = GiftsAndBequestPageEnum;

  switch (page) {
    case LANDING:
      return <GiftsAndBequestPages.LANDING />;
    case NEW_INDIVIDUAL:
      return <GiftsAndBequestPages.NEW_INDIVIDUAL />;
    case EDIT_INDIVIDUAL:
      return <GiftsAndBequestPages.EDIT_INDIVIDUAL />;
    case EXISTING_INDIVIDUAL:
      return <GiftsAndBequestPages.EXISTING_INDIVIDUAL />;
    case EXISTING_INDIVIDUAL_ADDRESS:
      return <GiftsAndBequestPages.EXISTING_INDIVIDUAL_ADDRESS />;
    case ESTATE_ALLOCATION:
      return <GiftsAndBequestPages.ESTATE_ALLOCATION />;
    case PART_OR_ABOVE_SHARE:
      return <GiftsAndBequestPages.PART_OR_ABOVE_SHARE />;
    case MEANS_TESTED_BENEFITS:
      return <GiftsAndBequestPages.MEANS_TESTED_BENEFITS />;
    case DISABLED_BENEFICIARIES:
      return <GiftsAndBequestPages.DISABLED_BENEFICIARIES />;
    case NEW_CHARITY:
      return <GiftsAndBequestPages.NEW_CHARITY />;
    case EDIT_CHARITY:
      return <GiftsAndBequestPages.EDIT_CHARITY />;
    case CHARITY_ESTATE:
      return <GiftsAndBequestPages.CHARITY_ESTATE />;
    case SPONSORED_CHARITY:
      return <GiftsAndBequestPages.SPONSORED_CHARITY />;
    case SPONSORED_CHARITY_DONATION:
      return <GiftsAndBequestPages.SPONSORED_CHARITY_DONATION />;
    case SPONSORED_CHARITY_THANKS:
      return <GiftsAndBequestPages.SPONSORED_CHARITY_THANKS />;
    default:
      return <GiftsAndBequestPages.LANDING />;
  }
};

export default renderGiftsAndBequestPage;
