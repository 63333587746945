import React from "react";
import FamilyCard from "../../Cards/FamilyCard/FamilyCard.component";
import { FamilyMemberObject } from "../../../../components/Wills/constants/types";
import { ListItemsWrapper } from "./FamilyComposition.styled";

type Props = {
  item: FamilyMemberObject;
  maleMemberLabel?: string;
  femaleMemberLabel?: string;
  setSelectedFamilyMember: React.Dispatch<
    React.SetStateAction<FamilyMemberObject | undefined>
  >;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onRemove: (id: string | undefined) => void;
};

export const FamilyCompositionItem = ({
  item,
  setSelectedFamilyMember,
  setShowModal,
  onRemove,
}: Props): JSX.Element => {
  const { id, firstName, lastName, familyMemberType } = item;

  return (
    <ListItemsWrapper key={id}>
      <FamilyCard
        name={`${firstName} ${lastName}`}
        familyRelationship={familyMemberType}
        onClick={() => {
          setSelectedFamilyMember(item);
          setShowModal(true);
        }}
        enableDelete
        onRemove={() => onRemove(id)}
      />
    </ListItemsWrapper>
  );
};

export default FamilyCompositionItem;
