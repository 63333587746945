import React from "react";
import styled from "styled-components";
import { theme } from "../../../styles/legacy-theme";

export interface ProgressBarProps {
  percentage?: number;
  barColor?: string;
  barHeight?: number;
}

const BarContainer = styled.div`
  background-color: #eaeaea;
  border-radius: 30px;
`;

const Bar = styled.div<ProgressBarProps>`
  border-radius: 30px;
  width: ${(props) => props.percentage + "%"};
  height: ${(props) => props.barHeight + "px"};
  background-color: ${(props) => props.barColor};
`;

const ProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  barHeight = 8,
  barColor = theme.colors.Primary01,
}) => {
  return (
    <BarContainer>
      <Bar
        data-testid="progress-bar"
        barColor={barColor}
        barHeight={barHeight}
        percentage={percentage}
      />
    </BarContainer>
  );
};

export default ProgressBar;
