import React, { useState } from "react";
import styled from "styled-components";
import Arrow from "../../../assets/icons/keyboard_arrow_up.svg";
import { theme } from "../../styles/theme";

const ScrollComponent = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  bottom: 10px;
  right: 10px;
  background-color: ${theme.colors.Primary01};
  border-radius: 50%;
  cursor: pointer;
  box-shadow: ${`${theme.colors.Grey02} 0 0 8px 1px`};
  z-index: 1;

  &:hover {
    transform: scale(1.1);
  }
`;

const ScrollToTop: React.FC = () => {
  const [scrollAtTop, setScrollAtTop] = useState(window.scrollY < 100);
  window.addEventListener("scroll", () => {
    if (window.scrollY < 100 && !scrollAtTop) setScrollAtTop(true);
    if (window.scrollY > 100 && scrollAtTop) setScrollAtTop(false);
  });

  if (scrollAtTop) return null;
  return (
    <ScrollComponent
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }
    >
      <Arrow fill={theme.colors.Primary02} />
    </ScrollComponent>
  );
};

export default ScrollToTop;
