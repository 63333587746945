import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import FormNavigationButton, {
  NavigationDirections,
} from "../../../../../legacyComponents/FormNavigationButton/FormNavigationButton.component";
import PieChart from "../../../../components/PieChart/PieChart.component";
import Button from "../../../../../legacyComponents/Button/Button.component";
import ProgressBarCard from "../../../../components/Cards/ProgressBarCard/ProgessBarCard.component";
import { theme } from "../../../../styles/theme";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../../../components/mediaQueries";
import { familySelector } from "../../../../../components/Wills/selectors/family";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateInheritance,
  generateFamily,
} from "../../../../components/inheritance-calculator";
import {
  addSharesToFamilyMemebers,
  inheritanceAPIFriendly,
  inheritanceUserFriendlyPrint,
} from "../../../../components/inheritance-calculator/inheritanceUserFriendlyPrint";
import { populateInheritanceDataAPI } from "../../../../../components/Wills/actions/family";

type MobileProps = {
  isMobile: boolean;
};

export const Title = styled.h1`
  color: ${theme.colors.BrandNavy};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.87rem;
  margin-bottom: 10px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
`;

const Columns = styled.div<MobileProps>`
  display: flex;
  align-items: stretch;
  padding-bottom: ${({ isMobile }) => (isMobile ? "0" : "2rem")};
  ${({ isMobile }) => (isMobile ? "flex-direction: column;" : "")}
`;

const CardsColumn = styled.div<MobileProps>`
  width: ${({ isMobile }) => (isMobile ? "100%" : "40%")};
  padding: 20px;
`;

const ChartColumn = styled.div`
  width: 60%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardsWrapper = styled.div<MobileProps>`
  margin-top: ${({ isMobile }) => (isMobile ? "0" : "3rem")};
  & > div {
    margin: 12px 0;
  }
`;

const ButtonsWrapper = styled.div<MobileProps>`
  width: 310px;
  padding: ${({ isMobile }) => (isMobile ? "0" : "20px")};
`;

const SaveButtonWrapper = styled.div`
  padding-bottom: 2rem;
`;

const TotalWrapper = styled.div<MobileProps>`
  margin-top: ${({ isMobile }) => (isMobile ? "1rem" : "3rem")};
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.5rem;
`;

const TotalLabel = styled.div`
  color: ${theme.colors.Font02};
`;

const TotalValue = styled.div`
  color: ${theme.colors.Primary01};
`;

type Props = {
  onFinish: () => void;
  onBack: () => void;
};

export const FamilySummary = ({ onFinish, onBack }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const family = useSelector(familySelector);
  const { shares } = useMemo(
    () => calculateInheritance(generateFamily(family.familyMembers)),
    [family.familyMembers]
  );
  const uiShares = inheritanceUserFriendlyPrint(shares);

  const familyMembers = uiShares.map((share) => {
    const familyMember = family.familyMembers.find(
      (familyMember) => familyMember.id === Number.parseInt(share[0])
    );

    return {
      name: `${familyMember?.firstName} ${familyMember?.lastName}`,
      value: Number.parseFloat(share[1]),
    };
  });

  useEffect(() => {
    if (family.familyMembers.length) {
      dispatch(
        populateInheritanceDataAPI(
          addSharesToFamilyMemebers(family, inheritanceAPIFriendly(shares))
        )
      );
    }
  }, [family, shares]);

  return (
    <PageWrapper>
      <Columns isMobile={isMobile}>
        <CardsColumn isMobile={isMobile}>
          <Title>Summary of your Islamic inheritors</Title>
          <p>
            This is a summary of all your Islamic inheritors and the share they
            will receive distributed according to Islamic Law.
          </p>
          {isMobile && <PieChart data={familyMembers} />}
          <CardsWrapper isMobile={isMobile}>
            {familyMembers.map(({ name, value }) => (
              <ProgressBarCard
                percentage={value}
                text={name}
                key={`${name}-${value}`}
              />
            ))}
          </CardsWrapper>
          <TotalWrapper isMobile={isMobile}>
            <TotalLabel>Total</TotalLabel>
            <TotalValue>100%</TotalValue>
          </TotalWrapper>
        </CardsColumn>
        {!isMobile ? (
          <ChartColumn>
            <PieChart data={familyMembers} />
          </ChartColumn>
        ) : null}
      </Columns>
      <ButtonsWrapper isMobile={isMobile}>
        <SaveButtonWrapper>
          <Button text="Save & Continue" onClick={onFinish} />
        </SaveButtonWrapper>
        <FormNavigationButton
          onClick={onBack}
          text="Back"
          direction={NavigationDirections.BACKWARD}
        />
      </ButtonsWrapper>
    </PageWrapper>
  );
};

export default FamilySummary;
