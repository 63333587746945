import React from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { Section } from "./helpers";
import { PortfolioDataInterface } from "@ifgengineering/hip-app-domain/src";
import {
  Title,
  Text,
  WidthWrapper,
  HyperLink,
  ButtonWrapper,
  FlexCenter,
  ContentWrapperMobile,
  SectionsContainer,
} from "./styled";
import CardContainer from "@legacyComponents/CardContainer/CardContainer.component";
import { sections } from "./constants/sectionsSchema";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import { getFullPortfolioData } from "@components/PortfolioData/selectors";
import {
  willIsCompletePaidSelector,
  willSelector,
} from "@components/Wills/selectors";
import Button from "@legacyComponents/Button/Button.component";
import ScrollToTop from "../ScrollToTop";
import willsStatus, {
  WillsStatus,
} from "../../views/wills/helpers/willsStatus";
import { getCharity } from "@components/Charity/selectors";
import useAnalytics from "@helpers/useAnalytics";

const Summary: React.FC = () => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const willComplete = useSelector(willIsCompletePaidSelector);
  const portfolioData = useSelector(
    getFullPortfolioData
  ) as PortfolioDataInterface;
  const will = useSelector(willSelector);
  const sponsCharity = useSelector(getCharity);
  const status = willsStatus(will, portfolioData);

  const family = {
    ...will?.family,
    familyMembers: will?.family?.familyMembers,
  };

  const isThereAnyAssets = !!portfolioData.estimatedEstateValue;
  const isThereAnyFamilyMemeber = Boolean(family?.familyMembers?.length);

  const { sponsoredCharity, ...gbqNoSponsor } = sections.giftsBequests;
  const gbqSection = sponsCharity.id ? sections.giftsBequests : gbqNoSponsor;
  const bqCharity = will?.bequests?.bequestCharity || [];
  const gbq = {
    ...will?.bequests,
    bequestCharity: bqCharity
      .filter((bq) => !bq.partnerCharity)
      .map(({ fullName, message, money, percentageEstate }) => ({
        fullName,
        message,
        money,
        percentageEstate: percentageEstate ? percentageEstate / 100 : null,
      })),
    bequestIndividuals:
      will?.bequests?.bequestIndividuals?.map((bequest) => ({
        ...bequest,
        itemsToLeave: bequest.itemsToLeave?.join(", "),
        percentageEstate: (bequest.percentageEstate as number) / 100,
      })) || [],
  };

  const sponsorCharityPercentage =
    bqCharity
      .find((bq) => bq?.partnerCharity?.id === sponsCharity.id)
      ?.percentageEstate?.toString() || "-";

  const giftsBequests =
    !!sponsCharity.id &&
    (sponsCharity.donation || sponsorCharityPercentage !== "-")
      ? {
          sponsoredCharity: [
            {
              sponsName: sponsCharity?.displayName,
              donation: sponsCharity.donation?.amount,
              sponsBqPercentage: `${sponsorCharityPercentage}%`,
              giftAid: sponsCharity.donation?.giftAid,
              giftAidTitle: sponsCharity.donation?.title,
            },
          ],
          ...gbq,
        }
      : gbq;

  const fireAnalytics = useAnalytics("willSubmission");

  const Content = () => (
    <div>
      <Title>Summary</Title>
      <Text>
        Once you submit your Will, it will be checked by a qualified member of
        our team. <br />
        It normally takes 1-2 weeks for a Will to be processed. We&apos;ll let
        you know once it&apos;s ready.
        <br /> Please watch out for emails (including in your junk folder).
      </Text>
      <Text>
        You cannot amend a Will while it&apos;s being processed. If there&apos;s
        something you want to add or change, get in contact with our team:{" "}
        <a href="mailto:wills@islamicfinanceguru.com">
          wills@islamicfinanceguru.com
        </a>
      </Text>

      <Text>
        To help us create the best possible experience please let us know your
        feedback -{" "}
        <HyperLink
          href={"https://islamicfinanceguru.typeform.com/willsurvey"}
          target="_blank"
        >
          Complete survey
        </HyperLink>
        .
      </Text>

      {!!will && !willComplete && status === WillsStatus.COMPLETE_NOT_PAID && (
        <FlexCenter>
          <ButtonWrapper>
            <a href="/app/payment" target="_blank">
              <Button
                text="Continue to checkout"
                onClick={async () => (await fireAnalytics)()}
              />
            </a>
          </ButtonWrapper>
        </FlexCenter>
      )}
      <SectionsContainer>
        <Section
          section="About Me"
          schema={sections.aboutMe}
          data={will?.userInfo}
        />
        {isThereAnyFamilyMemeber && (
          <Section section="My Family" schema={sections.family} data={family} />
        )}
        {isThereAnyAssets && (
          <Section
            section="Assets"
            schema={sections.assets}
            data={portfolioData}
          />
        )}
        <Section
          section="Executors"
          schema={sections.executors}
          data={will?.overseer}
        />
        <Section
          section="Guardians"
          schema={sections.guardians}
          data={will?.overseer}
        />
        <Section
          section="Gifts & Bequests"
          schema={gbqSection}
          data={giftsBequests}
        />
        <Section
          section="Departing message"
          schema={sections.departingMessage}
          data={will?.departingMessage && will}
        />
        {!isMobile && <ScrollToTop />}
      </SectionsContainer>
    </div>
  );

  if (isMobile)
    return (
      <ContentWrapperMobile>
        <Content />
      </ContentWrapperMobile>
    );

  return (
    <WidthWrapper>
      <CardContainer padding={60}>
        <Content />
      </CardContainer>
    </WidthWrapper>
  );
};

export default Summary;
