import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const FormText = styled.span`
  color: ${theme.colors.Font02};
`;

export const FormTitle = styled.h1`
  color: ${theme.colors.BrandNavy};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.87rem;
  margin-bottom: 10px;
`;

export const FieldName = styled.div`
  font-weight: bold;
  margin: 10px 0 5px 0;
`;

export const ButtonWrapper = styled.div`
  padding-top: 20px;
`;
