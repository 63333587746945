import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "../../../../../components/Wills/constants/types";
import { fullBrothersSonSelector } from "../../../../../components/Wills/selectors/family";

type Props = {
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean) => void;
  onBack: () => void;
};

export const FullBrothersSon = ({
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const fullBrothersSon = useSelector(fullBrothersSonSelector);

  useEffect(() => {
    if (fullBrothersSon.length) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [fullBrothersSon.length]);

  return (
    <>
      {showForm ? (
        <FamilyComposition
          title="Do any of your full brothers have any sons?"
          description="These are your nephews. Your full brother shares both the same father and same mother as you."
          buttonText="Add a brother’s son"
          modalTitle="Brother’s son"
          limit={1}
          data={fullBrothersSon}
          onBack={onBack}
          onSave={(value) => {
            onChange({
              ...value,
              familyMemberType: FamilyMemberTypes.FullBrothersSon,
            });
          }}
          onRemove={(id) => onDelete(id)}
          onFinish={() => onFinish(Boolean(fullBrothersSon.length))}
        />
      ) : (
        <YesNoQuestions
          title="Do any of your full brothers have any sons?"
          description="These are your nephews. Your full brother shares both the same father and same mother as you."
          onChange={(value) => (value ? setShowForm(value) : onFinish(false))}
          onBack={onBack}
        />
      )}
    </>
  );
};
