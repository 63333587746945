import React from "react";
import styled from "styled-components";
import { theme } from "../../../../styles/legacy-theme";
import CardContainer, {
  CardContainerStyles,
} from "../../../../legacyComponents/CardContainer/CardContainer.component";
import Button from "../../../../legacyComponents/Button/Button.component";
import CheckIcon from "../../../../assets/icons/check_icon.svg";
import { SectionStatus } from "../../../views/wills/helpers/sectionStatus";

interface SectionCardProps {
  sectionNumber?: number;
  selected?: boolean;
  status?: SectionStatus;
  estimatedTime?: string;
  text?: string;
  buttonText: string;
  isOptional?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const SectionCardContainer = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const getCardStyle = (selected: boolean, status: SectionStatus) => {
  if (selected) {
    return CardContainerStyles.SELECTED;
  }

  if (status === SectionStatus.IN_PROGRESS) {
    return CardContainerStyles.IN_PROGRESS;
  }

  if (status === SectionStatus.COMPLETE) {
    return CardContainerStyles.COMPLETED;
  }

  return CardContainerStyles.HIGHLIGHTED;
};

const StyledIndicatorBackgrounds: Record<CardContainerStyles, string> = {
  [CardContainerStyles.SELECTED]: theme.colors.Primary01,
  [CardContainerStyles.COMPLETED]: theme.colors.CompleteGreen,
  [CardContainerStyles.IN_PROGRESS]: theme.colors.Primary02,
  [CardContainerStyles.HIGHLIGHTED]: theme.colors.Primary02,
  [CardContainerStyles.REGULAR]: theme.colors.Primary02,
};

const StyledIndicatorBorders = {
  [CardContainerStyles.SELECTED]: theme.colors.Primary02,
  [CardContainerStyles.COMPLETED]: theme.colors.CompleteGreen,
  [CardContainerStyles.IN_PROGRESS]: theme.colors.InProgress,
  [CardContainerStyles.HIGHLIGHTED]: theme.colors.Primary01,
  [CardContainerStyles.REGULAR]: theme.colors.Primary01,
};

const StyledIndicatorFontColor = {
  [CardContainerStyles.SELECTED]: theme.colors.Primary02,
  [CardContainerStyles.COMPLETED]: theme.colors.Primary01,
  [CardContainerStyles.IN_PROGRESS]: theme.colors.Primary01,
  [CardContainerStyles.HIGHLIGHTED]: theme.colors.Primary01,
  [CardContainerStyles.REGULAR]: theme.colors.Primary01,
};

type StyledStatus = {
  cardStyle: CardContainerStyles;
};

const StyledIndicator = styled.div<StyledStatus>`
  background: ${(props) => StyledIndicatorBackgrounds[props.cardStyle]};
  border: 2px solid ${(props) => StyledIndicatorBorders[props.cardStyle]};
  border-radius: 100%;
  height: 24px;
  left: -14px;
  margin-top: -12px;
  position: absolute;
  top: 50%;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 14px;
    height: 14px;
  }
`;

const StyledSectionNumber = styled.p<StyledStatus>`
  color: ${(props) => StyledIndicatorFontColor[props.cardStyle]};
`;

const SectionTitle = styled.p<Pick<SectionCardProps, "selected">>`
  margin: 0;
  padding: 0;
  font-size: 0.899rem;
  margin-top: 5px;
  color: ${(props) => (props.selected ? "white" : theme.colors.Font02)};
`;

const SectionText = styled.p<Pick<SectionCardProps, "selected">>`
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-top: 5px;
  color: ${(props) => (props.selected ? "white" : theme.colors.Font02)};
`;

const SectionCard: React.FC<SectionCardProps> = ({
  sectionNumber = 0,
  selected = false,
  status = SectionStatus.NOT_YET_STARTED,
  isOptional = false,
  estimatedTime = "",
  text,
  buttonText,
  onClick,
}) => {
  const cardStyle = getCardStyle(selected, status);

  const optimalText = isOptional ? "(Optional)" : "";
  const estimatedTimeText = estimatedTime ? `(${estimatedTime})` : "";

  const title = `Section ${sectionNumber} ${optimalText} ${estimatedTimeText}`;
  const testId = text?.toLowerCase().replaceAll(" ", "-");
  return (
    <CardContainer cardStyle={cardStyle}>
      <div>
        <StyledIndicator cardStyle={cardStyle}>
          {status === SectionStatus.COMPLETE ? (
            <CheckIcon />
          ) : (
            <StyledSectionNumber cardStyle={cardStyle}>
              {sectionNumber}
            </StyledSectionNumber>
          )}
        </StyledIndicator>
      </div>
      <SectionCardContainer>
        <div>
          {sectionNumber !== null && (
            <SectionTitle selected={selected}>{title}</SectionTitle>
          )}
          <SectionText selected={selected}>{text}</SectionText>
        </div>
        <div>
          <Button testId={testId} text={buttonText} onClick={onClick} />
        </div>
      </SectionCardContainer>
    </CardContainer>
  );
};

export default SectionCard;
