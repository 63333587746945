import React from "react";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import DropDown from "../../../../legacyComponents/DropDown/DropDown.component";
import Button from "../../../../legacyComponents/Button/Button.component";
import { Formik, Field, Form } from "formik";
import RadioInputGroup, {
  RadioInputDirection,
} from "../../../../legacyComponents/Form/input/RadioInput/RadioInputGroup.component";
import { RadioComponentProps } from "../../../../legacyComponents/Form/input/RadioInput";
import RadioInput from "../../../../legacyComponents/Form/input/RadioInput/RadioInput.component";

import {
  ButtonWrapper,
  FieldName,
  FormText,
  FormTitle,
} from "./FamilyForm.styled";
import { FamilyFormDropdownOptions } from "./types";
import { initialValues, validateGender, validateNames } from "./contstants";
import { FamilyMemberObject } from "../../../../components/Wills/constants/types";

type Props = {
  title: string;
  familyMember?: FamilyMemberObject;
  askGender?: boolean;
  askChildAge?: boolean;
  dropDownOptions?: FamilyFormDropdownOptions;
  onSave: (value: FamilyMemberObject) => void;
};

const showValidationMessage = (
  touched: boolean | undefined,
  errorMessage: string | undefined
): string | undefined => (touched ? errorMessage : undefined);

const FamilyForm = ({
  title,
  familyMember,
  askGender = false,
  askChildAge = false,
  dropDownOptions = [],
  onSave,
}: Props): JSX.Element => {
  const formInitialValues = familyMember || {
    ...initialValues,
    ...(askChildAge ? { over18: false } : {}),
  };
  const validationSchema = askGender
    ? validateNames.concat(validateGender)
    : validateNames;

  const submitForm = (values: FamilyMemberObject): FamilyMemberObject => {
    const { id, firstName, lastName, familyMemberType, over18 } = values;

    return {
      id,
      firstName,
      lastName,
      familyMemberType,
      ...(askChildAge && { over18 }),
    };
  };

  return (
    <div>
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          onSave(submitForm(values));
          resetForm();
        }}
      >
        {({ values, setFieldValue, submitForm, errors, touched }) => {
          const dropDownValue = {
            label:
              dropDownOptions?.find(
                (option) => option.value === values.familyMemberType
              )?.label || "",
            value: values.familyMemberType,
          };

          return (
            <div>
              <FormText>
                <Form>
                  <FormTitle>{title}</FormTitle>
                  <FieldName>First name (Including any middle names)</FieldName>
                  <Input
                    testId="firstName"
                    value={values.firstName}
                    errorMessage={showValidationMessage(
                      touched.firstName,
                      errors.firstName
                    )}
                    onChange={(e) =>
                      setFieldValue(
                        "firstName",
                        (e.target as HTMLInputElement).value
                      )
                    }
                  />
                  <FieldName>Last name</FieldName>
                  <Input
                    testId="lastName"
                    value={values.lastName}
                    errorMessage={showValidationMessage(
                      touched.lastName,
                      errors.lastName
                    )}
                    onChange={(e) =>
                      setFieldValue(
                        "lastName",
                        (e.target as HTMLInputElement).value
                      )
                    }
                  />
                  {askGender && (
                    <>
                      <FieldName>Gender</FieldName>
                      <DropDown
                        testId="familyMemberType"
                        errorMessage={showValidationMessage(
                          touched.familyMemberType,
                          errors.familyMemberType
                        )}
                        value={dropDownValue}
                        onChange={(item) =>
                          setFieldValue("familyMemberType", item?.value)
                        }
                        options={dropDownOptions}
                      />
                    </>
                  )}
                  {askChildAge && (
                    <>
                      <p>
                        We need to know if your child in below the UK’s legal
                        age as you’ll need to add a guardian
                      </p>

                      <RadioInputGroup direction={RadioInputDirection.VERTICAL}>
                        <Field
                          label="My child is below 18"
                          value={true}
                          checked={!values.over18}
                          onChange={(value: boolean) =>
                            setFieldValue("over18", !value)
                          }
                          component={(props: RadioComponentProps) => (
                            <RadioInput {...props} />
                          )}
                        />
                        <Field
                          label="My child is above 18"
                          value={false}
                          checked={values.over18}
                          onChange={(value: boolean) =>
                            setFieldValue("over18", !value)
                          }
                          component={(props: RadioComponentProps) => (
                            <RadioInput {...props} />
                          )}
                        />
                      </RadioInputGroup>
                    </>
                  )}
                </Form>
              </FormText>
              <ButtonWrapper>
                <Button
                  testId="family-form-confirm-button"
                  text="Save & Continue"
                  onClick={submitForm}
                />
              </ButtonWrapper>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default FamilyForm;
