import { PAGES, State } from "..";

export const paternalGrandmotherPage = (page: number) => {
  return (state: State): boolean => {
    const fatherState = state[PAGES.FATHER];
    const motherState = state[PAGES.MOTHER];

    return !state[page].has && !fatherState.answered && !motherState.answered;
  };
};
