import { Selector } from "@willsStore/utils/types";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
  WillsState,
} from "../constants/types";

export const familySelector: Selector<WillsState["will"]["family"]> = (state) =>
  state.resources.wills?.will?.family;

export const genericFilter =
  (familyMembers: FamilyMemberObject[]) =>
  (familyType: string): FamilyMemberObject[] =>
    familyMembers?.filter(
      (familyMember) => familyMember.familyMemberType === familyType
    ) || [];

export const wivesSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.Wife
  );

export const husbandSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.Husband
  );

export const fatherSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.Father
  );

export const motherSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.Mother
  );

export const sonsSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.Son
  );

export const daughtersSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.Daughter
  );

export const childrenSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) => {
  const sons = sonsSelector(state);
  const daughter = daughtersSelector(state);

  return sons.concat(daughter);
};

export const paternalGrandfathersSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.PaternalGrandfather
  );

export const maternalGrandmotherSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.MaternalGrandmother
  );

export const paternalGrandmotherSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.PaternalGrandmother
  );

export const grandSonsSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.Grandson
  );

export const grandDaughtersSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.Granddaughter
  );

export const grandchildrenSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) => {
  const grandsons = grandSonsSelector(state);
  const granddaughters = grandDaughtersSelector(state);

  return grandsons.concat(granddaughters);
};

export const siblingsSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) => {
  const brother = genericFilter(
    state.resources.wills?.will?.family?.familyMembers
  )(FamilyMemberTypes.Brother);

  const sister = genericFilter(
    state.resources.wills?.will?.family?.familyMembers
  )(FamilyMemberTypes.Sister);

  return brother.concat(sister);
};

export const maternalSiblingsSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) => {
  const maternalBrothers = genericFilter(
    state.resources.wills?.will?.family?.familyMembers
  )(FamilyMemberTypes.MaternalBrothers);

  const maternalSisters = genericFilter(
    state.resources.wills?.will?.family?.familyMembers
  )(FamilyMemberTypes.MaternalSisters);

  return maternalBrothers.concat(maternalSisters);
};

export const paternalBrothersSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.PaternalBrothers
  );

export const fullBrothersSonSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.FullBrothersSon
  );

export const paternalSistersSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.PaternalSisters
  );

export const paternalBrothersSonSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.PaternalBrothersSon
  );

export const fullPaternalUncleSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.FullPaternalUncle
  );

export const fathersPaternalBrothersSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.FathersPaternalBrothers
  );

export const fullPaternalUnclesSonsSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.FullPaternalUnclesSons
  );

export const fathersPaternalBrothersSonsSelector: Selector<
  WillsState["will"]["family"]["familyMembers"]
> = (state) =>
  genericFilter(state.resources.wills?.will?.family?.familyMembers)(
    FamilyMemberTypes.FathersPaternalBrothersSons
  );
