import React from "react";
import PreFetchData from "../PreFetchData/PreFetchData.components";

export type PrivateRouteProps = {
  path: string;
  component: React.FC;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  children,
  ...rest
}) => {
  return (
    <PreFetchData>
      <Component {...rest}>{children}</Component>
    </PreFetchData>
  );
};

export default PrivateRoute;
