import {
  ExecutorInfo,
  GuardianInfo,
} from "../../../../../components/Wills/constants/types";
import { FormData } from "./generalForm";

export const dataToForm = (
  data: ExecutorInfo | GuardianInfo | null
): FormData => ({
  id: data?.id,
  firstName: data?.firstName || "",
  lastName: data?.lastName || "",
  email: data?.email || "",
  addressLine1: data?.address?.addressLine1 || "",
  addressLine2: data?.address?.addressLine2 || "",
  city: data?.address?.city || "",
  postcode: data?.address?.postcode || "",
});

export const formToData = (form: FormData): ExecutorInfo | GuardianInfo => ({
  id: form.id,
  firstName: form.firstName,
  lastName: form.lastName,
  ...(form.email ? { email: form.email || "" } : {}),
  address: {
    addressLine1: form.addressLine1,
    addressLine2: form.addressLine2,
    city: form.city,
    postcode: form.postcode,
  },
});
