import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { departingMessageSelector } from "../../../../components/Wills/selectors";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import { ButtonsContainer, Text } from "../../signup/styled";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { onUpdateDepartingMessage } from "../../../../components/Wills/actions";
import { theme } from "../../../styles/theme";
import { updateDepartingMessageAPI } from "../../../../components/Wills/actions/departingMessage";

export const Layout = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const FormWrapper = styled.div`
  width: 327px;
  height: 80vh;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Description = styled.p`
  margin: 0 0 0.3rem 0;
  color: ${theme.colors.Font02};
`;

const Messages: React.FC = () => {
  const initialState = useSelector(departingMessageSelector);
  const [value, setValue] = useState<string>("");
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(onUpdateDepartingMessage(value));
    dispatch(updateDepartingMessageAPI(value));
    navigate("/app/wills");
  };

  useEffect(() => {
    setValue(initialState);
  }, [initialState]);

  return (
    <Layout>
      <FormWrapper>
        <div>
          <Text align="left">Leave a message to your loved ones</Text>
          <Description>
            Leave a message to your loved ones to be delivered after your
            passing.
          </Description>

          <Input
            heading={"Message (optional)"}
            isLong
            placeholder="Begin typing your message..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <ButtonsContainer>
          <FormButton
            onClick={onSubmit}
            text="Save & Continue"
            leftArrowOnClick={() => navigate(-1)}
            leftArrowLabel="Back"
          />
        </ButtonsContainer>
      </FormWrapper>
    </Layout>
  );
};

export default Messages;
