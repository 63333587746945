import React, { useContext, useState } from "react";
import {
  InnerWrapper,
  Layout,
  Text,
  Description,
  CardsContainer,
} from "../../styled";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { getAlternativeInvestments } from "../../selectors";
import { useMediaQuery } from "react-responsive";
import LockIcon from "@assets/icons/lock.svg";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import AddItemCard from "../../../../legacyComponents/AddItemCard/AddItemCard.component";
import AccountCard from "../../../../legacyComponents/AccountCard/AccountCard.component";
import { deleteAlternativeInvestmentStore } from "../../actions";
import Modal from "../../../../legacyComponents/Modal/Modal.component";
import AlternativeInvestmentsForm from "./AlternativeInvestmentsForm";
import { DetailedAssetInterface } from "@ifgengineering/hip-app-domain";
import { DetailedAssetObject } from "../../constants/types";
import { deleteAlternativeItemAPI } from "../../actions/alternativeInvestments";
import Tooltip from "@components/Tooltip";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

const emptyState: DetailedAssetInterface = {
  value: 0,
};

const AlternativeInvestments: React.FC<PageType> = ({
  nextPage,
  previousPage,
  showTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedInvestment, setSelectedInvestment] =
    useState<DetailedAssetInterface>(emptyState);

  const alternativeAssetList = useSelector(getAlternativeInvestments);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const deleteAPI = async (value: DetailedAssetObject) => {
    await dispatch(
      deleteAlternativeItemAPI(value.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deleteAlternativeInvestmentStore(value));
  };

  const onClose = () => {
    setOpen(false);
    setSelectedInvestment(emptyState);
  };

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Other investments</Text>
              <Description>
                If you have any other assets or investments that you did not
                add, you can add them here. E.g. SME Lending
              </Description>
              {showTooltip && <Tooltip text={tooltipText.other} />}
            </div>

            <CardsContainer>
              <AddItemCard
                text="Add other investment"
                onClick={() => {
                  setOpen(true);
                  setSelectedInvestment({
                    value: 0,
                  });
                }}
              />

              {alternativeAssetList.map((investment) => {
                const { value, description = "" } = investment;

                return (
                  <AccountCard
                    key={`${description}-${value}`}
                    name={description}
                    currency={currencySymbol}
                    balance={value}
                    onClickDelete={() => deleteAPI(investment)}
                    onClickEdit={() => {
                      setOpen(true);
                      setSelectedInvestment(investment);
                    }}
                  />
                );
              })}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        <AlternativeInvestmentsForm
          title="Add other investment"
          data={selectedInvestment}
          previousPage={previousPage}
          onSubmit={onClose}
        />
      ) : (
        <Modal onClose={onClose} open={open}>
          <AlternativeInvestmentsForm
            title="Add other investment"
            data={selectedInvestment}
            previousPage={previousPage}
            onSubmit={onClose}
          />
        </Modal>
      )}
    </>
  );
};

export default AlternativeInvestments;
