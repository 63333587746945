import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_OWED_ASSET_API,
  DELETE_OWED_ASSET_API,
  UPDATE_OWED_ASSET_API,
} from "../constants/actionTypes";
import { OwedAssetItemObject, OwedAssetObject } from "../constants/types";

export const createOwedAssetAPI = createAsyncThunk<
  OwedAssetObject,
  {
    email: string;
    data: OwedAssetItemObject[];
  }
>(CREATE_OWED_ASSET_API, async ({ email, data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: [CreateOwedAssetItemInput!]!, $email: String!) {
          createOwedAssets(data: $data, email: $email) {
            id
            totalValue
            owedAssetList {
              id
              nameOfItem
              nameOfEntityLentTo
              description
              value
            }
          }
        }
      `,
      variables: {
        email,
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createOwedAssets;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "createOwedAssetAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateOwedAssetItemAPI = createAsyncThunk<
  OwedAssetItemObject,
  OwedAssetItemObject
>(UPDATE_OWED_ASSET_API, async (data) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: UpdateOwedAssetItemInput!) {
          updateOwedAssets(data: $data) {
            id
            nameOfItem
            nameOfEntityLentTo
            description
            value
          }
        }
      `,
      variables: {
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.updateOwedAssets;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateOwedAssetItemAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteOwedAssetAPI = createAsyncThunk<string, number>(
  DELETE_OWED_ASSET_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deleteOwedAssetItem(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return [];
      }

      return response.data.deleteOwedAssetItem;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deleteOwedAssetAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
