import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { CryptoItemInterface } from "@ifgengineering/hip-app-domain";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_CRYPTO_API,
  DELETE_CRYPTO_API,
  UPDATE_CRYPTO_API,
} from "../constants/actionTypes";
import { CryptoItemObject, CryptoObject } from "../constants/types";

export const createCryptoAPI = createAsyncThunk<
  CryptoObject,
  {
    email: string;
    data: CryptoItemObject[];
  }
>(CREATE_CRYPTO_API, async ({ email, data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: [CreateCryptoItem!]!, $email: String!) {
          createCryptoItems(data: $data, email: $email) {
            id
            totalValue
            cryptoList {
              id
              coinName
              totalValue
              coinID
              numCoins
            }
          }
        }
      `,
      variables: {
        email,
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createCryptoItems;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "createCryptoAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateCryptoItemAPI = createAsyncThunk<
  CryptoItemObject,
  CryptoItemInterface
>(UPDATE_CRYPTO_API, async (data) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: UpdateCryptoItem!) {
          updateCryptoItem(data: $data) {
            id
            coinName
            totalValue
            coinID
            numCoins
          }
        }
      `,
      variables: {
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.updateCryptoItem;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateCryptoItemAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteCryptoAPI = createAsyncThunk<string, number>(
  DELETE_CRYPTO_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deleteCryptoItem(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return [];
      }

      return response.data.deleteCryptoItem;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deleteCryptoAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
