import { PAGES, State } from "..";

export const maternalSiblingsPage = (page: number) => {
  return (state: State): boolean => {
    const childrenPage = state[PAGES.CHILDREN];
    const grandchildrenPage = state[PAGES.GRAND_CHILDREN];

    const notAnswered = !state[page].has;
    const preConditions =
      grandchildrenPage.has &&
      !grandchildrenPage.answered &&
      childrenPage.has &&
      !childrenPage.answered;

    return notAnswered && preConditions;
  };
};
