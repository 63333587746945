import { navigate } from "gatsby";
import React from "react";
import styled from "styled-components";
import LockIcon from "@assets/icons/lock.svg";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { theme } from "../../../../styles/legacy-theme";
import { InnerWrapper, Layout, Text, Description } from "../../styled";

export const TextButton = styled.button`
  color: ${theme.colors.Primary01};
  font-size: 0.8em;
`;

const EnterAssetsDetailsQuestion: React.FC<{
  onClickContinue?: () => void;
  onClickBack?: () => void;
  destinationPage: string;
}> = ({ onClickBack, onClickContinue, destinationPage }) => {
  return (
    <Layout>
      <InnerWrapper>
        <Text>Would you like to enter specific details about your assets?</Text>
        <Description>
          <b>
            This section is completely optional but we highly recommend you
            complete it.
          </b>
          <br />
          <br />
          For a further 5 minutes of effort:
          <br />
          <br />
          - Your executors will know exactly what assets you have so none of
          them go missing.
          <br />
          <br />- We will automatically calculate your zakat for you every
          Ramadan <strong>saving you 30m every year</strong> going forward.
        </Description>
      </InnerWrapper>
      <InnerWrapper>
        <FormButton
          text="Continue"
          onClick={onClickContinue}
          leftArrowLabel="Back"
          disclaimerIcon={<LockIcon />}
          disclaimerText={
            <div>
              We take your{" "}
              <a
                href="https://www.islamicfinanceguru.com/security-policy"
                target="_blank"
                rel="noreferrer"
              >
                privacy seriously
              </a>
              . Our institutional-grade security ensures your data is
              confidential.
            </div>
          }
          leftArrowOnClick={onClickBack}
        />
        <br />
        <div style={{ width: "100%", textAlign: "center" }}>
          <TextButton
            onClick={() => {
              navigate(destinationPage);
            }}
          >
            Skip Details
          </TextButton>
        </div>
      </InnerWrapper>
    </Layout>
  );
};

export default EnterAssetsDetailsQuestion;
