import styled from "styled-components";
import { theme } from "../../../../../styles/legacy-theme";

type RadioLabelProps = {
  checked: boolean;
  name?: string;
};

const RadioLabel = styled.label<RadioLabelProps>`
  cursor: ${({ checked }) => (checked ? "default" : "pointer")};
  color: ${theme.colors.Font02};
  font-size: 1rem;
  line-height: 1rem;
  font-family: "Inter", sans-serif;
`;
export default RadioLabel;
