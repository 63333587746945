import React, { useContext } from "react";
import { Formik } from "formik";
import { useMediaQuery } from "react-responsive";
import LockIcon from "@assets/icons/lock.svg";
import { ButtonsContainer, InnerWrapper, Text } from "../../styled";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { PropertySchema } from "../../constants/validation";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import { PropertyItemInterface } from "@ifgengineering/hip-app-domain";
import { useDispatch } from "react-redux";
import { updatePropertiesStore, updatePropertyStore } from "../../actions";
import {
  createPropertyAPI,
  updatePropertyItemAPI,
} from "../../actions/property";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { CurrencyContext } from "@components/CurrencyContext";

type Props = {
  title: string;
  data: PropertyItemInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

const PropertyForm = ({
  title,
  data,
  previousPage,
  onSubmit,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const createAPI = async (values: PropertyItemInterface) => {
    const property = await dispatch(
      createPropertyAPI({
        email,
        data: [values],
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updatePropertiesStore(property));
  };

  const updateAPI = async (values: PropertyItemInterface) => {
    const debt = await dispatch(
      updatePropertyItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updatePropertyStore(debt));
  };

  return (
    <Formik
      validationSchema={PropertySchema}
      initialValues={data}
      onSubmit={(property) => {
        if (property.id) {
          updateAPI(property);
        } else {
          createAPI(property);
        }

        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        return (
          <FormikForm>
            <Text align="left">{title}</Text>

            <Input
              value={values.description?.toString() || ""}
              type="text"
              name="description"
              heading="Description"
              description="e.g. My rental property in East London"
              errorMessage={errors.description}
              onChange={(e) => setFieldValue("description", e.target.value)}
            />
            <Input
              value={values.postcode?.toString() || ""}
              type="text"
              name="postcode"
              heading="Address (Optional)"
              description="Enter the full address of your property"
              errorMessage={
                touched.postcode && errors.postcode ? errors.postcode : ""
              }
              onChange={(e) => setFieldValue("postcode", e.target.value)}
            />
            <MoneyInput
              heading="Estimated Value of Property"
              description="How much you estimate your property to be worth"
              value={values.value.toString() || ""}
              onChange={(value) => setFieldValue("value", Number(value))}
              currency={currencySymbol}
              errorMessage={touched.value && errors.value ? errors.value : ""}
            />

            <ButtonsContainer>
              <FormButton
                onClick={submitForm}
                type="submit"
                text="Save & Continue"
                disclaimerIcon={<LockIcon />}
                disclaimerText={
                  <div>
                    We take your{" "}
                    <a
                      href="https://www.islamicfinanceguru.com/security-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy seriously
                    </a>
                    . Our institutional-grade security ensures your data is
                    confidential.
                  </div>
                }
                leftArrowOnClick={isMobile ? previousPage : undefined}
                leftArrowLabel={isMobile ? "Back" : undefined}
              />
            </ButtonsContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default PropertyForm;
