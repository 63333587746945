import React, { useEffect, useState } from "react";
import sections, { SectionTemplateItem } from "./constants/sections";
import templates, {
  reviewSubmitTextCompletedNotPaid,
  reviewSubmitTextInProgress,
} from "./constants/templates";
import WillEnums from "./types/willEnums";
import SectionContainer from "../../components/SectionContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  willSelector,
  willStatusSelector,
} from "../../../components/Wills/selectors";
import { getPortfolioData } from "../../../components/PortfolioData/selectors";
import willsStatus, { WillsStatus } from "./helpers/willsStatus";
import sectionStatus, { StatusPerSection } from "./helpers/sectionStatus";
import setStatusToSections from "./helpers/setStatusToSections";
import { updateWillAPI } from "../../../components/Wills/actions/will";
import { isAuthenticatedSelector } from "../../../components/Login/selectors";
import { getCharity } from "@components/Charity/selectors";
import { updateAppRecordAPI } from "@components/Login/actions/updateAppRecordAPI";
import { AppRecordEnum } from "@components/Login/constants/types";

const Wills: React.FC = () => {
  const wills = useSelector(willSelector);
  const pd = useSelector(getPortfolioData);
  const statusFromAPI = useSelector(willStatusSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const sponsoredCharity = useSelector(getCharity);

  const [status, setStatus] = useState<WillsStatus>();
  const [sectionProgress, setSectionProgress] = useState<StatusPerSection>();
  const [sectionsWithStatuses, setSectionsWithStatuses] = useState<
    SectionTemplateItem[]
  >([]);
  const [localTemplate, setLocalTemplate] = useState(templates);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateAppRecordAPI(AppRecordEnum.wills));
  }, []);

  useEffect(() => {
    if (wills && pd) {
      setStatus(willsStatus(wills, pd));
      setSectionProgress(sectionStatus(wills, pd, sponsoredCharity?.id));
    }
  }, [wills, pd]);

  useEffect(() => {
    if (sectionProgress && sections) {
      setSectionsWithStatuses(setStatusToSections(sectionProgress, sections));
    }
  }, [sectionProgress, sections]);

  useEffect(() => {
    if (!statusFromAPI || !status) {
      return;
    }

    const statusWhiteList = [WillsStatus.NOT_STARTED, WillsStatus.IN_PROGRESS];

    if (
      statusWhiteList.includes(statusFromAPI as WillsStatus) &&
      isAuthenticated
    ) {
      dispatch(
        updateWillAPI({
          status,
        })
      );
    }
  }, [status, statusFromAPI]);

  // Hides the submit button from the Review and Submit page until
  // the status is No Yet Started or In Progress
  useEffect(() => {
    if (status) {
      const button =
        status === WillsStatus.COMPLETE_NOT_PAID
          ? templates[WillEnums.reviewSubmit].button
          : "";

      const description =
        status === WillsStatus.COMPLETE_NOT_PAID
          ? reviewSubmitTextCompletedNotPaid
          : reviewSubmitTextInProgress;

      setLocalTemplate({
        ...templates,
        [WillEnums.reviewSubmit]: {
          ...templates[WillEnums.reviewSubmit],
          description,
          button,
        },
      });
    }
  }, [status]);

  return (
    <SectionContainer
      sections={sectionsWithStatuses}
      templates={localTemplate}
      defaultPage={WillEnums.getStarted}
    />
  );
};

export default Wills;
