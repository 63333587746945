export const AppRecordEnum = {
  track: "track",
  compare: "compare",
  zakat: "zakat",
  wills: "wills",
};

export type AuthState = {
  isAuthenticated: boolean;
  loading: boolean;
  user: {
    id?: number | null;
    email?: string;
    appRecord: Record<keyof typeof AppRecordEnum, boolean>;
  };
  error?: Error | null;
  neverLoaded: boolean;
};

export type ActionPayload = {
  payload?: {
    id?: number | undefined;
    email: string;
    appRecord: Record<keyof typeof AppRecordEnum, boolean>;
  };
};

export type Selector<T> = (state: { auth: AuthState }) => T;
