import styled from "styled-components";
import React, { ReactText } from "react";
import { theme } from "../../../../styles/legacy-theme";

type Props = {
  name: string;
  entry?: {
    strokeDasharray?: ReactText;
    percent?: number;
    value?: string;
  };
  color?: string;
  onMouseEnter: () => void;
  onMouseOut: () => void;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  min-width: 5.625rem;
`;

const ColorTag = styled.div`
  background-color: ${({ color }) => color};
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  margin: 0.125rem 0.5rem;
`;

const Label = styled.div`
  font-size: 0.813rem;
  color: grey;
  font-family: "Inter", sans-serif;
`;

const Value = styled.div`
  font-size: 1.063rem;
  color: ${theme.colors.BrandBlue};
  font-family: "Inter", sans-serif;
  margin: 0.25rem 0;
`;

const LegendComponent: (props: Props) => JSX.Element = ({
  color,
  name,
  entry,
  onMouseEnter,
  onMouseOut,
}: Props) => (
  <Wrapper
    data-testid="legend-component"
    onMouseOver={onMouseEnter}
    onMouseLeave={onMouseOut}
  >
    <ColorTag color={color} />
    <div>
      <Label>{name}</Label>
      <Value data-testid="legend-percentage">{`${entry?.value}%`}</Value>
    </div>
  </Wrapper>
);

export default LegendComponent;
