import { PAGES, State } from "..";

export const paternalBrothersPage = (page: number) => {
  return (state: State): boolean => {
    const children = state[PAGES.CHILDREN];
    const grandChildren = state[PAGES.GRAND_CHILDREN];
    const siblings = state[PAGES.SIBLINGS];

    const notAnswered = !state[page].has;
    const preConditions =
      children.has && grandChildren.has && siblings.has && !siblings.hasMale;

    return notAnswered && preConditions;
  };
};
