import React, { useContext, useState } from "react";
import {
  InnerWrapper,
  Layout,
  Text,
  Description,
  CardsContainer,
} from "../../styled";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import DebtForm from "./DebtForm";
import { useDispatch, useSelector } from "react-redux";
import LockIcon from "@assets/icons/lock.svg";
import { getDebts } from "../../selectors";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { deleteDebtStore } from "../../actions";
import AddItemCard from "../../../../legacyComponents/AddItemCard/AddItemCard.component";
import Modal from "../../../../legacyComponents/Modal/Modal.component";
import AccountCard from "../../../../legacyComponents/AccountCard/AccountCard.component";
import { DebtLabels, DebtType } from "../../constants/debt";
import { DebtItemInterface } from "@ifgengineering/hip-app-domain";
import { deleteDebtAPI } from "../../actions/debt";
import Tooltip from "@components/Tooltip";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

const emptyState: DebtItemInterface = {
  type: DebtType.MORTGAGE,
  value: 0,
};

const Debts: React.FC<PageType> = ({ nextPage, previousPage, showTooltip }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedDebt, setSelectedDebt] =
    useState<DebtItemInterface>(emptyState);

  const debts = useSelector(getDebts);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const onClose = () => {
    setOpen(false);
    setSelectedDebt(emptyState);
  };

  const deleteAPI = async (debt: DebtItemInterface) => {
    await dispatch(
      deleteDebtAPI(debt.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deleteDebtStore(debt));
  };

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Debts</Text>
              <Description>
                This is any money that you owe. e.g. student loans.
              </Description>
              {showTooltip && <Tooltip text={tooltipText.debts} />}
            </div>

            <CardsContainer>
              <AddItemCard
                text="Add Debt"
                onClick={() => {
                  setOpen(true);
                  setSelectedDebt({
                    type: DebtType.MORTGAGE,
                    value: 0,
                  });
                }}
              />

              {debts.map((debt) => {
                const { type, value, description } = debt;

                return (
                  <AccountCard
                    key={`${type}-${description}`}
                    name={
                      type === DebtType.OTHER
                        ? `${DebtLabels[type]}: ${description}`
                        : DebtLabels[type]
                    }
                    currency={currencySymbol}
                    balance={value}
                    onClickDelete={() => deleteAPI(debt)}
                    onClickEdit={() => {
                      setOpen(true);
                      setSelectedDebt(debt);
                    }}
                  />
                );
              })}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        <DebtForm
          title="Add Debt"
          debt={selectedDebt}
          previousPage={previousPage}
          onSubmit={onClose}
        />
      ) : (
        <Modal onClose={onClose} open={open}>
          <DebtForm
            title="Add Debt"
            debt={selectedDebt}
            previousPage={previousPage}
            onSubmit={onClose}
          />
        </Modal>
      )}
    </>
  );
};

export default Debts;
