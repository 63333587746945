import {
  BequestIndividualInterface,
  BequestCharityInterface,
  BequestInterface,
  MarriageStatusType,
  FamilyRelationType,
} from "@ifgengineering/hip-app-domain";

export enum FamilyMemberTypes {
  Father = "Father",
  Mother = "Mother",
  Brother = "Brother",
  Sister = "Sister",
  Grandson = "Grandson",
  Granddaughter = "Granddaughter",
  PaternalGrandfather = "Paternal grandfather",
  PaternalGrandmother = "Paternal grandmother",
  MaternalGrandfather = "Maternal grandfather",
  MaternalGrandmother = "Maternal grandmother",
  PaternalBrothers = "Paternal Brothers",
  PaternalSisters = "Paternal Sisters",
  MaternalBrothers = "Maternal Brothers",
  MaternalSisters = "Maternal Sisters",
  FullBrothersSon = "Full Brothers Son",
  PaternalBrothersSon = "Paternal Brothers Son",
  FullPaternalUncle = "Full Paternal Uncle",
  FullPaternalUnclesSons = "Full Paternal Uncles Sons",
  FathersPaternalUncle = "Fathers Paternal Uncle",
  FathersPaternalBrothersSons = "Fathers Paternal Brothers Sons",
  FathersPaternalBrothers = "Fathers Paternal Brothers",
  Wife = "Wife",
  Husband = "Husband",
  Son = "Son",
  Daughter = "Daughter",
}

export type WillsState = {
  loading: boolean;
  bq_individual: BequestIndividualInterface;
  bq_charity: BequestCharityInterface;
  will: WillObject;
  willsList: {
    id: string;
    // other will data
  }[];
};

export type UserInfo = {
  firstName?: string;
  lastName?: string;
  gender?: string;
  addressLine1?: string;
  addressLine2?: string;
  dob?: string;
  city?: string;
  postcode?: string;
  mobileNumber?: string;
};

export type ExecutorInfo = {
  id?: number;
  firstName: string;
  lastName: string;
  email?: string;
  address: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    postcode?: string;
  };
};

export type GuardianInfo = {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  address: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    postcode: string;
  };
  familyMember?: {
    firstName: string;
    lastName: string;
    over18: boolean;
    email: string;
  };
};

export interface UserInfoInput {
  firstName?: string;
  lastName?: string;
  address?: AddressInput;
  email?: string;
  dob?: string;
  mobileNumber?: string;
  gender?: string;
  referral?: string;
}

interface AddressInput {
  id?: number;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  region?: string;
  country?: string;
  postcode?: string;
}

export interface ExecutionerObject {
  id?: number;
  firstName: string;
  lastName: string;
  email?: string;
  address: AddressInput;
}

export interface FamilyObject {
  id?: number;
  memberDisabled: boolean | null;
  memberReceivingTestedBenefits: boolean | null;
  familyMembers: FamilyMemberObject[];
}

export interface FamilyMemberObject {
  id?: number;
  firstName: string;
  lastName: string;
  over18?: boolean;
  percentageEstate?: number;
  marriageStatustype?: MarriageStatusType;
  email?: string;
  familyMemberType: FamilyRelationType;
}

export interface WillObject {
  userInfo: UserInfoObject;
  bequests: BequestInterface;
  overseer: OverSeerObject;
  family: FamilyObject;
  departingMessage: string;
  status: string;
  adminStatus: string;
}

export interface WillInput {
  userInfo?: UserInfoObject;
  bequests?: BequestInterface;
  overseer?: OverSeerObject;
  family?: FamilyObject;
  departingMessage?: string;
  status?: string;
  adminStatus?: string;
}

export interface UserInfoObject {
  firstName?: string;
  lastName?: string;
  address?: AddressObject;
  email?: string;
  mobileNumber?: string;
  gender?: string;
  referral?: string;
  dob?: string;
}

export interface AddressObject {
  id?: number;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  region?: string;
  country?: string;
  postcode?: string;
}

export interface OverSeerObject {
  executionerList: ExecutionerObject[];
  guardianList: GuardianObject[];
}

export interface ExecutionerObject {
  id?: number;
  firstName: string;
  lastName: string;
  email?: string;
  address: AddressInput;
}

export interface GuardianObject {
  id?: number;
  firstName: string;
  lastName: string;
  email?: string;
  address: AddressInput;
}
export interface FamilyCreateUpdateInput {
  id?: number;
  memberDisabled: boolean | null;
  memberReceivingTestedBenefits: boolean | null;
}
