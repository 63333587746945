import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_GUARDIAN_API,
  DELETE_GUARDIAN_API,
  UPDATE_GUARDIAN_API,
} from "../constants/actionTypes";
import { GuardianObject } from "../constants/types";

export const createGuardiansAPI = createAsyncThunk<
  GuardianObject[],
  {
    guardian: GuardianObject[];
    email: string;
  }
>(CREATE_GUARDIAN_API, async ({ guardian, email }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($guardian: [GuardianInput!]!, $email: String!) {
          createGuardians(data: $guardian, email: $email) {
            id
            firstName
            lastName
            email
            address {
              id
              addressLine1
              addressLine2
              city
              region
              country
              postcode
            }
          }
        }
      `,
      variables: {
        guardian,
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createGuardians;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "createGuardiansAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateGuardiansAPI = createAsyncThunk<
  GuardianObject,
  GuardianObject
>(UPDATE_GUARDIAN_API, async (guardian) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($guardian: GuardianEditInput!) {
          editGuardian(data: $guardian) {
            id
            firstName
            lastName
            email
            address {
              id
              addressLine1
              addressLine2
              city
              region
              country
              postcode
            }
          }
        }
      `,
      variables: {
        guardian,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.editGuardian;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateGuardiansAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteGuardianAPI = createAsyncThunk<void, number>(
  DELETE_GUARDIAN_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deleteGuardian(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deleteGuardianAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
