import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "../../../../../components/Wills/constants/types";
import { fathersPaternalBrothersSelector } from "../../../../../components/Wills/selectors/family";

type Props = {
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean) => void;
  onBack: () => void;
};

export const FatherPaternalBrothers = ({
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const fathersPaternalBrothers = useSelector(fathersPaternalBrothersSelector);

  useEffect(() => {
    if (fathersPaternalBrothers.length) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [fathersPaternalBrothers.length]);

  return (
    <>
      {showForm ? (
        <FamilyComposition
          title="Does your father have any paternal half brothers?"
          description="This is your half uncle, where your father shares the same father with his brother, but a different mother"
          buttonText="Add a Father's paternal half-brother"
          modalTitle="Father's paternal half-brother"
          data={fathersPaternalBrothers}
          onBack={onBack}
          onSave={(value) => {
            onChange({
              ...value,
              familyMemberType: FamilyMemberTypes.FathersPaternalBrothers,
            });
          }}
          onRemove={(id) => onDelete(id)}
          onFinish={() => onFinish(Boolean(fathersPaternalBrothers.length))}
        />
      ) : (
        <YesNoQuestions
          title="Does your father have any paternal half brothers?"
          description="This is your half uncle, where your father shares the same father with his brother, but a different mother"
          onChange={(value) => (value ? setShowForm(value) : onFinish(false))}
          onBack={onBack}
        />
      )}
    </>
  );
};
