import { SectionStatus } from "../helpers/sectionStatus";
import WillEnums from "../types/willEnums";

export type SectionTemplateItem = {
  sectionNumber: number;
  text: string;
  buttonText: string;
  estimatedTime: string;
  content: WillEnums;
  status: SectionStatus;
  isOptional: boolean;
};

export const sections: SectionTemplateItem[] = [
  {
    sectionNumber: 1,
    text: "Get Started",
    buttonText: "Start",
    estimatedTime: "0 mins",
    content: WillEnums.getStarted,
    status: SectionStatus.NOT_YET_STARTED,
    isOptional: false,
  },
  {
    sectionNumber: 2,
    text: "About Me",
    buttonText: "Start",
    estimatedTime: "1-2 mins",
    content: WillEnums.aboutMe,
    status: SectionStatus.NOT_YET_STARTED,
    isOptional: false,
  },
  {
    sectionNumber: 3,
    text: "My Family",
    buttonText: "Start",
    estimatedTime: "4-6 mins",
    content: WillEnums.myFamily,
    status: SectionStatus.NOT_YET_STARTED,
    isOptional: false,
  },
  {
    sectionNumber: 4,
    text: "Gifts & Bequests",
    buttonText: "Start",
    estimatedTime: "4-6 mins",
    content: WillEnums.giftsBequests,
    status: SectionStatus.NOT_YET_STARTED,
    isOptional: false,
  },
  {
    sectionNumber: 5,
    text: "My Assets",
    buttonText: "Start",
    estimatedTime: "4-6 mins",
    content: WillEnums.myAssets,
    status: SectionStatus.NOT_YET_STARTED,
    isOptional: false,
  },
  {
    sectionNumber: 6,
    text: "Executors & Guardians",
    buttonText: "Start",
    estimatedTime: "2-4 mins",
    content: WillEnums.executorsGuardians,
    status: SectionStatus.NOT_YET_STARTED,
    isOptional: false,
  },
  {
    sectionNumber: 7,
    text: "Messages",
    buttonText: "Start",
    estimatedTime: "1-2 mins",
    content: WillEnums.messages,
    status: SectionStatus.NOT_YET_STARTED,
    isOptional: true,
  },
  {
    sectionNumber: 8,
    text: "Review & Submit",
    buttonText: "Start",
    estimatedTime: "0 mins",
    content: WillEnums.reviewSubmit,
    status: SectionStatus.NOT_YET_STARTED,
    isOptional: false,
  },
];

export default sections;
