import { PAGES, State } from "../../../../components/Family/NavigationFlow";

const resetFlow = (state: State): State => {
  const newState: State = {};

  for (let i = 0; i <= PAGES.MEMBER_DISABLED; i++) {
    newState[i] = {
      ...state[i],
      has: false,
    };
  }

  return newState;
};

export default resetFlow;
