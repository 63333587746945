export enum WillEnums {
  getStarted = "getStarted",
  aboutMe = "about",
  myFamily = "family",
  myAssets = "assets",
  executorsGuardians = "executors",
  giftsBequests = "gifts",
  messages = "messages",
  reviewSubmit = "review",
}

export default WillEnums;
