import React from "react";
import styled from "styled-components";
import CardContainer from "../../../../legacyComponents/CardContainer/CardContainer.component";
import ProgressBar, {
  ProgressBarProps,
} from "../../ProgressBar/ProgressBar.component";
import { theme } from "../../../../styles/legacy-theme";
export interface ProgressButtonCardProps extends ProgressBarProps {
  text?: string;
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProgressBarWithTextContainer = styled.div`
  min-width: 75%;
`;

const Text = styled.p`
  padding: 0;
  margin: 0 0 5px 0;
  font-family: "Inter", sans-serif;
  color: ${theme.colors.Font02};
  font-size: 1.2rem;
  font-weight: normal;
`;

const ProgressPercentage = styled.p`
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  color: ${theme.colors.Primary03};
`;

export const ProgressBarCard: React.FC<ProgressButtonCardProps> = ({
  percentage,
  barColor,
  barHeight,
  text,
}) => {
  return (
    <CardContainer>
      <Container>
        <ProgressBarWithTextContainer>
          {text && <Text data-testid="progress-text">{text}</Text>}
          <ProgressBar
            barColor={barColor}
            barHeight={barHeight}
            percentage={percentage}
          />
        </ProgressBarWithTextContainer>
        <div>
          <ProgressPercentage data-testid="percentage-text">
            {percentage}%
          </ProgressPercentage>
        </div>
      </Container>
    </CardContainer>
  );
};

export default ProgressBarCard;
