import React, { useContext } from "react";
import LockIcon from "@assets/icons/lock.svg";
import { Formik } from "formik";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { AgricultureAssetsSchema } from "../../constants/validation";
import { ButtonsContainer, InnerWrapper, Text } from "../../styled";
import { AgricultureItemInterface } from "@ifgengineering/hip-app-domain";
import { useDispatch } from "react-redux";
import { AgricultureItemObject } from "../../constants/types";
import {
  createAgricultureAPI,
  updateAgricultureItemAPI,
} from "../../actions/agriculture";
import { updateAgriculturesStore, updateAgricultureStore } from "../../actions";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { CurrencyContext } from "@components/CurrencyContext";

type Props = {
  title: string;
  data: AgricultureItemInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

const AgricultureAssetsForm = ({
  title,
  data,
  previousPage,
  onSubmit,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const createAPI = async (values: AgricultureItemObject) => {
    const bankAccount = await dispatch(
      createAgricultureAPI({
        email,
        data: [values],
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updateAgriculturesStore(bankAccount));
  };

  const updateAPI = async (values: AgricultureItemObject) => {
    const bankAccount = await dispatch(
      updateAgricultureItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateAgricultureStore(bankAccount));
  };

  return (
    <Formik
      validationSchema={AgricultureAssetsSchema}
      initialValues={data}
      onSubmit={(agriculture) => {
        if (agriculture.id) {
          updateAPI(agriculture);
        } else {
          createAPI(agriculture);
        }

        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        return (
          <FormikForm>
            <Text align="left">{title}</Text>

            <Input
              value={values.description || ""}
              type="textarea"
              name="description"
              heading="Description"
              description="e.g. my 20 hectare farmland in South-East England"
              errorMessage={errors.description}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setFieldValue("description", e.target.value)}
            />

            <MoneyInput
              heading="Estimated total value"
              value={values.value.toString() || ""}
              onChange={(value) => setFieldValue("value", Number(value))}
              currency={currencySymbol}
              errorMessage={touched.value && errors.value ? errors.value : ""}
            />

            <ButtonsContainer>
              <FormButton
                onClick={submitForm}
                type="submit"
                text="Save & Continue"
                disclaimerIcon={<LockIcon />}
                disclaimerText={
                  <div>
                    We take your{" "}
                    <a
                      href="https://www.islamicfinanceguru.com/security-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy seriously
                    </a>
                    . Our institutional-grade security ensures your data is
                    confidential.
                  </div>
                }
                leftArrowOnClick={isMobile ? previousPage : undefined}
                leftArrowLabel={isMobile ? "Back" : undefined}
              />
            </ButtonsContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default AgricultureAssetsForm;
