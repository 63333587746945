import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { CREATE_CASH_API, UPDATE_CASH_API } from "../constants/actionTypes";
import { CashObject } from "../constants/types";

export const createCashAPI = createAsyncThunk<
  CashObject,
  {
    email: string;
    data: CashObject;
  }
>(CREATE_CASH_API, async ({ email, data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: CreateCashInputInput!, $email: String!) {
          createCashAsset(data: $data, email: $email) {
            id
            cashInHand
            cashISA
            totalCashValue
          }
        }
      `,
      variables: {
        email,
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createCashAsset;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "createCashAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateCashAPI = createAsyncThunk<CashObject, CashObject>(
  UPDATE_CASH_API,
  async (data) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($data: UpdateCashInputInput!) {
            updateCashAsset(data: $data) {
              id
              cashInHand
              cashISA
              totalCashValue
            }
          }
        `,
        variables: {
          data,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return [];
      }

      return response.data.updateCashAsset;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "updateCashAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
