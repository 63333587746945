import axios from "axios";
import { CORE_API } from "gatsby-env-variables";
import { logException } from "./logException";

type crmRequest = {
  payload: {
    [key: string]: string;
  };
};

export const updateCrm = (request: crmRequest) => {
  axios
    .post(
      `${CORE_API}/crm/update`,
      {
        ...request,
      },
      {
        withCredentials: true,
      }
    )
    .catch((e) => {
      logException({
        tag: "updateCrm",
        exception: e,
      });
    });
};
