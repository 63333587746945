import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_DEBT_API,
  DELETE_DEBT_API,
  UPDATE_DEBT_API,
} from "../constants/actionTypes";
import { DebtItemObject, DebtObject } from "../constants/types";

export const createDebtAPI = createAsyncThunk<
  DebtObject,
  {
    email: string;
    data: DebtItemObject[];
  }
>(CREATE_DEBT_API, async ({ email, data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: [CreateDebtItemInput!]!, $email: String!) {
          createDebt(data: $data, email: $email) {
            id
            totalValue
            debt {
              id
              type
              value
              description
            }
          }
        }
      `,
      variables: {
        email,
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createDebt;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "createDebtAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateDebtItemAPI = createAsyncThunk<
  DebtItemObject,
  DebtItemObject
>(UPDATE_DEBT_API, async (data) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: UpdateDebtItemInput!) {
          updateDebtItem(data: $data) {
            id
            type
            value
            description
          }
        }
      `,
      variables: {
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.updateDebtItem;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateDebtItemAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteDebtAPI = createAsyncThunk<string, number>(
  DELETE_DEBT_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deleteDebtItem(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return [];
      }

      return response.data.deleteDebtItem;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deleteDebtAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
