import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "../../../../../components/Wills/constants/types";
import { fatherSelector } from "../../../../../components/Wills/selectors/family";

type Props = {
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean) => void;
  onBack: () => void;
};

export const Father = ({
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const father = useSelector(fatherSelector);

  useEffect(() => {
    if (father.length) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [father.length]);

  return (
    <>
      {showForm ? (
        <FamilyComposition
          title="Is your father still alive?"
          buttonText="Add your father's info"
          modalTitle="About your father"
          limit={1}
          data={father}
          onBack={onBack}
          onSave={(value) => {
            onChange({
              ...value,
              familyMemberType: FamilyMemberTypes.Father,
            });
          }}
          onRemove={(id) => onDelete(id)}
          onFinish={() => onFinish(Boolean(father.length))}
        />
      ) : (
        <YesNoQuestions
          title="Is your father still alive?"
          description="Non-Muslim family members are not eligible for Islamic Inheritance and so shouldn’t be added here. You can still leave them a share of inheritance in the Bequests section."
          onChange={(value) => (value ? setShowForm(value) : onFinish(false))}
          onBack={onBack}
        />
      )}
    </>
  );
};
