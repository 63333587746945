import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { navigate } from "gatsby";
import { MEDIA_QUERIES } from "../../../components/mediaQueries";

import SectionCard from "../Cards/SectionCard/SectionCard.component";
import InfoComponent from "./components/infoComponent";
import WillEnums from "../../views/wills/types/willEnums";
import DashboardEnums from "../../views/dashboard/types/dashboardEnums";
import WillTemplates from "../../views/wills/constants/templates";
import DashboardTemplates from "../../views/dashboard/constants/templates";
import { SectionTemplateItem } from "../../views/wills/constants/sections";
import { willIsCompletePaidSelector } from "../../../components/Wills/selectors";

const LeftPanelContainer = styled.div`
  flex: 1;
  max-width: 400px;
  min-width: 200px;
  @media (max-width: 600px) {
    width: 100%;
    min-width: 0;
  }
`;

const RightPanelContainer = styled.div`
  flex: 3;
  margin-left: 2rem;
  @media (max-width: 600px) {
    width: 100%;
    margin: 0;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 1rem;
`;

type SectionType = {
  sections: SectionTemplateItem[];
  templates: typeof WillTemplates | typeof DashboardTemplates;
  defaultPage: DashboardEnums | WillEnums;
};

export const SectionContainer: React.FC<SectionType> = ({
  sections,
  templates,
  defaultPage,
}) => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const willComplete = useSelector(willIsCompletePaidSelector);
  const [selectedSection, setSelectedSection] = useState<
    null | WillEnums | DashboardEnums
  >(null);

  useEffect(() => {
    willComplete && navigate("/app/summary");
  }, [willComplete]);

  return (
    <>
      {(!isMobile || !selectedSection) && (
        <LeftPanelContainer>
          {sections.map((section, i) => (
            <SectionWrapper key={i}>
              <SectionCard
                selected={section.content === selectedSection}
                onClick={() => setSelectedSection(section.content)}
                {...section}
              />
            </SectionWrapper>
          ))}
        </LeftPanelContainer>
      )}

      {(!isMobile || selectedSection) && (
        <RightPanelContainer>
          <InfoComponent
            defaultPage={defaultPage}
            templates={templates}
            selectedSection={selectedSection}
            buttonAction={(item: WillEnums | DashboardEnums | null) =>
              setSelectedSection(item)
            }
          />
        </RightPanelContainer>
      )}
    </>
  );
};

export default SectionContainer;
