import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { CREATE_FAMILY_MEMBER_API } from "../constants/actionTypes";
import { FamilyMemberObject } from "../constants/types";

export const createFamilyMembersAPI = createAsyncThunk<
  FamilyMemberObject,
  {
    familyMember: FamilyMemberObject;
    email: string;
  }
>(CREATE_FAMILY_MEMBER_API, async ({ familyMember, email }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($familyMember: FamilyMemberCreateInput!, $email: String!) {
          createFamilyMember(data: $familyMember, email: $email) {
            id
            firstName
            lastName
            over18
            percentageEstate
            marriageStatustype
            email
            familyMemberType
          }
        }
      `,
      variables: {
        familyMember,
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.createFamilyMember;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "createFamilyMembersAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateFamilyMembersAPI = createAsyncThunk<
  FamilyMemberObject,
  FamilyMemberObject
>(CREATE_FAMILY_MEMBER_API, async (familyMember) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($familyMember: FamilyMemberUpdateInput!) {
          updateFamilyMember(data: $familyMember) {
            id
            firstName
            lastName
            over18
            percentageEstate
            marriageStatustype
            email
            familyMemberType
          }
        }
      `,
      variables: {
        familyMember,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.updateFamilyMember;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateFamilyMembersAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteFamilyMembersAPI = createAsyncThunk<string, string>(
  CREATE_FAMILY_MEMBER_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deleteFamilyMember(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }

      return response.data.message;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deleteFamilyMembersAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
