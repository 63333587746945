import React, { useState } from "react";
import styled from "styled-components";
import { Description, Text } from "../../../signup/styled";
import GeneralForm from "../forms/generalForm";
import AddItemCard from "../../../../../legacyComponents/AddItemCard/AddItemCard.component";
import StyledModal from "../../../../../legacyComponents/Modal/Modal.component";
import FormButton from "../../../../../legacyComponents/FormButton/FormButton.component";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../../../components/mediaQueries";
import { useDispatch, useSelector } from "react-redux";
import { executorListSelector } from "../../../../../components/Wills/selectors";
import FamilyCard from "../../../../components/Cards/FamilyCard/FamilyCard.component";
import { ExecutorInfo } from "../../../../../components/Wills/constants/types";
import {
  deleteExecutorStore,
  updateExecutorsStore,
  updateExecutorStore,
} from "../../../../../components/Wills/actions";
import { theme } from "../../../../styles/theme";
import { ExecutorGuardianPages } from "../index";
import {
  createExecutorsAPI,
  deleteExecutorAPI,
  updateExecutorsAPI,
} from "../../../../../components/Wills/actions/executors";
import store from "../../../../store";
import { getUserEmail } from "../../../../../components/Login/selectors";

const Executors: React.FC<{
  setPage: (page: ExecutorGuardianPages) => void;
}> = ({ setPage }) => {
  const [addMode, setAddMode] = useState<boolean>(false);
  const [selectedExec, setSelectedExec] = useState<ExecutorInfo | null>(null);
  const userEmail = useSelector(getUserEmail);
  const executorList = useSelector(executorListSelector);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch<typeof store.dispatch>();
  const closeModal = () => {
    setAddMode(false);
    setSelectedExec(null);
  };

  const handleAddExecutor = async (executor: ExecutorInfo) => {
    const executors = await dispatch(
      createExecutorsAPI({
        email: userEmail,
        executors: [executor],
      })
    ).unwrap();
    dispatch(updateExecutorsStore(executors));
  };

  const handleRemoveExecutor = (executor: ExecutorInfo) => {
    dispatch(deleteExecutorAPI(executor.id as number));
    dispatch(deleteExecutorStore(executor.id as number));
  };

  const handleEditExecutor = async (executor: ExecutorInfo) => {
    const result = await dispatch(updateExecutorsAPI(executor)).unwrap();
    dispatch(updateExecutorStore(result));
  };

  const renderExecutors = () =>
    executorList.map((exec, key) => (
      <FamilyCard
        key={key}
        name={`${exec.firstName} ${exec.lastName}`}
        familyRelationship={`Executor ${key + 1}`}
        enableDelete
        onRemove={() => {
          handleRemoveExecutor(exec);
        }}
        onClick={() => {
          setSelectedExec(exec);
          setAddMode(true);
        }}
      />
    ));

  return (
    <>
      {!addMode && (
        <FormWrapper>
          <div>
            <Text align="center">Executors</Text>
            <Description>
              These are people you appoint to sort out your Will after you pass
              away and who will look after your estate until everything has been
              distributed. We highly recommend you appoint two executors.
            </Description>
            <CardsContainer>
              <AddItemCard
                text="Add executor"
                onClick={() => setAddMode(true)}
              />
              {renderExecutors()}
              {!executorList.length && (
                <WarningText>Minimum of 1 executors</WarningText>
              )}
              {executorList.length > 3 && (
                <WarningText>Maximum of 3 executors</WarningText>
              )}
            </CardsContainer>
          </div>
          <FormButton
            active={executorList.length > 0 && executorList.length <= 3}
            onClick={() => setPage(ExecutorGuardianPages.guardians)}
            text="Save & Continue"
          />
        </FormWrapper>
      )}
      {isMobile && addMode ? (
        <GeneralForm
          setAddMode={closeModal}
          selected={selectedExec}
          addCallback={handleAddExecutor}
          editCallback={handleEditExecutor}
          searchDescription="If you don't know the address of your executor, don't worry. Just leave this blank for now and you can always email us after submission."
        />
      ) : (
        <StyledModal open={addMode} onClose={closeModal}>
          <GeneralForm
            setAddMode={closeModal}
            selected={selectedExec}
            addCallback={handleAddExecutor}
            editCallback={handleEditExecutor}
            searchDescription="If you don't know the address of your executor, don't worry. Just leave this blank for now and you can always email us after submission."
          />
        </StyledModal>
      )}
    </>
  );
};

export default Executors;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80vh;
`;

const CardsContainer = styled.div`
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

const WarningText = styled.p`
  color: ${theme.colors.ErrorRed};
`;
