import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "../../../../../components/Wills/constants/types";
import {
  childrenSelector,
  daughtersSelector,
  sonsSelector,
} from "../../../../../components/Wills/selectors/family";

type Props = {
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean, hasMale?: boolean, hasFemale?: boolean) => void;
  onBack: () => void;
};

export const Children = ({
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const children = useSelector(childrenSelector);
  const sons = useSelector(sonsSelector);
  const daughters = useSelector(daughtersSelector);

  useEffect(() => {
    if (children.length) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [children.length]);

  return (
    <>
      {showForm ? (
        <FamilyComposition
          title="Do you have any biological children?"
          description="Non-Muslim children, adopted children and step-children are not eligible for Islamic Inheritence and so shouldn’t be added here. You can still leave them a share of inheritance in the Bequests section."
          buttonText="Add a child"
          modalTitle="About your children"
          formConfig={{
            askChildAge: true,
            askGender: true,
          }}
          dropDownOptions={[
            {
              label: "Male",
              value: FamilyMemberTypes.Son,
            },
            {
              label: "Female",
              value: FamilyMemberTypes.Daughter,
            },
          ]}
          data={children}
          onBack={onBack}
          onSave={(value) => onChange(value)}
          onRemove={(id) => onDelete(id)}
          onFinish={() =>
            onFinish(
              Boolean(children.length),
              Boolean(sons.length),
              Boolean(daughters.length)
            )
          }
        />
      ) : (
        <YesNoQuestions
          title="Do you have any biological children?"
          description="Non-Muslim children, adopted children and step-children are not eligible for Islamic Inheritence and so shouldn’t be added here. You can still leave them a share of inheritance in the Bequests section."
          onChange={(value) =>
            value
              ? setShowForm(value)
              : onFinish(false, Boolean(sons.length), Boolean(daughters.length))
          }
          onBack={onBack}
        />
      )}
    </>
  );
};
