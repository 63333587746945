import React, { useContext } from "react";
import { Formik } from "formik";
import LockIcon from "@assets/icons/lock.svg";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { ButtonsContainer, InnerWrapper, Text } from "../../styled";
import { StartupItemInterface } from "@ifgengineering/hip-app-domain";
import { StartupSchema } from "../../constants/validation";
import { useDispatch } from "react-redux";
import { StartupItemObject } from "../../constants/types";
import { createStartupAPI, updateStartupItemAPI } from "../../actions/startup";
import { updateStartupsStore, updateStartupStore } from "../../actions";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { CurrencyContext } from "@components/CurrencyContext";

type Props = {
  title: string;
  data: StartupItemInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

const StartupForm = ({
  title,
  data,
  previousPage,
  onSubmit,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const createAPI = async (values: StartupItemObject) => {
    const bankAccount = await dispatch(
      createStartupAPI({
        email,
        data: [values],
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updateStartupsStore(bankAccount));
  };

  const updateAPI = async (values: StartupItemObject) => {
    const startup = await dispatch(
      updateStartupItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateStartupStore(startup));
  };

  return (
    <Formik
      validationSchema={StartupSchema}
      initialValues={data}
      onSubmit={(startup) => {
        if (startup.id) {
          updateAPI(startup);
        } else {
          createAPI(startup);
        }

        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        return (
          <FormikForm>
            <Text align="left">{title}</Text>

            <Input
              value={values.companyName?.toString() || ""}
              type="text"
              name="companyName"
              heading="Company Name"
              description="e.g. Islamicfinanceguru Limited"
              errorMessage={errors.companyName}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setFieldValue("companyName", e.target.value)}
            />

            <MoneyInput
              heading="Total Value of startup investment"
              description="The estimated value of your startup investment"
              value={values.value.toString() || ""}
              onChange={(value) => setFieldValue("value", Number(value))}
              currency={currencySymbol}
              errorMessage={touched.value && errors.value ? errors.value : ""}
            />

            <ButtonsContainer>
              <FormButton
                onClick={submitForm}
                type="submit"
                text="Save & Continue"
                disclaimerIcon={<LockIcon />}
                disclaimerText={
                  <div>
                    We take your{" "}
                    <a
                      href="https://www.islamicfinanceguru.com/security-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy seriously
                    </a>
                    . Our institutional-grade security ensures your data is
                    confidential.
                  </div>
                }
                leftArrowOnClick={isMobile ? previousPage : undefined}
                leftArrowLabel={isMobile ? "Back" : undefined}
              />
            </ButtonsContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default StartupForm;
