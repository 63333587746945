import { InheritanceShares } from ".";
import { FamilyObject } from "../../../components/Wills/constants/types";

export const inheritanceUserFriendlyPrint = (shares: InheritanceShares) => {
  return Object.keys(shares)
    .map((share: string) => ({
      [share]: (shares[share].valueOf() * 100).toFixed(2),
    }))
    .map((share) => Object.entries(share).flat());
};

export const inheritanceAPIFriendly = (shares: InheritanceShares) => {
  return Object.keys(shares)
    .map((share: string) => ({
      [share]: Number.parseFloat(shares[share].valueOf().toFixed(4)),
    }))
    .map((share) => Object.entries(share).flat());
};

export const addSharesToFamilyMemebers = (
  family: FamilyObject,
  shares: any[][]
) => {
  const { familyMembers } = family;

  return {
    ...family,
    familyMembers: familyMembers.map((familyMember) => {
      const share = shares.find(
        (share) => Number.parseInt(share[0]) === familyMember.id
      );

      return {
        ...familyMember,
        percentageEstate:
          share !== undefined ? share[1] : familyMember.percentageEstate,
      };
    }),
  };
};
