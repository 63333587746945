import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WILLS_SITE_URL } from "gatsby-env-variables";
import { fetchWillAPI } from "@components/Wills/actions/will";
import { updateWills } from "@components/Wills/actions";
import { fetchPortfolioAPI } from "@components/PortfolioData/actions/portfolio";
import { updatePortfolio } from "@components/PortfolioData/actions";
import store from "../../store";
import {
  fetchUserInfoAPI,
  logoutAction,
  updateUserInfo,
} from "../../../components/Login/actions";
import { navigate } from "@reach/router";
import { redirectUser } from "../../lib/redirectUser";
import {
  fetchCharityByIdAPI,
  fetchCharityByKeyAPI,
  fetchPartnerCharityDonation,
  updateCharityStore,
} from "@components/Charity/actions";
import {
  getUserEmail,
  neverLoadedAuthSelector,
} from "@components/Login/selectors";

const PreFetchData: React.FC = ({ children }) => {
  const host = location.host;
  const [charityKey] = host.split(".");

  const dispatch = useDispatch<typeof store.dispatch>();
  const redirect = redirectUser(navigate, WILLS_SITE_URL);
  const neverLoadedAuth = useSelector(neverLoadedAuthSelector);
  const userEmail = useSelector(getUserEmail);

  async function portfolio(userEmail: string) {
    const { data, errors } = await dispatch(
      fetchPortfolioAPI(userEmail)
    ).unwrap();

    if (data) {
      dispatch(updatePortfolio(data));
      return data;
    }

    if (errors?.some(({ statusCode }) => statusCode === 401)) {
      dispatch(logoutAction());
    }
  }

  async function wills(email: string, partnerCharityId?: string) {
    const { data, errors } = await dispatch(
      fetchWillAPI({
        email,
        partnerCharityId,
      })
    ).unwrap();

    if (data) {
      dispatch(updateWills(data));
      return data;
    }

    if (errors?.some(({ statusCode }) => statusCode === 401)) {
      dispatch(logoutAction());
    }
  }

  async function userInfo() {
    const { data, errors } = await dispatch(fetchUserInfoAPI()).unwrap();

    if (data) {
      dispatch(updateUserInfo(data));
      return data;
    }

    if (errors?.some(({ statusCode }) => statusCode === 401)) {
      dispatch(logoutAction());
    }
  }

  async function donation(email: string) {
    await dispatch(fetchPartnerCharityDonation(email));
  }

  async function fetchCharity() {
    const charity = await dispatch(fetchCharityByKeyAPI(charityKey)).unwrap();

    dispatch(
      updateCharityStore({
        ...charity,
      })
    );

    return charity;
  }

  async function getCharityKey(charityId: string) {
    const charity = await dispatch(fetchCharityByIdAPI(charityId)).unwrap();
    if (charity) {
      return charity.key;
    }
  }

  async function loadData() {
    let willCharityKey;

    if (neverLoadedAuth) {
      await userInfo();
    }

    const charity = await fetchCharity();

    if (!userEmail) {
      return;
    }

    donation(userEmail);
    portfolio(userEmail);

    const will = await wills(userEmail, charity?.id);

    if (!will) {
      return;
    }

    if (will.partnerCharityId) {
      willCharityKey = await getCharityKey(will.partnerCharityId);
    }

    redirect(
      will.status,
      charity?.id || "",
      will.partnerCharityId,
      willCharityKey
    );
  }

  useEffect(() => {
    loadData();
  }, [neverLoadedAuth]);

  return <>{children}</>;
};

export default PreFetchData;
