import { DecisionFlow, State, DecisionCallback } from ".";
import { PAGES } from "./contstants";
import {
  grandchildrenPage,
  maternalSiblingsPage,
  paternalBrothersPage,
  paternalGrandmotherPage,
  paternalSistersPage,
  siblingsPage,
  genericDecision,
} from "./pageDecisions";

const decisionFlowItem = (
  name: number,
  previous: number | null,
  callback: DecisionCallback
) => ({
  name,
  decide: callback(name, previous),
});

const decisionFlow: DecisionFlow = [
  // Level 1
  decisionFlowItem(PAGES.SPOUSE, null, genericDecision),
  decisionFlowItem(PAGES.FATHER, null, genericDecision),
  decisionFlowItem(PAGES.MOTHER, null, genericDecision),
  decisionFlowItem(PAGES.CHILDREN, null, genericDecision),
  // Level 2
  decisionFlowItem(PAGES.PATERNAL_GRANDFATHER, PAGES.FATHER, genericDecision),
  decisionFlowItem(PAGES.PATERNAL_GRANDMOTHER, null, paternalGrandmotherPage),
  decisionFlowItem(PAGES.MATERNAL_GRANDMOTHER, PAGES.MOTHER, genericDecision),
  decisionFlowItem(PAGES.GRAND_CHILDREN, null, grandchildrenPage),
  // Level 3
  decisionFlowItem(PAGES.SIBLINGS, null, siblingsPage),
  decisionFlowItem(PAGES.MATERNAL_SIBLINGS, null, maternalSiblingsPage),
  // Level 4
  decisionFlowItem(PAGES.PATERNAL_BROTHERS, null, paternalBrothersPage),
  decisionFlowItem(PAGES.PATERNAL_SISTERS, null, paternalSistersPage),
  // Level 5 - 10
  decisionFlowItem(
    PAGES.FULL_BROTHERS_SON,
    PAGES.PATERNAL_BROTHERS,
    genericDecision
  ),
  decisionFlowItem(
    PAGES.PATERNAL_BROTHERS_SON,
    PAGES.FULL_BROTHERS_SON,
    genericDecision
  ),
  decisionFlowItem(
    PAGES.FULL_UNCLES,
    PAGES.PATERNAL_BROTHERS_SON,
    genericDecision
  ),
  decisionFlowItem(
    PAGES.FATHER_PATERNAL_BROTHERS,
    PAGES.FULL_UNCLES,
    genericDecision
  ),
  decisionFlowItem(
    PAGES.FULL_PATERNAL_UNCLES_SON,
    PAGES.FATHER_PATERNAL_BROTHERS,
    genericDecision
  ),
  decisionFlowItem(
    PAGES.FATHER_PATERNAL_BROTHERS_SON,
    PAGES.FULL_PATERNAL_UNCLES_SON,
    genericDecision
  ),
];

const navigateFlow = (
  position: number,
  decisionFlow: DecisionFlow,
  state: State
): number | null => {
  const page = decisionFlow[position];

  if (page === undefined) {
    return null;
  }

  if (page.decide(state)) {
    return page.name;
  }

  const nextPosition = position + 1;

  return navigateFlow(nextPosition, decisionFlow, state);
};

export const nextPage = (state: State): number | null => {
  return navigateFlow(0, decisionFlow, state);
};

export const previousPage = (state: State): State => {
  // It creates a list of all answered pages until this point
  const flow = decisionFlow
    .map((page) => (state[page.name].has ? page.name : null))
    .filter((items) => items !== null);

  // The previous page is always the last item of the array
  const previousPage = flow[flow.length - 1];

  if (previousPage === undefined || previousPage === null) {
    return state;
  }

  // Returning to a page is just saying that page has not been answered
  return {
    ...state,
    [previousPage]: {
      ...state[previousPage],
      has: false,
    },
  };
};
