import styled from "styled-components";
import { theme } from "../../../../styles/legacy-theme";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 14px 0 10px 0;
  border-bottom: 2px solid ${theme.colors.Grey01};
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const WidthWrapper = styled.div`
  max-width: 850px;
`;

export const ContentWrapperMobile = styled.div`
  display: flex;
  padding: 0 8px;
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 20px;
`;

export const Question = styled.div`
  font-size: 16px;
  margin-right: 6rem;
  width: 250px;
  min-width: 250px;
`;

export const NestedLabel = styled.div`
  font-size: 14px;
  margin-right: 12px;
  width: 140px;
  text-align: left;
`;

export const NestedField = styled.div`
  font-size: 16px;
  margin-right: 12px;
  text-align: left;
`;

export const NestedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const NestedSpacer = styled.div`
  margin-bottom: 30px;
`;

export const Answer = styled.div`
  font-size: 16px;
`;

export const Heading = styled.h4`
  font-size: 19px;
  font-weight: bold;
  display: flex;
  align-items: baseline;
`;

export const Title = styled.h4`
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: ${theme.colors.Primary03};
`;

export const Text = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  color: ${theme.colors.Primary03};
`;

export const Link = styled.p`
  font-size: 14px;
  margin-left: 8px;
  color: ${theme.colors.Primary01};
`;

export const HyperLink = styled.a`
  font-weight: bold;
  color: ${theme.colors.Primary01};
`;

export const ButtonWrapper = styled.div`
  width: 327px;
  margin-bottom: 30px;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
