import { Field, Formik } from "formik";
import { Schema } from "../constants/validation";
import { ButtonsContainer, Spacer } from "../../../signup/styled";
import Input from "../../../../../legacyComponents/Form/input/Input/Input.component";
import AddressFields from "../../../../components/AddressFields";
import { Separator } from "../../aboutYou/pages/contact";
import FormButton from "../../../../../legacyComponents/FormButton/FormButton.component";
import React from "react";
import {
  ExecutorInfo,
  GuardianObject,
} from "../../../../../components/Wills/constants/types";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../../../components/mediaQueries";
import styled from "styled-components";
import { theme } from "../../../../styles/theme";
import { dataToForm, formToData } from "./helpers";
import { FormikForm } from "../../../../../legacyComponents/Form/input/styled/Form";

type FieldType = {
  field: {
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
};

export interface FormData {
  id?: number;
  firstName: string;
  lastName: string;
  email?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postcode: string;
}

type GeneralFormProps = {
  selected: ExecutorInfo | GuardianObject | null;
  searchDescription?: string;
  setAddMode: () => void;
  addCallback: (data: ExecutorInfo | GuardianObject) => void;
  editCallback: (data: ExecutorInfo | GuardianObject) => void;
};

const GeneralForm: React.FC<GeneralFormProps> = ({
  setAddMode,
  selected,
  searchDescription,
  addCallback,
  editCallback,
}) => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const initialValues: FormData = dataToForm(selected);
  const isEditing = selected !== null;

  return (
    <Formik
      validationSchema={Schema}
      initialValues={initialValues}
      onSubmit={(values) => {
        const reshapedData = formToData(values);
        const callback = isEditing ? editCallback : addCallback;

        callback(reshapedData);
        setAddMode();
      }}
    >
      {({ errors, touched }) => (
        <FormikForm>
          <Field name="firstName">
            {({ field }: FieldType) => (
              <Spacer>
                <Input
                  heading="First name"
                  errorMessage={
                    touched.firstName ? errors.firstName : undefined
                  }
                  placeholder="First name"
                  {...field}
                />
              </Spacer>
            )}
          </Field>
          <Field name="lastName">
            {({ field }: FieldType) => (
              <Spacer>
                <Input
                  heading="Last name"
                  errorMessage={touched.lastName ? errors.lastName : undefined}
                  placeholder="Last name"
                  {...field}
                />
              </Spacer>
            )}
          </Field>
          <AddressFields
            isOpen={Boolean(initialValues.addressLine1)}
            isOptional
            searchDescription={searchDescription}
          />
          <Separator />
          <Field name="email">
            {({ field }: FieldType) => (
              <Spacer>
                <Input
                  heading="Email address (optional)"
                  errorMessage={touched.email ? errors.email : undefined}
                  placeholder="Email"
                  {...field}
                />
              </Spacer>
            )}
          </Field>
          <WarningText>*This person must be over the age of 18</WarningText>
          <ButtonsContainer>
            <FormButton
              type="submit"
              text="Save & Continue"
              leftArrowOnClick={isMobile ? () => setAddMode() : undefined}
              leftArrowLabel={isMobile ? "Back" : undefined}
            />
          </ButtonsContainer>
        </FormikForm>
      )}
    </Formik>
  );
};

export default GeneralForm;

const WarningText = styled.p`
  color: ${theme.colors.Font02};
`;
