import React from "react";
import sections from "./constants/sections";
import templates from "./constants/templates";
import DashboardEnums from "./types/dashboardEnums";
import SectionContainer from "../../components/SectionContainer";

const Dashboard: React.FC = () => {
  return (
    <SectionContainer
      sections={sections}
      templates={templates}
      defaultPage={DashboardEnums.noWill}
    />
  );
};

export default Dashboard;
