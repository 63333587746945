import { Field, Formik } from "formik";
import { BankAccountItemInterface } from "@ifgengineering/hip-app-domain";
import { ButtonsContainer, Spacer, Text } from "../../styled";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import LockIcon from "@assets/icons/lock.svg";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import {
  updateBankAccountItemStore,
  updateBankAccountsStore,
} from "../../actions";
import { BankAccountSchema } from "../../constants/validation";
import {
  createBankAccountAPI,
  updateBankAccountItemAPI,
} from "../../actions/bankAccount";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";

type FieldType = {
  field: {
    onBlur: () => void;
    onChange: () => void;
    value: string;
  };
};

const initialValues: BankAccountItemInterface = {
  bankAccountName: "",
  nameOnCard: "",
  sortCode: undefined,
  accountNumber: undefined,
  cashInAccount: 0,
};

const AccountForm: React.FC<{
  closeAddMode: () => void;
  selected: BankAccountItemInterface | null;
}> = ({ closeAddMode, selected }) => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const dispatch = useDispatch();

  const createAPI = async (values: BankAccountItemInterface) => {
    const bankAccount = await dispatch(
      createBankAccountAPI({
        email,
        data: [values],
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updateBankAccountsStore(bankAccount));
  };

  const updateAPI = async (values: BankAccountItemInterface) => {
    const bankAccount = await dispatch(
      updateBankAccountItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateBankAccountItemStore(bankAccount));
  };

  return (
    <Formik
      validationSchema={BankAccountSchema}
      initialValues={selected || initialValues}
      onSubmit={(values) => {
        if (values.id) {
          updateAPI(values);
        } else {
          createAPI(values);
        }

        closeAddMode();
      }}
    >
      {({ errors, touched }) => (
        <FormikForm>
          <Text align="left">Bank Accounts</Text>

          <Field name="bankAccountName">
            {({ field }: FieldType) => (
              <Spacer>
                <Input
                  heading="Bank Name"
                  description="The name of your bank e.g. Barclays"
                  errorMessage={
                    touched.bankAccountName ? errors.bankAccountName : undefined
                  }
                  placeholder="Enter bank name"
                  {...field}
                />
              </Spacer>
            )}
          </Field>
          <Field name="nameOnCard">
            {({ field }: FieldType) => (
              <Spacer>
                <Input
                  heading="Account Holder Name (Optional)"
                  description="This is normally your name"
                  errorMessage={
                    touched.nameOnCard ? errors.nameOnCard : undefined
                  }
                  placeholder="Name of Account"
                  {...field}
                />
              </Spacer>
            )}
          </Field>
          <Field name="accountNumber">
            {({ field }: FieldType) => (
              <Spacer>
                <Input
                  type="text"
                  heading="Account Number (Optional)"
                  errorMessage={
                    touched.accountNumber ? errors.accountNumber : undefined
                  }
                  placeholder="Enter Account Number"
                  {...field}
                />
              </Spacer>
            )}
          </Field>
          <Field name="sortCode">
            {({ field }: FieldType) => (
              <Spacer>
                <Input
                  type="text"
                  heading="Sort Code (Optional)"
                  errorMessage={touched.sortCode ? errors.sortCode : undefined}
                  placeholder="Sort Code"
                  {...field}
                />
              </Spacer>
            )}
          </Field>
          <Field name="cashInAccount">
            {({ field }: FieldType) => (
              <Spacer>
                <Input
                  type="number"
                  heading="Cash in account"
                  description="Amount of funds you have in this bank account"
                  errorMessage={
                    touched.cashInAccount ? errors.cashInAccount : undefined
                  }
                  placeholder="Enter amount"
                  {...field}
                />
              </Spacer>
            )}
          </Field>
          <ButtonsContainer>
            <FormButton
              type="submit"
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={isMobile ? () => closeAddMode() : undefined}
              leftArrowLabel={isMobile ? "Back" : undefined}
            />
          </ButtonsContainer>
        </FormikForm>
      )}
    </Formik>
  );
};

export default AccountForm;
