import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "../../../../../components/Wills/constants/types";
import { maternalGrandmotherSelector } from "../../../../../components/Wills/selectors/family";

type Props = {
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean) => void;
  onBack: () => void;
};

export const MaternalGrandmother = ({
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const maternalGrandmother = useSelector(maternalGrandmotherSelector);

  useEffect(() => {
    if (maternalGrandmother.length) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [maternalGrandmother.length]);

  return (
    <>
      {showForm ? (
        <FamilyComposition
          title="Do you have a maternal grandmother?"
          description="This is your mother’s mother"
          buttonText="Add your maternal grandmother's info"
          modalTitle="About your maternal grandmother"
          limit={1}
          data={maternalGrandmother}
          onBack={onBack}
          onSave={(value) => {
            onChange({
              ...value,
              familyMemberType: FamilyMemberTypes.MaternalGrandmother,
            });
          }}
          onRemove={(id) => onDelete(id)}
          onFinish={() => onFinish(Boolean(maternalGrandmother.length))}
        />
      ) : (
        <YesNoQuestions
          title="Do you have a maternal grandmother?"
          description="This is your mother’s mother"
          onChange={(value) => (value ? setShowForm(value) : onFinish(false))}
          onBack={onBack}
        />
      )}
    </>
  );
};
