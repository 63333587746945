import React from "react";
import RadioHiddenInput from "./styled/RadioHiddenInput";
import RadioInputContainer from "./styled/RadioInputContainer";
import StyledRadioInput from "./styled/StyledRadioInput";
import RadioLabel from "./styled/RadioLabel";

export type RadioComponentProps = {
  checked: boolean;
  value: string;
  onChange: (value: string) => void;
  label: string;
  testId?: string;
  name?: string;
};

const RadioInput: React.FC<RadioComponentProps> = ({
  value,
  testId,
  checked,
  onChange,
  label,
  name,
}) => {
  return (
    <RadioInputContainer>
      <RadioHiddenInput readOnly checked={checked} name={name} type="radio" />
      <StyledRadioInput
        data-testid={testId}
        name={name}
        value={value}
        checked={checked}
        onClick={() => onChange(value)}
      />
      <RadioLabel
        data-testid={`${testId}-label`}
        checked={checked}
        name={name}
        onClick={() => onChange(value)}
      >
        {label}
      </RadioLabel>
    </RadioInputContainer>
  );
};

export default RadioInput;
