import React from "react";
import styled from "styled-components";
import Button from "../../../../legacyComponents/Button/Button.component";
import CardContainer from "../../../../legacyComponents/CardContainer/CardContainer.component";
import { theme } from "../../../../styles/legacy-theme";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../../components/mediaQueries";
import Doc from "../../../../assets/icons/doc.svg";
import Family from "../../../../assets/icons/family.svg";
import Admin from "../../../../assets/icons/administration.svg";
import Gift from "../../../../assets/icons/gift.svg";
import Plane from "../../../../assets/icons/paper-plane.svg";
import Wealth from "../../../../assets/icons/wealth.svg";
import Will from "../../../../assets/images/will.png";
import BackArrow from "../../../../assets/icons/keyboard_arrow_left.svg";
import WillEnums from "../../../views/wills/types/willEnums";
import DashboardEnums from "../../../views/dashboard/types/dashboardEnums";
import { Template, Templates } from "../../../views/wills/constants/templates";

const renderIcon = (section: WillEnums | DashboardEnums | null) => {
  switch (section) {
    case WillEnums.aboutMe:
      return <Doc />;
    case WillEnums.myFamily:
      return <Family />;
    case WillEnums.giftsBequests:
      return <Gift />;
    case WillEnums.executorsGuardians:
      return <Admin />;
    case WillEnums.myAssets:
      return <Wealth />;
    case WillEnums.messages:
      return <Plane />;
    case WillEnums.reviewSubmit:
      return <Doc />;
    case DashboardEnums.noWill:
    case DashboardEnums.inReview:
    case DashboardEnums.queries:
    case DashboardEnums.complete:
      return <img alt="will-img" src={Will} />;
    case WillEnums.getStarted:
    default:
      return <Plane />;
  }
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  margin: 2rem 0;
  max-width: 525px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
`;

const SectionTitle = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  margin: 1rem 0;
  color: ${theme.colors.Font02};
`;

const Text = styled.p`
  font-size: 1rem;
  margin-top: 5px;
  color: ${theme.colors.Font02};
`;

const StyledBackArrow = styled(BackArrow)`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 18px;
  left: 18px;
`;

type Props = {
  selectedSection: null | WillEnums | DashboardEnums;
  buttonAction: (item: WillEnums | DashboardEnums | null) => void;
  templates: Templates;
  defaultPage: WillEnums | DashboardEnums;
};

const InfoComponent: React.FC<Props> = ({
  buttonAction,
  selectedSection,
  templates,
  defaultPage,
}) => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const data = (
    selectedSection ? templates[selectedSection] : templates[defaultPage]
  ) as Template;

  const CustomButton = data.customButton || null;
  const content = () => (
    <Wrapper>
      {isMobile && <StyledBackArrow onClick={() => buttonAction(null)} />}
      <ContentWrapper>
        {renderIcon(selectedSection || defaultPage)}
        <SectionTitle>{data.title}</SectionTitle>
        {data.description.map((item: string, i: number) => (
          <Text key={i}>{item}</Text>
        ))}
        <ButtonWrapper>
          {CustomButton ? (
            <CustomButton />
          ) : (
            <Button
              testId={data.testId}
              text={data.button}
              onClick={data.onClick}
            />
          )}
        </ButtonWrapper>
        {data.additionalInfo && <Text>{data.additionalInfo}</Text>}
      </ContentWrapper>
    </Wrapper>
  );

  if (isMobile) return content();
  return <CardContainer>{content()}</CardContainer>;
};

export default InfoComponent;
