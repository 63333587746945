import React, { useContext } from "react";
import { Formik } from "formik";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { CryptoSchema } from "../../constants/validation";
import { ButtonsContainer, InnerWrapper, Text } from "../../styled";
import { CryptoItemInterface } from "@ifgengineering/hip-app-domain";
import LockIcon from "@assets/icons/lock.svg";
import { useDispatch } from "react-redux";
import { createCryptoAPI, updateCryptoItemAPI } from "../../actions/crypto";
import { updateCryptosStore, updateCryptoStore } from "../../actions";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { CurrencyContext } from "@components/CurrencyContext";

type Props = {
  title: string;
  data: CryptoItemInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

const CryptoForm = ({
  title,
  data,
  previousPage,
  onSubmit,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const createAPI = async (values: CryptoItemInterface) => {
    const crypto = await dispatch(
      createCryptoAPI({
        email,
        data: [values],
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updateCryptosStore(crypto));
  };

  const updateAPI = async (values: CryptoItemInterface) => {
    const debt = await dispatch(
      updateCryptoItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateCryptoStore(debt));
  };

  return (
    <Formik
      validationSchema={CryptoSchema}
      initialValues={data}
      onSubmit={(crypto) => {
        if (crypto.id) {
          updateAPI(crypto);
        } else {
          createAPI(crypto);
        }

        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        return (
          <FormikForm>
            <Text align="left">{title}</Text>

            <Input
              value={values.coinName?.toString() || ""}
              type="text"
              name="coinName"
              heading="Name of Coin"
              description="Name of the coin you own e.g. Bitcoin. You can also put the ticker symbol e.g. $BTC"
              errorMessage={errors.coinName}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setFieldValue("coinName", e.target.value)}
            />

            <Input
              value={values.numCoins?.toString() || ""}
              type="number"
              name="numCoins"
              heading="Number of Coins (Optional)"
              errorMessage={
                touched.numCoins && errors.numCoins ? errors.numCoins : ""
              }
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setFieldValue("numCoins", Number(e.target.value))}
            />

            <MoneyInput
              heading="Total Estimated Value of Coins"
              description="How much you think your coins are worth"
              value={values.totalValue?.toString() || ""}
              currency={currencySymbol}
              onChange={(value) => setFieldValue("totalValue", Number(value))}
              errorMessage={
                touched.totalValue && errors.totalValue ? errors.totalValue : ""
              }
            />

            <ButtonsContainer>
              <FormButton
                onClick={submitForm}
                type="submit"
                text="Save & Continue"
                disclaimerIcon={<LockIcon />}
                disclaimerText={
                  <div>
                    We take your{" "}
                    <a
                      href="https://www.islamicfinanceguru.com/security-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy seriously
                    </a>
                    . Our institutional-grade security ensures your data is
                    confidential.
                  </div>
                }
                leftArrowOnClick={isMobile ? previousPage : undefined}
                leftArrowLabel={isMobile ? "Back" : undefined}
              />
            </ButtonsContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default CryptoForm;
