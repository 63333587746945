import React, { useState } from "react";
import styled from "styled-components";
import Executors from "./pages/executors";
import Guardians from "./pages/guardians";

export const Layout = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const FormWrapper = styled.div`
  width: 327px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export enum ExecutorGuardianPages {
  executors,
  guardians,
}

const AboutYou: React.FC = () => {
  const [page, setPage] = useState(ExecutorGuardianPages.executors);
  return (
    <Layout>
      <FormWrapper>
        {page === ExecutorGuardianPages.executors && (
          <Executors setPage={setPage} />
        )}
        {page === ExecutorGuardianPages.guardians && (
          <Guardians setPage={setPage} />
        )}
      </FormWrapper>
    </Layout>
  );
};

export default AboutYou;
