export const sections = {
  aboutMe: {
    firstName: "What is your first name?",
    lastName: "What is your last name?",
    gender: "What is your gender?",
    address: "What is your address?",
    mobileNumber: "What is your phone number?",
  },
  family: {
    memberDisabled: "Family members disabled:",
    memberReceivingTestedBenefits: "Family members receiving benefits:",
    familyMembers: "Family members:",
  },
  financialAssets: {
    bankAccounts: "Please list out all the banks you have accounts with:",
    properties: "Please list out all addresses of any properties you own:",
    pensions: "Please list out any pensions you have:",
    insurance: "Please list out any life insurance policies you have:",
    other: "Please list out any financial assets you may not have mentioned",
  },
  executors: {
    executionerList: "Your executors:",
  },
  guardians: {
    guardianList: "Your guardians:",
  },
  giftsBequests: {
    sponsoredCharity: "Sponsored Charity:",
    memberDisabled: "Beneficiaries disabled:",
    memberReceivingTestedBenefits: "Beneficiaries receiving benefits:",
    bequestIndividuals: "Bequest individuals:",
    bequestCharity: "Bequest charities:",
  },
  departingMessage: { departingMessage: "Message to your loved ones" },
  assets: {
    estimatedEstateValue: "Estimated Value of Estate:",
    cash: {
      cashISA: "Cash ISA:",
      cashInHand: "Cash in hand:",
    },
    bankAccounts: {
      bankAccountList: "Your bank accounts:",
    },
    stockAccounts: {
      stockAccountList: "Your stock accounts:",
    },
    cryptoAccounts: {
      cryptoAccountList: "Your crypto accounts:",
    },
    debts: {
      debt: "Your debts:",
    },
    commodities: {
      commoditiesList: "Your commodities:",
    },
    childTrustFunds: {
      childTrustFundList: "Your child trust funds:",
    },
    funds: {
      fundsList: "Your funds:",
    },
    stocks: {
      shareList: "Your shares list:",
    },
    crypto: {
      cryptoList: "Your cryptos:",
    },
    pensions: {
      pensionList: "Your pensions:",
    },
    properties: {
      propertyList: "Your properties:",
    },
    businessAssets: {
      businessList: "Your business assets:",
    },
    startups: {
      startupInvestments: "Your startup investments:",
    },
    agricultureAssets: {
      agricultureList: "Your agricultural assets:",
    },
    alternativeInvestments: {
      alternativeAssetList: "Your alternative investments:",
    },
    owedAssets: {
      owedAssetList: "Assets you are owed:",
    },
  },
};
