import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_BANK_ACCOUNT_API,
  DELETE_BANK_ACCOUNT_API,
  UPDATE_BANK_ACCOUNT_API,
} from "../constants/actionTypes";
import { BankAccountItemObject, BankAccountObject } from "../constants/types";

export const createBankAccountAPI = createAsyncThunk<
  BankAccountObject,
  {
    email: string;
    data: BankAccountItemObject[];
  }
>(CREATE_BANK_ACCOUNT_API, async ({ email, data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: [CreateBankAccountItemInput!]!, $email: String!) {
          createBankAccount(data: $data, email: $email) {
            id
            totalValue
            bankAccountList {
              id
              nameOnCard
              sortCode
              accountNumber
              bankAccountName
              cashInAccount
            }
          }
        }
      `,
      variables: {
        email,
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createBankAccount;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "createBankAccountAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateBankAccountItemAPI = createAsyncThunk<
  BankAccountItemObject,
  BankAccountItemObject
>(UPDATE_BANK_ACCOUNT_API, async (data) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: UpdateBankAccountItemInput!) {
          updateBankAccountItem(data: $data) {
            id
            nameOnCard
            sortCode
            accountNumber
            bankAccountName
            cashInAccount
          }
        }
      `,
      variables: {
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.updateBankAccountItem;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateBankAccountItemAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteBankAccountAPI = createAsyncThunk<string, number>(
  DELETE_BANK_ACCOUNT_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deleteBankAccountItem(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return [];
      }

      return response.data.deleteBankAccountItem;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deleteBankAccountAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
