import * as Yup from "yup";
import { FamilyMemberObject } from "../../../../components/Wills/constants/types";

export const validateNames = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
});

export const validateGender = Yup.object({
  familyMemberType: Yup.string().required("Gender is required"),
});

export const initialValues: FamilyMemberObject = {
  familyMemberType: "",
  firstName: "",
  lastName: "",
};
