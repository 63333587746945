import React, { useEffect, useRef, useCallback, useState } from "react";
import ReactTooltip, { Place } from "react-tooltip";
import {
  Text,
  TextTypeAndSize,
  MEDIA_QUERIES,
} from "@ifgengineering/component-library";
import { ColorType, theme } from "@styles/theme";
import { useMediaQuery } from "react-responsive";
import {
  LabelText,
  StyledCross,
  StyledIcon,
  StyledTooltip,
  TitleText,
  TooltipText,
  TooltipWrapper,
} from "./styled";

type TooltipRef = { tooltipRef: null } | null;

interface TootltipProps {
  text: string | React.ReactElement;
  textColor?: ColorType;
  label?: string;
  labelFont?: TextTypeAndSize;
  labelColor?: ColorType;
  title?: string;
  titleColor?: ColorType;
  id?: string;
  backgroundColor?: ColorType;
  openOnMount?: boolean;
  icon?: string;
  iconColor?: ColorType;
  place?: Place;
}

const Tooltip: React.FC<TootltipProps> = ({
  id,
  icon,
  iconColor = "SLATE450",
  backgroundColor = "WHITE",
  text,
  textColor = "SLATE500",
  label = "Important Information",
  labelFont = "H14",
  labelColor = "SLATE800",
  openOnMount,
  title,
  titleColor = "SLATE500",
  place = "bottom",
}) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const tooltipWrapperRef = useRef(null);
  const tooltip = useRef(null);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  useEffect(() => {
    openOnMount && onTooltipTriggerClick();
  }, [tooltipWrapperRef.current]);

  const hideTooltipHandler = useCallback(() => {
    const tooltipWrapperEl = tooltipWrapperRef.current;
    if (tooltipWrapperEl) {
      ReactTooltip.hide(tooltipWrapperEl);
      setTooltipOpen(false);
      document.removeEventListener("click", hideTooltipHandler);
    }
  }, []);

  const onTooltipTriggerClick = useCallback(() => {
    const tooltipWrapperEl = tooltipWrapperRef.current;
    if (tooltipWrapperEl) {
      if (isTooltipOpen) {
        ReactTooltip.hide(tooltipWrapperEl);
        setTooltipOpen(false);
      } else {
        ReactTooltip.show(tooltipWrapperEl);
        setTooltipOpen(true);

        document.addEventListener("click", hideTooltipHandler);
      }
    }
  }, [isTooltipOpen]);

  useEffect(() => {
    return () => {
      document.removeEventListener("click", hideTooltipHandler);
    };
  }, []);

  return (
    <section>
      <div
        data-tip
        data-for={id}
        ref={tooltipWrapperRef}
        data-event="click"
        onClick={onTooltipTriggerClick}
      >
        {icon ? (
          <StyledIcon type={icon} size={16} fill={theme.colors[iconColor]} />
        ) : (
          <LabelText type={labelFont} color={labelColor}>
            {label}
          </LabelText>
        )}
      </div>
      <StyledTooltip
        ref={tooltip}
        overridePosition={(position: { top: number; left: number }) => ({
          top: position.top,
          left: window.innerWidth < 600 ? 4 : position.left,
        })}
        backgroundColor={theme.colors[backgroundColor]}
        id={id}
        place={place}
        type="light"
        isCapture
        clickable
        globalEventOff="hover"
        effect="solid"
        arrowColor={isMobile ? "transparent" : theme.colors[backgroundColor]}
        getContent={() => (
          <TooltipWrapper>
            <StyledCross
              fill={theme.colors.SLATE500}
              size={14}
              onClick={() => {
                const current: TooltipRef = tooltip.current;
                current!.tooltipRef = null;
                onTooltipTriggerClick();
              }}
            />
            <TooltipText>
              <TitleText type="H14" color={titleColor}>
                {title}
              </TitleText>
              <Text type="P14" color={textColor}>
                {text}
              </Text>
            </TooltipText>
          </TooltipWrapper>
        )}
      />
    </section>
  );
};

export default Tooltip;
