import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { WEALTH_SITE_URL, CUR8_SITE_URL } from "gatsby-env-variables";
import { NavigationBar } from "@ifgengineering/component-library";
import { useDispatch } from "react-redux";
import {
  NavBar,
  NavMenuItemsPosition,
  MenuTemplateItem,
} from "@ifgengineering/client-app-navbar";
import { navigate as outerNav } from "@reach/router";
import { navigate as innerNav } from "gatsby";
import { useLocation } from "@reach/router";
import store from "../store";
import useAnalytics from "@helpers/useAnalytics";
import { logoutAction } from "../../components/Login/actions";

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChildrenLayout = styled.div`
  padding: 2rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const NavigationBarContainer = styled.div``;

const tabs: { id: string; title: string }[] = [
  {
    id: "wills",
    title: "My Will",
  },
  {
    id: "summary",
    title: "Summary",
  },
];

const NavigationLayout: React.FC = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch<typeof store.dispatch>();
  const fireAppSwitchAnalytics = useAnalytics("appSwitch");
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const logoutCallback = () => dispatch(logoutAction());

  const template: MenuTemplateItem[] = [
    {
      tab: "cur8",
      label: "Invest",
      selected: false,
      position: NavMenuItemsPosition.MAIN,
      onClick: async () => {
        (await fireAppSwitchAnalytics)("Cur8");
        await outerNav(`${CUR8_SITE_URL}/app/deals`);
      },
    },
    {
      tab: "help",
      label: "Help",
      selected: false,
      position: NavMenuItemsPosition.SECONDARY,
      onClick: () => outerNav(`${WEALTH_SITE_URL}/app/compare?view=help`),
    },
  ];

  const willsRoutes: Record<string, string> = {
    "/app": "wills",
    "/app/wills": "myWill",
    "/app/wills/family": "family",
    "/app/wills/about": "aboutMe",
    "/app/wills/messages": "messages",
    "/app/wills/gifts": "gifts",
    "/app/wills/executors": "executors",
    "/app/wills/assets": "assets",
    "/app/summary": "summary",
    "/app/payment": "payment",
  };

  const location = useLocation();
  const fireAnalyticsEvent = useAnalytics("pageView");
  const currentPage = location.pathname.split("/")[2];

  useEffect(() => {
    (async () => {
      (await fireAnalyticsEvent)({
        eventName: `wills:${willsRoutes[location.pathname]}_pageViewed`,
      });
    })();
  }, [location]);

  const onSetPage = (page: string) => {
    innerNav(`/app/${page}`);
  };

  return (
    <NavBar
      template={template}
      isOpen={isMenuOpen}
      hideTopBarDesktop={true}
      hideTopBarMobile={true}
      toggleMenu={toggleMenu}
      page="Wills"
      logoutCallback={logoutCallback}
    >
      <StyledLayout>
        <NavigationBarContainer>
          <NavigationBar
            navItemsTextColor="WHITE"
            selectedNavItemsTextColor="WHITE"
            bgColor="BLACK"
            selectedNavItemsBgColor="BLUE600"
            selectedItemId={currentPage}
            pageName="Wills"
            navItems={tabs}
            onSelectNavItem={(item) => onSetPage(item.id)}
          />
        </NavigationBarContainer>
        <ChildrenLayout>{children}</ChildrenLayout>
      </StyledLayout>
    </NavBar>
  );
};

export default NavigationLayout;
