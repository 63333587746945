import React, { useContext } from "react";
import { Formik } from "formik";
import LockIcon from "@assets/icons/lock.svg";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { PensionSchema } from "../../constants/validation";
import { ButtonsContainer, Text } from "../../styled";
import { PensionItemInterface } from "@ifgengineering/hip-app-domain";
import { useDispatch } from "react-redux";
import { createPensionAPI, updatePensionItemAPI } from "../../actions/pension";
import { updatePensionsStore, updatePensionItemStore } from "../../actions";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { CurrencyContext } from "@components/CurrencyContext";

type Props = {
  title: string;
  data: PensionItemInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

const PensionForm = ({
  title,
  data,
  previousPage,
  onSubmit,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const { currencySymbol } = useContext(CurrencyContext);
  const dispatch = useDispatch();

  const createAPI = async (values: PensionItemInterface) => {
    const pension = await dispatch(
      createPensionAPI({
        email,
        data: [values],
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updatePensionsStore(pension));
  };

  const updateAPI = async (values: PensionItemInterface) => {
    const debt = await dispatch(
      updatePensionItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updatePensionItemStore(debt));
  };

  return (
    <Formik
      validationSchema={PensionSchema}
      initialValues={data}
      onSubmit={(pension) => {
        if (pension.id) {
          updateAPI(pension);
        } else {
          createAPI(pension);
        }

        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        return (
          <FormikForm>
            <Text align="left">{title}</Text>

            <Input
              value={values.description || ""}
              type="textarea"
              name="description"
              heading="Description"
              description="e.g. my workplace pension with Pension Bee"
              errorMessage={errors.description}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setFieldValue("description", e.target.value)}
            />

            <MoneyInput
              heading="Estimated value in pension"
              description="How much you think your pension is worth"
              value={values.totalValue.toString() || ""}
              currency={currencySymbol}
              onChange={(value) => setFieldValue("totalValue", Number(value))}
              errorMessage={
                touched.totalValue && errors.totalValue ? errors.totalValue : ""
              }
            />

            <ButtonsContainer>
              <FormButton
                onClick={submitForm}
                type="submit"
                text="Save & Continue"
                disclaimerIcon={<LockIcon />}
                disclaimerText={
                  <div>
                    We take your{" "}
                    <a
                      href="https://www.islamicfinanceguru.com/security-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy seriously
                    </a>
                    . Our institutional-grade security ensures your data is
                    confidential.
                  </div>
                }
                leftArrowOnClick={isMobile ? previousPage : undefined}
                leftArrowLabel={isMobile ? "Back" : undefined}
              />
            </ButtonsContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default PensionForm;
