import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "../../../../../components/Wills/constants/types";
import { siblingsSelector } from "../../../../../components/Wills/selectors/family";

type Props = {
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean, hasMale?: boolean, hasFemale?: boolean) => void;
  onBack: () => void;
};

export const Siblings = ({
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const siblings = useSelector(siblingsSelector);
  const brothers = siblings.filter(
    (sibling) => sibling.familyMemberType === FamilyMemberTypes.Brother
  );
  const sisters = siblings.filter(
    (sibling) => sibling.familyMemberType === FamilyMemberTypes.Sister
  );

  useEffect(() => {
    if (siblings.length) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [siblings.length]);

  return (
    <>
      {showForm ? (
        <FamilyComposition
          title="Do you have any full siblings?"
          description="These are your brothers and sisters that share the same father and mother as you."
          buttonText="Add a brother or sister"
          modalTitle="Brother or sister"
          formConfig={{
            askGender: true,
          }}
          dropDownOptions={[
            {
              label: "Male",
              value: FamilyMemberTypes.Brother,
            },
            {
              label: "Female",
              value: FamilyMemberTypes.Sister,
            },
          ]}
          data={siblings}
          onBack={onBack}
          onSave={(value) => onChange(value)}
          onRemove={(id) => onDelete(id)}
          onFinish={() =>
            onFinish(
              Boolean(siblings.length),
              Boolean(brothers.length),
              Boolean(sisters.length)
            )
          }
        />
      ) : (
        <YesNoQuestions
          title="Do you have any full siblings?"
          description="These are your brothers and sisters that share the same father and mother as you. Non-Muslim family members are not eligible for Islamic Inheritance and so shouldn’t be added here. You can still leave them a share of inheritance in the Bequests section."
          onChange={(value) => (value ? setShowForm(value) : onFinish(false))}
          onBack={onBack}
        />
      )}
    </>
  );
};
