import { State } from "./types";

export enum PAGES {
  SPOUSE,
  FATHER,
  MOTHER,
  CHILDREN,
  PATERNAL_GRANDFATHER,
  PATERNAL_GRANDMOTHER,
  MATERNAL_GRANDMOTHER,
  GRAND_CHILDREN,
  SIBLINGS,
  MATERNAL_SIBLINGS,
  PATERNAL_BROTHERS,
  PATERNAL_SISTERS,
  FULL_BROTHERS_SON,
  PATERNAL_BROTHERS_SON,
  FULL_UNCLES,
  FATHER_PATERNAL_BROTHERS,
  FULL_PATERNAL_UNCLES_SON,
  FATHER_PATERNAL_BROTHERS_SON,
  MEMBER_RECEIVING_BENEFITS,
  MEMBER_DISABLED,
}

export const DEFAULT_STATE: State = {
  [PAGES.SPOUSE]: {
    has: false,
    answered: false,
  },
  [PAGES.FATHER]: {
    has: false,
    answered: false,
  },
  [PAGES.MOTHER]: {
    has: false,
    answered: false,
  },
  [PAGES.CHILDREN]: {
    has: false,
    answered: false,
    hasFemale: false,
    hasMale: false,
  },
  [PAGES.PATERNAL_GRANDFATHER]: {
    has: false,
    answered: false,
  },
  [PAGES.PATERNAL_GRANDMOTHER]: {
    has: false,
    answered: false,
  },
  [PAGES.MATERNAL_GRANDMOTHER]: {
    has: false,
    answered: false,
  },
  [PAGES.GRAND_CHILDREN]: {
    has: false,
    answered: false,
    hasFemale: false,
    hasMale: false,
  },
  [PAGES.SIBLINGS]: {
    has: false,
    answered: false,
    hasFemale: false,
    hasMale: false,
  },
  [PAGES.MATERNAL_SIBLINGS]: {
    has: false,
    answered: false,
  },
  [PAGES.PATERNAL_BROTHERS]: {
    has: false,
    answered: false,
  },
  [PAGES.PATERNAL_SISTERS]: {
    has: false,
    answered: false,
  },
  [PAGES.FULL_BROTHERS_SON]: {
    has: false,
    answered: false,
  },
  [PAGES.PATERNAL_BROTHERS_SON]: {
    has: false,
    answered: false,
  },
  [PAGES.FULL_UNCLES]: {
    has: false,
    answered: false,
  },
  [PAGES.FATHER_PATERNAL_BROTHERS]: {
    has: false,
    answered: false,
  },
  [PAGES.FULL_PATERNAL_UNCLES_SON]: {
    has: false,
    answered: false,
  },
  [PAGES.FATHER_PATERNAL_BROTHERS_SON]: {
    has: false,
    answered: false,
  },
  [PAGES.MEMBER_RECEIVING_BENEFITS]: {
    has: false,
    answered: false,
  },
  [PAGES.MEMBER_DISABLED]: {
    has: false,
    answered: false,
  },
};
