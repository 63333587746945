const COLORS = [
  "#284FF1",
  "#0062A9",
  "#121A5E",
  "#1D06AC",
  "#4A7585",
  "#71A9BD",
  "#8CE1FF",
];

export default COLORS;
