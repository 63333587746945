import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "../../../../../components/Wills/constants/types";
import { paternalBrothersSonSelector } from "../../../../../components/Wills/selectors/family";

type Props = {
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean) => void;
  onBack: () => void;
};

export const PaternalBrothersSon = ({
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const paternalBrothersSon = useSelector(paternalBrothersSonSelector);

  useEffect(() => {
    if (paternalBrothersSon.length) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [paternalBrothersSon.length]);

  return (
    <>
      {showForm ? (
        <FamilyComposition
          title="Do any of your paternal half-brothers have any sons?"
          description="Your paternal half-brother shares the same father with you but has a different mother"
          buttonText="Add a paternal half-brother son"
          modalTitle="Paternal half-brother’s son"
          data={paternalBrothersSon}
          onBack={onBack}
          onSave={(value) => {
            onChange({
              ...value,
              familyMemberType: FamilyMemberTypes.PaternalBrothersSon,
            });
          }}
          onRemove={(id) => onDelete(id)}
          onFinish={() => onFinish(Boolean(paternalBrothersSon.length))}
        />
      ) : (
        <YesNoQuestions
          title="Do any of your paternal half-brothers have any sons?"
          description="Your paternal half-brother shares the same father with you but has a different mother"
          onChange={(value) => (value ? setShowForm(value) : onFinish(false))}
          onBack={onBack}
        />
      )}
    </>
  );
};
