import { gql } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { FETCH_WILLS_API, UPDATE_WILLS_API } from "../constants/actionTypes";
import { WillInput, WillObject } from "../constants/types";
import { logoutAction } from "@components/Login/actions";
import { logException } from "@helpers/logException";

type fetchWillAPIReturn = {
  data?: WillObject;
};

export const fetchWillAPI = createAsyncThunk<
  fetchWillAPIReturn,
  {
    email: string;
    partnerCharityId?: string;
  }
>(
  FETCH_WILLS_API,

  async ({ email, partnerCharityId }, { dispatch }) => {
    const address = `address {
      id
      addressLine1
      addressLine2
      city
      region
      country
      postcode
    }`;

    try {
      const response = await client.query({
        query: gql`
          query($partnerCharityId: String, $email: String!) {
            findWillOrCreate(partnerCharityId: $partnerCharityId, email: $email) {
              id
              status
              adminStatus
              departingMessage
              partnerCharityId
              userInfo {
                firstName
                lastName
                email
                mobileNumber
                gender
                referral
                dob
                ${address}
              }
              family {
                id
                memberDisabled
                memberReceivingTestedBenefits
                familyMembers {
                  id
                  firstName
                  lastName
                  over18
                  percentageEstate
                  marriageStatustype
                  email
                  familyMemberType
                }
              }
              bequests {
                id
                memberDisabled
                memberReceivingTestedBenefits
                bequestIndividuals {
                  id
                  firstName
                  lastName
                  islamicInheritor
                  emailAddress
                  ${address}
                  percentageEstate
                  partOfInheritance
                  relationship
                  includedAsShare
                  personDisabled
                  money
                  itemsToLeave
                  message
                }
                bequestCharity {
                  id
                  fullName
                  ${address}
                  percentageEstate
                  charityID
                  description
                  money
                  message
                  partnerCharity {
                    id
                  }
                  partnerCharityCauses {
                    id
                    nameOfCause
                  }
                }
              }
              overseer {
                executionerList {
                  id
                  firstName
                  lastName
                  email
                  ${address}
                }
                guardianList {
                  id
                  firstName
                  lastName
                  email
                  ${address}
                }
              }
            }
          }
        `,
        variables: {
          ...(partnerCharityId ? { partnerCharityId } : {}),
          email,
        },
        fetchPolicy: "no-cache",
      });

      const {
        errors,
        data: { findWillOrCreate: data },
      } = response;

      return { errors, data };
    } catch (e: any) {
      if (
        e.networkError.result.errors.some(
          ({ statusCode }: { statusCode: number }) => statusCode === 401
        )
      ) {
        dispatch(logoutAction());
      } else {
        toast.warn("Something went wrong. Try again or contact support");

        logException({
          tag: "fetchWillAPI",
          exception: e,
        });
      }

      return { errors: e.networkError.result.errors };
    }
  }
);

export const updateWillAPI = createAsyncThunk<WillObject, WillInput>(
  UPDATE_WILLS_API,
  async (wills) => {
    try {
      const response = await client.mutate<WillInput>({
        mutation: gql`
          mutation ($data: WillUpdateInput!) {
            updateWill(data: $data) {
              id
              status
            }
          }
        `,
        variables: {
          data: {
            ...wills,
          },
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
      }

      return response?.data?.updateWill;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");
      logException({
        tag: "updateWillAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
