import DashboardEnums from "../types/dashboardEnums";

const sections = [
  {
    sectionNumber: 1,
    text: "Writing your will",
    buttonText: "Start",
    content: DashboardEnums.noWill,
  },
  {
    sectionNumber: 2,
    text: "Submitted for review",
    buttonText: "View",
    content: DashboardEnums.inReview,
  },
  {
    sectionNumber: 3,
    text: "Download your will",
    buttonText: "View",
    content: DashboardEnums.complete,
  },
];

export default sections;
