import AgricultureAssets from "../pages/agricultureAssets";
import AlternativeInvestments from "../pages/alternativeInvestments";
import BankAccount from "../pages/bankAccount";
import BusinessAssets from "../pages/businessAssets";
import Cash from "../pages/cash";
import ChildTrustFund from "../pages/childTrustFund";
import Commodity from "../pages/commodity";
import Crypto from "../pages/crypto";
import Debts from "../pages/debt";
import OwedAssets from "../pages/owedAssets";
import Pensions from "../pages/pensions";
import property from "../pages/property";
import StocksAndFunds from "../pages/stock";
import Startups from "../pages/startups";
import { PageType, ASSETS } from "../types";

interface Page {
  label: string;
  page: React.FC<PageType>;
}

export const pages: Record<ASSETS, Page> = {
  [ASSETS.cash]: {
    label: "Cash",
    page: Cash,
  },
  [ASSETS.bankAccounts]: {
    label: "Bank Accounts",
    page: BankAccount,
  },
  [ASSETS.commodities]: {
    label: "Gold & Silver",
    page: Commodity,
  },
  [ASSETS.debts]: {
    label: "Debts",
    page: Debts,
  },
  [ASSETS.childTrustFunds]: {
    label: "Child Trust Fund",
    page: ChildTrustFund,
  },
  [ASSETS.stocks]: {
    label: "Shares & Funds",
    page: StocksAndFunds,
  },
  [ASSETS.pensions]: {
    label: "Pensions",
    page: Pensions,
  },
  [ASSETS.crypto]: {
    label: "Crypto",
    page: Crypto,
  },
  [ASSETS.properties]: {
    label: "Property",
    page: property,
  },
  [ASSETS.businessAssets]: {
    label: "Business Assets",
    page: BusinessAssets,
  },
  [ASSETS.agricultureAssets]: {
    label: "Agricultural Assets",
    page: AgricultureAssets,
  },
  [ASSETS.startups]: {
    label: "Startups",
    page: Startups,
  },
  [ASSETS.owedAssets]: {
    label: "Assets owed",
    page: OwedAssets,
  },
  [ASSETS.alternativeInvestments]: {
    label: "Other",
    page: AlternativeInvestments,
  },
};
