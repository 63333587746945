import React, { useContext, useState } from "react";
import {
  InnerWrapper,
  Layout,
  Text,
  Description,
  CardsContainer,
} from "../../styled";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import LockIcon from "@assets/icons/lock.svg";
import { getAgricultures } from "../../selectors";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import AddItemCard from "@legacyComponents/AddItemCard/AddItemCard.component";
import AccountCard from "@legacyComponents/AccountCard/AccountCard.component";
import { deleteAgricultureStore } from "../../actions";
import Modal from "@legacyComponents/Modal/Modal.component";
import AgricultureAssetsForm from "./AgricultureAssetsForm";
import { AgricultureItemInterface } from "@ifgengineering/hip-app-domain";
import { AgricultureItemObject } from "../../constants/types";
import { deleteAgricultureAPI } from "../../actions/agriculture";
import Tooltip from "@components/Tooltip";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

const emptyState: AgricultureItemInterface = {
  value: 0,
};

const AgricultureAssets: React.FC<PageType> = ({
  nextPage,
  previousPage,
  showTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedTrust, setSelectedTurst] =
    useState<AgricultureItemInterface>(emptyState);
  const { currencySymbol } = useContext(CurrencyContext);
  const agricultureList = useSelector(getAgricultures);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch();

  const deleteAPI = async (value: AgricultureItemObject) => {
    await dispatch(
      deleteAgricultureAPI(value.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deleteAgricultureStore(value));
  };

  const onClose = () => {
    setOpen(false);
    setSelectedTurst(emptyState);
  };

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Agriculture Assets</Text>
              <Description>
                If you have farmland, crops or livestock you can add it here.
              </Description>
              {showTooltip && <Tooltip text={tooltipText.agricultureAssets} />}
            </div>

            <CardsContainer>
              <AddItemCard
                text="Add agriculture asset"
                onClick={() => {
                  setOpen(true);
                  setSelectedTurst({
                    value: 0,
                  });
                }}
              />

              {agricultureList.map((agriculture) => {
                const { value, description = "" } = agriculture;

                return (
                  <AccountCard
                    key={`${description}-${value}`}
                    name={description}
                    currency={currencySymbol}
                    balance={value}
                    onClickDelete={() => deleteAPI(agriculture)}
                    onClickEdit={() => {
                      setOpen(true);
                      setSelectedTurst(agriculture);
                    }}
                  />
                );
              })}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        <AgricultureAssetsForm
          title="Add agriculture asset"
          data={selectedTrust}
          previousPage={previousPage}
          onSubmit={onClose}
        />
      ) : (
        <Modal onClose={onClose} open={open}>
          <AgricultureAssetsForm
            title="Add agriculture asset"
            data={selectedTrust}
            previousPage={previousPage}
            onSubmit={onClose}
          />
        </Modal>
      )}
    </>
  );
};

export default AgricultureAssets;
