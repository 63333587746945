export const labels = {
  cash: "Cash",
  bankAccounts: "Bank accounts",
  debts: "Debts",
  commodities: "Commodities",
  childTrustFunds: "Child trust funds",
  funds: "Funds",
  stocks: "Stocks",
  stockAccounts: "Stock Accounts",
  crypto: "Crypto",
  currency: "Currency",
  cryptoAccounts: "Crypto Accounts",
  pensions: "Pensions",
  pensionName: "Pension name",
  properties: "Properties",
  businessAssets: "Business assets",
  startups: "Startups",
  agricultureAssets: "Agriculture assets",
  alternativeInvestments: "Alternative investments",
  owedAssets: "Owed assets",
  estimatedEstateValue: "Estimated estate value",
};
