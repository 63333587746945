import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { Link } from "gatsby";
import UpArrow from "../../../assets/icons/up-arrow.svg";
import Button, {
  ButtonStates,
} from "../../../legacyComponents/Button/Button.component";
import VideoPlaceholder from "../../../assets/images/video-placeholder.png";
import BackArrowIcon from "../../../assets/icons/blue_arrow_pointing_left.svg";
import { CURRENCY } from "../../../constants/currency";

const PageContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 90vw;
  min-height: 600px;
  padding: 2em 4em;
  background-color: ${theme.colors.Primary02};
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  @media (max-width: 649px) {
    background-color: inherit;
    width: 100%;
    padding: 0 0;
    box-shadow: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  max-width: 300px;
`;

const HeaderContainer = styled.div`
  text-align: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const HeadingText = styled.p`
  color: ${theme.colors.BrandNavy};
  font-size: 1.5rem;
  font-weight: 700;
  margin-top 1.5em;

`;

const TextContainer = styled.div`
  text-align: center;
  & > p {
    margin-top: 1em;
  }
`;

const Text = styled.p`
  color: ${theme.colors.Font02};
`;

const LinkText = styled.a`
  & > p {
    margin-top: 1em;
    color: ${theme.colors.Primary01};
  }
`;

const VideoContainer = styled.div``;

const ButtonsContainer = styled.div`
  display: grid;
  gap: 0.85em;
`;

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.85em;
  & > p {
    color: ${theme.colors.Primary01};
    font-weight: 700;
    font-size: 1em;
  }
  & > div {
    display: flex;
    width: 40px;
    & > svg {
      fill: ${theme.colors.Primary01};
    }
  }
`;

const WillUpgrade: React.FC = () => {
  return (
    <PageContainerWrapper>
      <PageContainer>
        <ContentContainer>
          <HeaderContainer>
            <IconContainer>
              <UpArrow />
            </IconContainer>
            <HeadingText>Upgrade Your Will</HeadingText>
          </HeaderContainer>
          <TextContainer>
            <Text>
              Because your assets are above £1m we recommend you get a{" "}
              <b>Trust-based Will.</b>
            </Text>
            <Text>
              This will allow your family to save 30% in inheritance tax when
              inheriting your estate
            </Text>
            {/* <LinkText href="#">
              <Text>Important Information</Text>
            </LinkText> */}
          </TextContainer>
          <VideoContainer>
            {/* This should be replaced with an iframe or videoplayer */}
            <img src={VideoPlaceholder} alt="video placeholder" />
          </VideoContainer>
          <ButtonsContainer>
            <Button text={`Yes, upgrade my Will (${CURRENCY.POUND}350)`} />
            <Button
              text={`No, i'll pay inheritance tax`}
              version={ButtonStates.PRIMARY_GHOST_LARGE}
            />
            <Link to="/app/wills">
              <BackButtonContainer>
                <div>
                  <BackArrowIcon />
                </div>
                <Text>Back</Text>
              </BackButtonContainer>
            </Link>
          </ButtonsContainer>
        </ContentContainer>
      </PageContainer>
    </PageContainerWrapper>
  );
};

export default WillUpgrade;
