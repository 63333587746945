import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { navigate } from "gatsby";
import { PAGES, State } from "../../../components/Family/NavigationFlow";
import {
  nextPage,
  previousPage,
} from "../../../components/Family/NavigationFlow/familyNavigationDecision";
import {
  createFamilyMember,
  updateBeneficiaryDisabled,
  updateFamily,
  updateFamilyMember,
  updateMemberReceivingTestedBenefits,
} from "../../../../components/Wills/actions";
import { userInfoSelector } from "../../../../components/Wills/selectors";
import { convertStoreToUIState } from "./helpers/convertStoreToUIState";
import { PAGES_COMPONENTS } from "./pages";
import FamilySummary from "./pages/FamilySummary";
import { MemberDisabled } from "./pages/MemberDisabled";
import { MemberReceivingTestedBenefits } from "./pages/MemberReceivingTestedBenefits";
import {
  createFamilyAPI,
  fetchFamilyAPI,
} from "../../../../components/Wills/actions/family";
import { FamilyMemberObject } from "../../../../components/Wills/constants/types";
import {
  createFamilyMembersAPI,
  deleteFamilyMembersAPI,
  updateFamilyMembersAPI,
} from "../../../../components/Wills/actions/familyMember";
import { familySelector } from "../../../../components/Wills/selectors/family";
import Loading from "../../../../components/Loading";
import resetFlow from "./helpers/resetFlow";
import store from "../../../store";
import { getUserEmail } from "../../../../components/Login/selectors";

const Family = (): JSX.Element => {
  const userInfo = useSelector(userInfoSelector);
  const familyState = useSelector(familySelector);
  const email = useSelector(getUserEmail);
  const [state, setState] = useState<State | undefined>();
  const [page, setPage] = useState<number | null>(null);
  const dispatch = useDispatch<typeof store.dispatch>();

  async function create() {
    const family = await dispatch(createFamilyAPI(email)).unwrap();
    dispatch(
      updateFamily({
        ...family,
        familyMembers: [],
      })
    );
  }

  async function fetch() {
    const { data, errors } = await dispatch(fetchFamilyAPI(email)).unwrap();

    if (errors) {
      if (
        errors.message === "Response not successful: Received status code 400"
      ) {
        create();
      }
    } else {
      dispatch(updateFamily(data));
    }
  }

  useEffect(() => {
    if (!email) {
      return;
    }

    fetch();
  }, [email]);

  useEffect(() => {
    if (!state && familyState?.familyMembers) {
      setState(convertStoreToUIState(familyState, userInfo));
    }
  }, [familyState, state]);

  useEffect(() => {
    if (state) {
      setPage(nextPage(state));
    }
  }, [state]);

  const updateAPI = async (familyMember: FamilyMemberObject) => {
    const family = await dispatch(
      updateFamilyMembersAPI(familyMember)
    ).unwrap();
    dispatch(updateFamilyMember(family));
  };

  const createAPI = async (email: string, familyMember: FamilyMemberObject) => {
    const family = await dispatch(
      createFamilyMembersAPI({
        email,
        familyMember,
      })
    ).unwrap();
    dispatch(createFamilyMember(family));
  };

  const deleteAPI = async (id: string | undefined) => {
    await dispatch(deleteFamilyMembersAPI(id as string));
    const { data } = await dispatch(fetchFamilyAPI(email)).unwrap();
    dispatch(updateFamily(data));
  };

  const El = PAGES_COMPONENTS[page || 0];

  if (!state) {
    return <Loading />;
  }

  if (page !== null) {
    return (
      <El
        onChange={(familyMember: FamilyMemberObject) => {
          if (familyMember.id) {
            updateAPI(familyMember);
          } else {
            createAPI(email, familyMember);
          }
        }}
        onDelete={(id) => {
          deleteAPI(id);
        }}
        onFinish={(answered, hasMale?: boolean, hasFemale?: boolean) => {
          setState({
            ...state,
            [page]: {
              ...state[page],
              has: true,
              answered,
              ...(hasMale !== undefined ? { hasMale } : {}),
              ...(hasFemale !== undefined ? { hasFemale } : {}),
            },
          });
        }}
        onBack={() => setState(previousPage(state))}
      />
    );
  }

  if (!state[PAGES.MEMBER_RECEIVING_BENEFITS].has) {
    return (
      <MemberReceivingTestedBenefits
        onFinish={(value) => {
          dispatch(updateMemberReceivingTestedBenefits(value));
          setState({
            ...state,
            [PAGES.MEMBER_RECEIVING_BENEFITS]: {
              ...state[PAGES.MEMBER_RECEIVING_BENEFITS],
              has: true,
            },
          });
        }}
        onBack={() => setState(previousPage(state))}
      />
    );
  }

  if (!state[PAGES.MEMBER_DISABLED].has) {
    return (
      <MemberDisabled
        onFinish={(value) => {
          dispatch(updateBeneficiaryDisabled(value));
          setState({
            ...state,
            [PAGES.MEMBER_DISABLED]: {
              ...state[PAGES.MEMBER_DISABLED],
              has: true,
            },
          });
        }}
        onBack={() => {
          setState({
            ...state,
            [PAGES.MEMBER_RECEIVING_BENEFITS]: {
              ...state[PAGES.MEMBER_RECEIVING_BENEFITS],
              has: false,
            },
          });
        }}
      />
    );
  }

  return (
    <FamilySummary
      onFinish={() => navigate("/app/wills")}
      onBack={() => setState(resetFlow(state))}
    />
  );
};

export default Family;
