import React, { useContext, useState } from "react";
import LockIcon from "@assets/icons/lock.svg";
import { InnerWrapper, Layout, Text, CardsContainer } from "../../styled";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { getPensions } from "../../selectors";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import AddItemCard from "../../../../legacyComponents/AddItemCard/AddItemCard.component";
import AccountCard from "../../../../legacyComponents/AccountCard/AccountCard.component";
import { deletePensionStore } from "../../actions";
import Modal from "../../../../legacyComponents/Modal/Modal.component";
import PensionForm from "./PensionForm";
import { PensionItemInterface } from "@ifgengineering/hip-app-domain";
import { deletePensionAPI } from "../../actions/pension";
import { Description } from "../../styled";
import Tooltip from "@components/Tooltip";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

const emptyState: PensionItemInterface = {
  totalValue: 0,
};

const Pensions: React.FC<PageType> = ({
  nextPage,
  previousPage,
  showTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPension, setSelectedPension] =
    useState<PensionItemInterface>(emptyState);

  const pensionList = useSelector(getPensions);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const onClose = () => {
    setOpen(false);
    setSelectedPension(emptyState);
  };

  const deleteAPI = async (pension: PensionItemInterface) => {
    await dispatch(
      deletePensionAPI(pension.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deletePensionStore(pension));
  };

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Pensions</Text>
              <Description>
                These are any retirement pensions you have, government or
                private
              </Description>
              {showTooltip && <Tooltip text={tooltipText.pensions} />}
            </div>

            <CardsContainer>
              <AddItemCard
                text="Add pension"
                onClick={() => {
                  setOpen(true);
                  setSelectedPension({
                    totalValue: 0,
                  });
                }}
              />

              {pensionList.map((pension) => {
                const { totalValue, description = "" } = pension;

                return (
                  <AccountCard
                    key={`${description}-${totalValue}`}
                    name={description}
                    currency={currencySymbol}
                    balance={totalValue}
                    onClickDelete={() => deleteAPI(pension)}
                    onClickEdit={() => {
                      setOpen(true);
                      setSelectedPension(pension);
                    }}
                  />
                );
              })}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        <PensionForm
          title="Add pension"
          data={selectedPension}
          previousPage={previousPage}
          onSubmit={onClose}
        />
      ) : (
        <Modal onClose={onClose} open={open}>
          <PensionForm
            title="Add pension"
            data={selectedPension}
            previousPage={previousPage}
            onSubmit={onClose}
          />
        </Modal>
      )}
    </>
  );
};

export default Pensions;
