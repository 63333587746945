import React from "react";
import styled from "styled-components";
import CardContainer from "../CardContainer/CardContainer.component";
import { theme } from "../../styles/legacy-theme";
import EditIcon from "../../assets/icons/pen_icon.svg";
import DeleteIcon from "../../assets/icons/bin_icon.svg";
interface AccountProps {
  name: string;
  currency: string;
  balance: number;
  onClickEdit?: React.MouseEventHandler<HTMLButtonElement>;
  onClickDelete?: React.MouseEventHandler<HTMLButtonElement>;
}

const AccountCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledBankName = styled.p`
  padding: 0;
  margin: 0;
`;

const StyledBankBalance = styled.p`
  padding: 0;
  margin: 0;
  color: ${theme.colors.Grey02};
  margin-top: 5px;
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
`;

const IconButton = styled.button`
  padding: 0;
  border: none;
  height: 31px;
  width: 31px;
  background: none;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.LightBlue};
  }
`;

const AccountCard: React.FC<AccountProps> = ({
  name,
  currency,
  balance,
  onClickEdit,
  onClickDelete,
}) => {
  return (
    <CardContainer>
      <AccountCardContainer>
        <div>
          <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
            <div>
              <StyledBankName>{name}</StyledBankName>
              <StyledBankBalance>{`${currency}${Intl.NumberFormat(
                "en-US"
              ).format(balance)}`}</StyledBankBalance>
            </div>
          </div>
        </div>

        {/* action buttons */}
        <IconsContainer>
          <IconButton onClick={onClickDelete} data-testid="delete-button">
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={onClickEdit} data-testid="edit-button">
            <EditIcon />
          </IconButton>
        </IconsContainer>
      </AccountCardContainer>
    </CardContainer>
  );
};

export default AccountCard;
