import { WillInterface } from "@ifgengineering/hip-app-domain";
import { SectionStatus } from "./sectionStatus";

const executorsGuardiansStatus = (will: WillInterface): SectionStatus => {
  const { overseer } = will;

  if (!overseer?.executionerList?.length) {
    return SectionStatus.NOT_YET_STARTED;
  }

  return SectionStatus.COMPLETE;
};

export default executorsGuardiansStatus;
