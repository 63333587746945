import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_ALTERNATIVE_INVESTMENT_API,
  DELETE_ALTERNATIVE_INVESTMENT_API,
  UPDATE_ALTERNATIVE_INVESTMENT_API,
} from "../constants/actionTypes";
import {
  DetailedAssetObject,
  AlternativeInvestmentObject,
} from "../constants/types";

export const createAlternativeAssetAPI = createAsyncThunk<
  AlternativeInvestmentObject,
  {
    email: string;
    data: DetailedAssetObject[];
  }
>(CREATE_ALTERNATIVE_INVESTMENT_API, async ({ email, data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: [CreateDetailedAssetInput!]!, $email: String!) {
          createAlternativeAsset(data: $data, email: $email) {
            id
            totalValue
            alternativeAssetList {
              id
              name
              description
              value
            }
          }
        }
      `,
      variables: {
        email,
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createAlternativeAsset;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "createAlternativeAssetAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateAlternativeItemAPI = createAsyncThunk<
  DetailedAssetObject,
  DetailedAssetObject
>(UPDATE_ALTERNATIVE_INVESTMENT_API, async (data) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: UpdateDetailedAssetInput!) {
          updateAlternativeItem(data: $data) {
            id
            name
            description
            value
          }
        }
      `,
      variables: {
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.updateAlternativeItem;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateAlternativeItemAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteAlternativeItemAPI = createAsyncThunk<string, number>(
  DELETE_ALTERNATIVE_INVESTMENT_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deleteAlternativeItem(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return [];
      }

      return response.data.deleteAlternativeItem;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deleteAlternativeItemAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
