import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FamilyComposition from "../../../../components/Family/FamilyComposition/FamilyComposition";
import YesNoQuestions from "../../../../components/Family/YesNoQuestions/YesNoQuestions";
import {
  FamilyMemberObject,
  FamilyMemberTypes,
} from "@components/Wills/constants/types";
import {
  grandchildrenSelector,
  grandSonsSelector,
  grandDaughtersSelector,
} from "@components/Wills/selectors/family";

type Props = {
  onChange: (value: FamilyMemberObject) => void;
  onDelete: (id: string | undefined) => void;
  onFinish: (answered: boolean, hasMale?: boolean, hasFemale?: boolean) => void;
  onBack: () => void;
};

export const Grandchildren = ({
  onChange,
  onDelete,
  onFinish,
  onBack,
}: Props): JSX.Element => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const grandchildren = useSelector(grandchildrenSelector);
  const grandSons = useSelector(grandSonsSelector);
  const grandDaughters = useSelector(grandDaughtersSelector);

  useEffect(() => {
    if (grandchildren.length) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [grandchildren.length]);

  return (
    <>
      {showForm ? (
        <FamilyComposition
          title="Do any of your sons have any children?"
          description="These are your grandchildren from all of your sons"
          buttonText="Add a son’s child"
          modalTitle="Sons children"
          formConfig={{
            askChildAge: true,
            askGender: true,
          }}
          dropDownOptions={[
            {
              label: "Male",
              value: FamilyMemberTypes.Grandson,
            },
            {
              label: "Female",
              value: FamilyMemberTypes.Granddaughter,
            },
          ]}
          data={grandchildren}
          onBack={onBack}
          onSave={(value) => onChange(value)}
          onRemove={(id) => onDelete(id)}
          onFinish={() =>
            onFinish(
              Boolean(grandchildren.length),
              Boolean(grandSons.length),
              Boolean(grandDaughters.length)
            )
          }
        />
      ) : (
        <YesNoQuestions
          title="Do any of your sons have any children?"
          description="These are your grandchildren from all of your sons"
          onChange={(value) =>
            value
              ? setShowForm(value)
              : onFinish(
                  false,
                  Boolean(grandSons.length),
                  Boolean(grandDaughters.length)
                )
          }
          onBack={onBack}
        />
      )}
    </>
  );
};
