import React, { useState } from "react";
import AddItemCard from "../../../../legacyComponents/AddItemCard/AddItemCard.component";
import FormNavigationButton, {
  NavigationDirections,
} from "../../../../legacyComponents/FormNavigationButton/FormNavigationButton.component";
import StyledModal from "../../../../legacyComponents/Modal/Modal.component";
import {
  BackLinkWrapper,
  ContentWrapper,
  Description,
  PageWrapper,
  Title,
  ScrollingArea,
  TopPadding,
} from "./FamilyComposition.styled";
import FamilyForm from "../Form/FamilyForm";
import FamilyCompositionItem from "./FamilyCompositionItem";
import Button from "../../../../legacyComponents/Button/Button.component";
import { FamilyMemberObject } from "../../../../components/Wills/constants/types";
import { FamilyFormDropdownOptions } from "../Form/types";

type FormConfig = {
  askChildAge?: boolean;
  askGender?: boolean;
};

type Props = {
  buttonText: string;
  description?: string;
  formConfig?: FormConfig;
  data: FamilyMemberObject[];
  modalTitle: string;
  title: string;
  limit?: number;
  dropDownOptions?: FamilyFormDropdownOptions;
  onBack?: () => void;
  onRemove: (id: string | undefined) => void;
  onSave: (item: FamilyMemberObject) => void;
  onFinish: () => void;
};

const FamilyComposition = ({
  buttonText,
  description,
  formConfig,
  data = [],
  modalTitle,
  title,
  limit,
  dropDownOptions,
  onBack,
  onRemove,
  onSave,
  onFinish,
}: Props): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState<
    FamilyMemberObject | undefined
  >(undefined);

  const disableAddingItemCard =
    limit !== undefined ? data.length >= limit : false;

  return (
    <PageWrapper>
      <ContentWrapper>
        <TopPadding>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <AddItemCard
            text={buttonText}
            onClick={() => setShowModal(true)}
            disabled={disableAddingItemCard}
          />
        </TopPadding>
        <ScrollingArea>
          {data.map((item) => (
            <FamilyCompositionItem
              key={`${item.id} ${item.firstName} ${item.lastName}`}
              item={item}
              onRemove={onRemove}
              setSelectedFamilyMember={setSelectedFamilyMember}
              setShowModal={setShowModal}
            />
          ))}
        </ScrollingArea>
        <BackLinkWrapper>
          <Button text="Save & Continue" onClick={onFinish} />
          <FormNavigationButton
            onClick={onBack}
            text="Back"
            direction={NavigationDirections.BACKWARD}
          />
        </BackLinkWrapper>
      </ContentWrapper>
      <StyledModal
        open={showModal}
        buttonText="Save & Continue"
        onClose={() => {
          setShowModal(false);
          setSelectedFamilyMember(undefined);
        }}
        onConfirm={() => setShowModal(false)}
        showConfirmButton={false}
      >
        <FamilyForm
          title={modalTitle}
          familyMember={selectedFamilyMember}
          askChildAge={formConfig?.askChildAge}
          askGender={formConfig?.askGender}
          dropDownOptions={dropDownOptions}
          onSave={(item) => {
            onSave(item);
            setSelectedFamilyMember(undefined);
            setShowModal(false);
          }}
        />
      </StyledModal>
    </PageWrapper>
  );
};

export default FamilyComposition;
