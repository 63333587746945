import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { ShareSchema } from "../../constants/validation";
import { ButtonsContainer, InnerWrapper } from "../../styled";
import { ShareTickerInterface } from "@ifgengineering/hip-app-domain";
import { useDispatch } from "react-redux";
import { createShareAPI, updateShareItemAPI } from "../../actions/share";
import { updateShareItemStore, updateShareStore } from "../../actions";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { CurrencyContext } from "@components/CurrencyContext";
import axios from "axios";
import { CORE_API } from "gatsby-env-variables";
import InputWithComponent from "../../../../legacyComponents/Form/input/InputWithComponent/InputWithComponent.component";
import styled from "styled-components";
import DropDownBox from "../../../../legacyComponents/DropDown/styled/DropDownBox";
import DropDownItem from "../../../../legacyComponents/DropDown/styled/DropDownItem";
import { theme } from "@ifgengineering/component-library";
import FormNavigationButton, {
  NavigationDirections,
} from "@legacyComponents/FormNavigationButton/FormNavigationButton.component";

type Props = {
  data: ShareTickerInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

const ShareForm = ({ data, previousPage, onSubmit }: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const { currencySymbol } = useContext(CurrencyContext);
  const dispatch = useDispatch();

  const createAPI = async (values: ShareTickerInterface) => {
    const toSubmit = {
      ...values,
      currency: "gbp",
    };

    const share = await dispatch(
      createShareAPI({
        email,
        data: [toSubmit],
      })

      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updateShareStore(share));
  };

  const updateAPI = async (values: ShareTickerInterface) => {
    const childTrust = await dispatch(
      updateShareItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateShareItemStore(childTrust));
  };

  const [options, setOptions] = useState<StockDropDownValue[]>([]);
  const [value, setValue] = useState<string>("");
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [manualInput, setManualInput] = useState<boolean>(false);

  const handleSearch = (value: string) => {
    axios
      .get(`${CORE_API}/search/stocks?symbol=${value}&size=4`, {
        withCredentials: true,
      })
      .then((res) => {
        setOptions(res.data);
        setShowOptions(true);
      });
  };

  useEffect(() => {
    if (value) {
      handleSearch(value);
    }
    setShowOptions(false);
  }, [value]);

  useEffect(() => {
    if (data.id) {
      setManualInput(true);
    }
  }, [data]);

  return (
    <Formik
      validationSchema={ShareSchema}
      initialValues={data}
      onSubmit={(share) => {
        if (share.id) {
          updateAPI(share);
        } else {
          createAPI(share);
        }

        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        const renderPredictions = () =>
          options.map((pred: StockDropDownValue, key) => (
            <DropDownItem
              key={key}
              onClick={() => {
                setShowOptions(false);
                setFieldValue("shareName", pred.shareName);
                setFieldValue("nameTicker", pred.nameTicker);
                setFieldValue("market", pred.market);
                setValue("");
                setManualInput(true);
              }}
            >
              <Ticker>{pred.shareName}</Ticker>
              {pred.market}
            </DropDownItem>
          ));
        return (
          <>
            <Link onClick={() => setManualInput((prev) => !prev)}>
              {manualInput ? "Search for Share" : "Set manually"}
            </Link>
            <FormikForm>
              {!manualInput && (
                <>
                  <FormWrapper>
                    <InnerWrapper>
                      <InputWithComponent
                        heading="Search for Share"
                        placeholder="Please enter a share or ticker"
                        description="You can search based on the company name or the ticker e.g. AAPL"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                      {showOptions && (
                        <StyledDropDownBox>
                          {renderPredictions()}
                        </StyledDropDownBox>
                      )}
                    </InnerWrapper>
                  </FormWrapper>
                  {isMobile && (
                    <FormNavigationButton
                      onClick={previousPage}
                      text="Back"
                      direction={NavigationDirections.BACKWARD}
                    />
                  )}
                </>
              )}
              {manualInput && (
                <>
                  <Input
                    value={values.shareName || ""}
                    type="text"
                    name="shareName"
                    heading="Name of Share"
                    description="The name of the share you own e.g. Apple."
                    errorMessage={
                      touched.shareName && errors.shareName
                        ? errors.shareName
                        : ""
                    }
                    onChange={(e) => setFieldValue("shareName", e.target.value)}
                  />

                  <Input
                    value={values.nameTicker || ""}
                    type="text"
                    name="nameTicker"
                    heading="Share Ticker"
                    description="The abbreviation used to identify your share e.g. AAPL."
                    errorMessage={
                      touched.nameTicker && errors.nameTicker
                        ? errors.nameTicker
                        : ""
                    }
                    onChange={(e) =>
                      setFieldValue("nameTicker", e.target.value)
                    }
                  />

                  <Input
                    value={values.market || ""}
                    type="text"
                    name="market"
                    heading="Share Market"
                    description="The market the shares are listed on e.g. NASDAQ"
                    errorMessage={
                      touched.market && errors.market ? errors.market : ""
                    }
                    onChange={(e) => setFieldValue("market", e.target.value)}
                  />

                  <Input
                    value={values.numShares?.toString() || ""}
                    type="number"
                    name="numShares"
                    heading="Number of Shares (Optional)"
                    description="Number of shares you hold of this stock e.g. 10"
                    errorMessage={
                      touched.numShares && errors.numShares
                        ? errors.numShares
                        : ""
                    }
                    onChange={(e) =>
                      setFieldValue("numShares", Number(e.target.value))
                    }
                  />

                  <MoneyInput
                    heading="Estimated Total Value of Shares"
                    description="How much you think your shares are worth"
                    value={values.totalValue?.toString() || ""}
                    currency={currencySymbol}
                    onChange={(value) =>
                      setFieldValue("totalValue", Number(value))
                    }
                    errorMessage={
                      touched.totalValue && errors.totalValue
                        ? errors.totalValue
                        : ""
                    }
                  />

                  <ButtonsContainer>
                    <FormButton
                      onClick={submitForm}
                      type="submit"
                      text="Save & Continue"
                      leftArrowOnClick={isMobile ? previousPage : undefined}
                      leftArrowLabel={isMobile ? "Back" : undefined}
                    />
                  </ButtonsContainer>
                </>
              )}
            </FormikForm>
          </>
        );
      }}
    </Formik>
  );
};

export default ShareForm;

const StyledDropDownBox = styled(DropDownBox)`
  top: 140px;
  height: 10rem;
`;

const Link = styled.div`
  text-align: left;
  font-size: 14px;
  color: ${theme.colors.BLUE600};
  cursor: pointer;
`;

export type StockDropDownValue = {
  nameTicker: string;
  shareName: string;
  market: string;
};

export const Ticker = styled.div`
  font-weight: bold;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FormWrapper = styled.div`
  min-height: 300px;
`;
