import React, { useState } from "react";
import { PieChart, Pie, Cell, Legend, LegendType } from "recharts";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../components/mediaQueries";
import LegendWrapper from "./styled/LegendWrapper";
import LegendComponent from "./styled/LegendComponent";
import activeShape from "./shapes/activeShape";
import COLORS from "./constants/pieColors";
import { Props } from "recharts/src/component/DefaultLegendContent";

type PieProps = {
  data: {
    name: string;
    value: number;
  }[];
};

type CustomProp = {
  hoverAction: (index: number | undefined) => void;
};

const RenderLegend: (props: Props & CustomProp) => React.ReactElement = (
  props: Props & CustomProp
) => (
  <LegendWrapper>
    {props.payload?.map((entry, index) => (
      <LegendComponent
        onMouseEnter={() => props.hoverAction(index)}
        onMouseOut={() => props.hoverAction(undefined)}
        key={`item-${index}`}
        color={entry.color}
        name={entry.value}
        entry={entry.payload}
      />
    ))}
  </LegendWrapper>
);

const CustomPieChart: React.FC<PieProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });

  const onPieEnter: (data: LegendType, index?: number) => void = (
    data,
    index
  ) => setActiveIndex(index);

  const handleLegendClick: (index?: number) => void = (index) =>
    setActiveIndex(index);

  const minimumHeight = 400;
  const heightFromData = data.length * 30;
  const desktopHeight =
    heightFromData < minimumHeight ? minimumHeight : heightFromData;

  return (
    <PieChart
      layout="vertical"
      width={isMobile ? 300 : 500}
      height={isMobile ? 240 : desktopHeight}
    >
      <Pie
        activeIndex={activeIndex}
        activeShape={isMobile ? undefined : activeShape}
        data={data}
        dataKey="value"
        nameKey="name"
        outerRadius={80}
        onMouseEnter={onPieEnter}
        onMouseOut={() => setActiveIndex(undefined)}
      >
        {data.map((entry, index) => (
          <Cell key={index} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      {!isMobile && (
        <Legend
          content={(props) => (
            <RenderLegend hoverAction={handleLegendClick} {...props} />
          )}
        />
      )}
    </PieChart>
  );
};

export default CustomPieChart;
