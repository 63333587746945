import { useEffect, useState } from "react";
import { CurrencyPriceType } from "@ifgengineering/hip-app-domain/lib/commodities";
import { CORE_API } from "gatsby-env-variables";
import axios from "axios";

export const useCurrencyData = () => {
  const [loading, setLoading] = useState(true);
  const [currenciesData, setCurrencyData] = useState<CurrencyPriceType[]>([]);

  useEffect(() => {
    const loadCurrencyData = async () => {
      const response = await axios.get(`${CORE_API}/currencies`, {
        withCredentials: true,
      });
      setCurrencyData(response.data);
      setLoading(false);
    };
    loadCurrencyData();
  }, []);

  return { currenciesData, loading };
};
