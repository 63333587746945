import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_FAMILY_API,
  FETCH_FAMILY_API,
  POPULATE_INHERITANCE_DATA,
  UPDATE_FAMILY_API,
} from "../constants/actionTypes";
import { FamilyCreateUpdateInput, FamilyObject } from "../constants/types";
import { GraphQLError } from "graphql";

interface fetchFamilyAPIOutput {
  data: FamilyObject;
  errors?: readonly GraphQLError[] | undefined;
}

export const fetchFamilyAPI = createAsyncThunk<fetchFamilyAPIOutput, string>(
  FETCH_FAMILY_API,
  async (email) => {
    try {
      const response = await client.query({
        query: gql`
          query ($email: String!) {
            family(email: $email) {
              id
              memberDisabled
              memberReceivingTestedBenefits
              familyMembers {
                id
                firstName
                lastName
                over18
                percentageEstate
                marriageStatustype
                email
                familyMemberType
              }
            }
          }
        `,
        variables: {
          email,
        },
        fetchPolicy: "no-cache",
      });

      if (response.errors) {
        return { errors: response.errors };
      }

      return { data: response.data.family };
    } catch (e: any) {
      if (e.message !== "Response not successful: Received status code 400") {
        toast.warn("Something went wrong. Try again or contact support");
      }

      logException({
        email,
        tag: "fetchFamilyAPI",
        exception: e,
      });

      return { errors: e };
    }
  }
);

export const createFamilyAPI = createAsyncThunk<
  FamilyCreateUpdateInput,
  string
>(CREATE_FAMILY_API, async (email) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($family: FamilyCreateUpdateInput!, $email: String!) {
          createFamily(data: $family, email: $email) {
            id
            memberDisabled
            memberReceivingTestedBenefits
            familyMembers {
              id
              firstName
              lastName
              over18
              percentageEstate
              marriageStatustype
              email
              familyMemberType
            }
          }
        }
      `,
      variables: {
        family: {},
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.createFamily;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "createFamilyAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateFamilyAPI = createAsyncThunk<
  FamilyObject,
  FamilyCreateUpdateInput
>(UPDATE_FAMILY_API, async (family) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($family: FamilyUpdateInput!) {
          updateFamily(data: $family) {
            id
            memberDisabled
            memberReceivingTestedBenefits
            familyMembers {
              id
              firstName
              lastName
              over18
              percentageEstate
              marriageStatustype
              email
              familyMemberType
            }
          }
        }
      `,
      variables: {
        family,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.updateFamily;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateFamilyAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const populateInheritanceDataAPI = createAsyncThunk<void, FamilyObject>(
  POPULATE_INHERITANCE_DATA,
  async (family) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($family: FamilyInput!) {
            populateInheritanceData(data: $family) {
              message
            }
          }
        `,
        variables: {
          family,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return;
      }
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "populateInheritanceDataAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
