import React, { useState, useRef } from "react";
import {
  Container,
  AccordionButton,
  StyledArrow,
  Title,
  Content,
} from "./styled";

const Accordion: React.FunctionComponent<{ title: string }> = (props) => {
  const [open, setOpen] = useState(false);
  const content = useRef<HTMLInputElement>(null);

  return (
    <Container>
      <AccordionButton
        data-testid="accordion-button"
        open={open}
        onClick={() => setOpen((prev) => !prev)}
      >
        <Title data-testid="accordion-title">{props.title}</Title>
        <StyledArrow open={open} />
      </AccordionButton>

      <Content
        data-testid="accordion-content"
        maxHeight={open ? `${content?.current?.scrollHeight}px` : "0px"}
        ref={content}
      >
        {props.children}
      </Content>
    </Container>
  );
};

export default Accordion;
