import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_AGRICULTURE_API,
  DELETE_AGRICULTURE_API,
  UPDATE_AGRICULTURE_API,
} from "../constants/actionTypes";
import { AgricultureItemObject, AgricultureObject } from "../constants/types";

export const createAgricultureAPI = createAsyncThunk<
  AgricultureObject,
  {
    email: string;
    data: AgricultureItemObject[];
  }
>(CREATE_AGRICULTURE_API, async ({ email, data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: [CreateAgricultureItemInput!]!, $email: String!) {
          createAgricultureAsset(data: $data, email: $email) {
            id
            totalValue
            agricultureList {
              id
              name
              description
              value
            }
          }
        }
      `,
      variables: {
        email,
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createAgricultureAsset;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "createAgricultureAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateAgricultureItemAPI = createAsyncThunk<
  AgricultureItemObject,
  AgricultureItemObject
>(UPDATE_AGRICULTURE_API, async (data) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: UpdateAgricultureItemInput!) {
          updateAgricultureItem(data: $data) {
            id
            name
            description
            value
          }
        }
      `,
      variables: {
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.updateAgricultureItem;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateAgricultureItemAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteAgricultureAPI = createAsyncThunk<string, number>(
  DELETE_AGRICULTURE_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deleteAgricultureItem(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return [];
      }

      return response.data.deleteAgricultureItem;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deleteAgricultureAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);
